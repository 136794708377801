<template>
  <div id="login" class="position-relative">
    <div class="container-fluid">
      <ValidationObserver ref="resetRules">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-6" id="left">
            <div class="forms-g-wrapper" style="width: 60%">
              <h2 class="text-center"><i class="fa-solid fa-check-circle mx-3"></i>{{ $t('E-Mail is send') }}</h2>
              <div class="description text-center">Şifrenizi sıfırlamak için talimatların yer aldığı bir e-posta <span class="fw-bold">{{$store.state.password.email}}</span> adresine gönderildi.</div>

              <div class="warning-text mt-3">
                <i class="fa-solid fa-circle-info" style="color: #6D6E6F66;"></i>
                E-postayı gelen kutunuzda göremezseniz, lütfen spam veya gereksiz klasörünü kontrol edin.</div>
              <div class="form-wrapper">
                <button @click="$router.push('/login')">{{ $t('Login') }}</button>

                <button class="open-gmail" @click="openGmail()">{{ $t('Open Gmail') }}</button>
              </div>
            </div>
          </div>
          <div class="col-lg-6" id="right">
            <div class="step1">
              <img src="https://s3-alpha-sig.figma.com/img/697b/1900/dc805255d4034e6bf71c8e515080bb8e?Expires=1722816000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=biIwSGjuoOjbdPZi2R5rUwOjI06i40n5DNKCzAvQJM9p1YSoEvud39oDwldeWNkbrAQurtzfxzOEq8FDE0htcVlk~9Axl3Nkz-vKBTdD~F1cNUMz28O7w2aBj5~nb0iGzDqxeFi6I9JcoYB8dNhyKkIr0Us-J6FvRMj0LyPBK4qUeBxE4V8bRr~n5QX9aKd3O-W1qRYEjvWsn9W5UiKeq-9eOczeBN~17FWTszkSXBeRiaH6EYungsxExzjmXTmoVd6Tx2F3hF-Vx5MsWOrSi8G8rnnW9Fl4CT26vo2PssyrrVywLiqcQix4qM4yUqKyv77kjjWy9o804dVcCt~yeA__" alt="">
            </div>
          </div>
        </div>
      </ValidationObserver>
    </div>
    <div class="powered-by">
      powered by <a href="https://www.servislet.com/" target="_blank"><img src="https://www.servislet.com/logo.svg" alt="" width="80"></a>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'sendpassword',
  data() {
    return {

    }
  },
  mounted(){

  },
  methods: {
    openGmail(){
      window.location.href = 'mailto:cayliizehra@gmail.com';
    }
  }
}
</script>

<style scoped>
.step1 {
  background-color: #1C1C1C;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 50px 0 0 50px;
}

.step1 img {
  height: 350px;
  width: auto;
  position: absolute;
  bottom: 0;
}

#login .col-lg-6 {
  padding: 0 !important;
}

#left {
  display: flex;
  align-items: center;
  justify-content: center;
}

.forms-g-wrapper h2 {
  color: #2A4195;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 1rem;
  white-space: nowrap;
}
.form-wrapper button {
  border-radius: 5px;
  background: #2A4195;
  color: #f4f4f4;
  width: 100%;
  margin-top: 30px;
  border: 1px solid #2A4195;
  outline: none !important;
  box-shadow: none !important;
  transition: .5s;
  padding: 10px;
}

.input-wrapper svg {
  color: #d9d9d9;
}
.powered-by{
  position: absolute;
  bottom: 10px;
  left: 30px;
  display: flex;
  align-items: baseline;
  font-size: 12px;
  font-weight: 400;
  line-height: 50px;
  text-align: left;
  color: #777777;
  gap: .5rem;

}
.description{
  font-family: Mulish;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #1C1C1C;
}
.warning-text{
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: #6D6E6F;
}
.form-wrapper .open-gmail{
  background-color: #2A42952E;
  color: #2A4195;
  border: 1px solid transparent;
}

@media only screen and (max-width: 992px) {
  #right {
    display: none;
  }

  #login {
    height: 100vh;
    display: flex;
    align-items: center;
  }
}
</style>
