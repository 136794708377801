import Vue from "vue";
import Vuex from 'vuex';

Vue.use(Vuex);

import axiosInstance from "@/plugins/axios";

const state = {
    list:[],
    loader: true,
    selected: {}
};

const mutations = {
    setList(state, data) {
        state.list = data
    },
    setSelected(state,data){
        state.selected = data
    }
};
const actions = {
    getCities({commit}, data) {
        commit('setList',[])
        axiosInstance.get(`/v1/region/cities/${data}`).then(response=>{
            if(response.data.status){
                commit("setList",response.data.responseData.cities)
            }
        })
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
