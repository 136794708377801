import Vue from "vue";
import Vuex from 'vuex';

Vue.use(Vuex);

import axiosInstance from "@/plugins/axios";

const state = {
    list:[],
    pagination: {},
    loader: true
};

const mutations = {
    setList(state, data) {
        state.list = data.items
        state.pagination = data.pagination
    },
    setLoader(state,data){
        state.loader = data
    }
};
const actions = {
    getDiscounts({commit}, data) {
        try{
            commit('setLoader',true)
            axiosInstance.get('/v1/customers/discounts',{
                params:{
                    page: data.page,
                    size: data.size
                }
            }).then(response=>{
                if(response.data.status){
                    commit("setList",response.data.responseData.customerDiscount)
                    commit('setLoader',false)
                    return true
                }
            })
            return true
        }catch(error) {
            return false
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
