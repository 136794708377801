<template>
  <div id="login" class="position-relative">
    <div class="container-fluid">
      <ValidationObserver ref="loginRules">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-6" id="left">
            <div class="forms-g-wrapper w-50">
              <h2 class="text-center">{{ $t('Login') }}</h2>
              <div class="form-wrapper">
                <ValidationProvider :name="$t('E-Mail')" #default="{errors}" rules="required|min:2|email">
                  <div class="input-wrapper mt-5">
                      <b-input-group>
                        <template #prepend>
                          <i class="fa-solid fa-envelope"></i>
                        </template>
                        <b-form-input :placeholder="$t('E-Mail')" type="text" v-model="form.email" v-on:keyup.enter="login()"></b-form-input>
                      </b-input-group>
                  </div>
                  <label for="email" class="text-center text-danger" v-if="errors.length>0"><small>* {{errors[0]}}.</small></label>
                </ValidationProvider>
                <ValidationProvider :name="$t('Password')" #default="{errors}" rules="required|min:2">
                  <div class="input-wrapper">
                    <b-input-group>
                      <template #prepend>
                        <img src="/images/icons/lock.svg" alt="">
                      </template>
                      <b-form-input :placeholder="$t('Password')" type="password" v-model="form.password"
                                    v-if="!show_password" v-on:keyup.enter="login()"></b-form-input>
                      <b-form-input :placeholder="$t('Password')" type="text" v-model="form.password" v-if="show_password"
                                    v-on:keyup.enter="login()"></b-form-input>
                      <button class="toggle-password" @click="show_password = !show_password">
                        <i class="fa-sharp fa-regular fa-eye-slash"></i>
                      </button>
                    </b-input-group>
                  </div>
                  <label for="email" class="text-center text-danger" v-if="errors.length>0"><small>* {{errors[0]}}.</small></label>
                </ValidationProvider>
                <div class="form-actions">
                  <b-form-checkbox @change="rememberPassword()" v-model="form.remember_me" class="select-remember">&nbsp;&nbsp;{{$t('Remember password')}}</b-form-checkbox>
                  <router-link to="/forget-password">{{ $t('Forget password?') }}</router-link>
                </div>
                <button @click="login()">{{ $t('Login') }}</button>

                <div class="text-center mt-3"><span class="open-text">{{$t('You want to open an account?')}} </span><span class="cursor-pointer" style="color:#2A4195" @click="$router.push('/register')">{{$t('Apply Now')}}</span></div>
              </div>

            </div>
          </div>
          <div class="col-lg-6" id="right">
            <div class="step1">
              <img src="https://s3-alpha-sig.figma.com/img/697b/1900/dc805255d4034e6bf71c8e515080bb8e?Expires=1722816000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=biIwSGjuoOjbdPZi2R5rUwOjI06i40n5DNKCzAvQJM9p1YSoEvud39oDwldeWNkbrAQurtzfxzOEq8FDE0htcVlk~9Axl3Nkz-vKBTdD~F1cNUMz28O7w2aBj5~nb0iGzDqxeFi6I9JcoYB8dNhyKkIr0Us-J6FvRMj0LyPBK4qUeBxE4V8bRr~n5QX9aKd3O-W1qRYEjvWsn9W5UiKeq-9eOczeBN~17FWTszkSXBeRiaH6EYungsxExzjmXTmoVd6Tx2F3hF-Vx5MsWOrSi8G8rnnW9Fl4CT26vo2PssyrrVywLiqcQix4qM4yUqKyv77kjjWy9o804dVcCt~yeA__" alt="">
            </div>
          </div>
        </div>
      </ValidationObserver>
    </div>
    <div class="powered-by">
      powered by <a href="https://www.servislet.com/" target="_blank"><img src="https://www.servislet.com/logo.svg" alt="" width="80"></a>
    </div>
  </div>
</template>

<script>
import axiosInstance from "@/plugins/axios";
import Cookies from 'js-cookie';
/* eslint-disable */
export default {
  name: 'Login',
  data() {
    return {
      form: {
        email: '',
        password: '',
        remember_me: false
      },
      show_password: false
    }
  },
  mounted(){
    const password = Cookies.get('password');
    const email = Cookies.get('email');
    if (password) {
      this.form.remember_me = true
      this.form.password = password;
    }
    if(email){
      this.form.remember_me = true
      this.form.email = email;
    }
  },
  methods: {
    login() {
      this.$refs.loginRules.validate().then(success=>{
        if(success){
          this.$axios.post('v1/customers/login',{
            email: this.form.email,
            password: this.form.password
          }).then(response=>{
            if(response.data.status){
              localStorage.setItem('access_token', response.data.responseData.access_token);
              localStorage.setItem('app_type', 'tire');
              localStorage.setItem('locale', 'tr');
              localStorage.setItem('logo', response.data.responseData.customer.header_logo);
              localStorage.setItem('default_discount_rate', response.data.responseData.customer.default_discount_rate ?? 0);
              localStorage.setItem('color', response.data.responseData.customer.template_color ?? 'rgba(28,28,28)');
              localStorage.setItem('list_price_type', response.data.responseData.customer.list_price_type ? response.data.responseData.customer.list_price_type : false);
              // localStorage.setItem('list_stock_type', response.data.responseData.customer.list_stock_type);
              localStorage.setItem('is_erp_integrated', response.data.responseData.customer.is_erp_integrated ? response.data.responseData.customer.is_erp_integrated : false);
              localStorage.setItem('user', JSON.stringify(response.data.responseData.user));
              axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${response.data.responseData.access_token}`
              axiosInstance.defaults.headers.Authorization = `Bearer ${response.data.responseData.access_token}`


              const text = localStorage.getItem('color');
              const bg = localStorage.getItem('color');
              const opacitied_bg = localStorage.getItem('color') ? localStorage.getItem('color').substring(0, localStorage.getItem('color').length - 1) + ', .08)' : '';
              const styles = `
  .dealer-text {
    color: ${text} !important;
  }
  .dealer-bg {
    background-color: ${bg};
  }
  .dealer-bg-muted{
    background-color: ${bg};
    opacity: .08;
  }
  .opacitied-bg{
    background-color: ${opacitied_bg} !important;
  }
  input[type=checkbox]:checked {
    accent-color: ${bg} !important;
    color: #ffffff !important;
  }
  .dealer-border{
    border: 1px solid ${text} !important;
  }
  #profil-dropdown__BV_toggle_ {
    color: ${text} !important;
  }
  #profil-dropdown .dropdown-menu .dropdown-item:hover{
    background-color: ${opacitied_bg} !important;
    color: ${text} !important;
  }
  #finance-actions__BV_toggle_ {
    border: 1px solid ${text} !important;
    background-color: ${opacitied_bg} !important;
    color: ${text} !important;
  }
  #finance-actions .dropdown-menu .dropdown-item:hover{
    background-color: ${opacitied_bg} !important;
    color: ${text} !important;
  }
  .dropdown-menu .dropdown-item:focus{
    background-color: ${opacitied_bg} !important;
    color: ${text} !important;
  }
  input[type=radio] {
    accent-color: ${bg} !important;
  }
  .form-control:focus{
    border: 1px solid ${text} !important;
  }
  .form-white .form-group.input-material .form-control:focus {
    border: 1px solid ${text} !important;
  }
  .form-group.input-material .form-control[value]:not([value=""]) ~ label,
  .form-group.input-material .form-select:focus ~ label,
  .form-group.input-material .form-select[value]:not([value=""]) ~ label,
  .form-white .form-group.input-material .form-control:focus ~ label {
    top: unset;
    bottom: 100%;
    font-family: Inter;
    bottom: calc(100% - 20px);
    font-size: 12px;
    color: ${text};
    font-weight: 400;
    padding: 0 10px;
    background-color: #fff;
    margin-bottom: 10px;
  }
  .payment-price:focus{
    border: 1px solid ${text} !important;
  }
  .border-0:focus {
    border: none !important;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    right: 18px;
    bottom: 2px;
    background-color: #fff;
    transition: .4s;
    border-radius: 50%;
  }
    .toggle input:checked + .slider {
    background-color: ${text};
  }

  .toggle .slider:after {
    position: absolute;
    content: "✓";
    color: ${text};
    font-size: 12px;
    top: 2px;
    right: 5px;
    opacity: 0;
    transition: .4s;
  }
`;
              const styleElement = document.createElement('style');
              styleElement.innerHTML = styles;
              document.head.appendChild(styleElement);




              this.$router.push('/')
              this.$swal({
                iconHtml: '<img src="/images/toast/success.svg" width="32" height="32">',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                text: this.$i18n.t('You successfully logged in.'),
                title: this.$i18n.t('Success')+' !',
                customClass: {
                  icon: 'swal2-no-border'
                }
              })
            }
          }).catch(error=>{
            this.$swal({
              iconHtml: '<img src="/images/toast/error.svg" width="32" height="32">',
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              title: this.$i18n.t('Error')+' !',
              text: error.response.data.statusMessage,
              customClass: {
                icon: 'swal2-no-border'
              }
            })
          })
        }else{
          this.$swal({
            iconHtml: '<img src="/images/toast/warning.png" width="32" height="32">',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            title: this.$i18n.t('Warning')+' !',
            text: this.$i18n.t('Please check fields.'),
            customClass: {
              icon: 'swal2-no-border'
            }
          })
        }
      })
    },
    rememberPassword(){
      if (this.form.remember_me) {
        Cookies.set('remember', true, { expires: 30 });
        if(this.form.password){
          Cookies.set('password', this.form.password, { expires: 30 });
        }
        if(this.form.email){
          Cookies.set('email', this.form.email, { expires: 30 });
        }
      } else {
        Cookies.remove('email');
        Cookies.remove('password');
        Cookies.remove('remember');
      }
    }
  }
}
</script>

<style scoped>
.step1 {
  background-color: #1C1C1C;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 50px 0 0 50px;
}

.step1 img {
  height: 350px;
  width: auto;
  position: absolute;
  bottom: 0;
}

#login .col-lg-6 {
  padding: 0 !important;
}

#left {
  display: flex;
  align-items: center;
  justify-content: center;
}

.forms-g-wrapper h2 {
  color: #2A4195;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 2rem;
}

.input-wrapper:first-child {
  margin-top: 1.75rem !important;
}

.input-wrapper {
  border: 1px solid #D8D6DE;
  padding: 2px 12px;
  border-radius: 5px;
  width: 100%;
  margin-top: 1.5rem;
  position: relative;
}

.form-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%
}

.form-actions a, .form-actions .select-remember {
  color: #1c1c1c;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-top: 1rem;
  text-decoration: none !important;
}

.form-wrapper button {
  border-radius: 5px;
  background: #2A4195;
  color: #f4f4f4;
  width: 100%;
  margin-top: 50px;
  border: 1px solid #2A4195;
  outline: none !important;
  box-shadow: none !important;
  transition: .5s;
  padding: 10px;
}

.input-wrapper svg {
  color: #d9d9d9;
}

.toggle-password {
  border: none !important;
  background: transparent !important;
  outline: none !important;
  box-shadow: none !important;
  color: #d9d9d9 !important;
  margin: 0 !important;
  width: min-content !important;
  padding: 0 !important;
}
.open-text{
  font-size: 16px;
  font-weight: 300;
  line-height: 40px;
  color: #77777799;
}
.powered-by{
  position: absolute;
  bottom: 10px;
  left: 30px;
  display: flex;
  align-items: baseline;
  font-size: 12px;
  font-weight: 400;
  line-height: 50px;
  text-align: left;
  color: #777777;
  gap: .5rem;

}

@media only screen and (max-width: 992px) {
  #right {
    display: none;
  }

  #login {
    height: 100vh;
    display: flex;
    align-items: center;
  }
}
</style>
