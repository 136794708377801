<template>
  <div>
    <Default>
      <div class="page-content">
        <ul id="breadcrumb-scroll">
          <li>
            <a href="/" itemprop="item"><span itemprop="name">{{$t('Summary')}}</span></a>
          </li>
          <li>
          <span class="active">
               {{$t('Orders')}}
          </span>
          </li>
        </ul>

        <div class="table-content product-listing-table neu col-sm-12 col-lg-12" v-if="!$store.state.order.loader">
          <div class="title-border">
            <div class="space-between">
              <div class="table-title">{{ $t('Orders') }}</div>
              <div class="table-action"/>
            </div>

<!--            <div class="table-description">{{ $t('Easily manage and update payment methods for your users.') }}-->
<!--            </div>-->
          </div>
          <div class="filters">
            <div class="filter-input">
              <b-form-input :placeholder="$t('Search Order')" class="bg-transparent" v-model="search_text" v-on:keydown.enter="setSearch()" />
              <i class="fa-solid fa-xmark cursor-pointer" v-if="search_text" @click="clearSearch()"></i>
              <i class="fa-solid fa-filter" v-else></i>
            </div>
            <button class="btn filter-action" @click="excel()"><img
                src="/images/icons/download.svg"
                alt=""> Excel
            </button>
          </div>

          <div class="filters">
            <div class="filter-input cursor-pointer">
              <i class="fa-regular fa-calendar-check" style="margin-right: .5rem;"></i>
              <flat-pickr :placeholder="$t('Date Range')" v-model="date" class="form-control text-nowrap bg-transparent"
                          :config="{dateFormat: 'Y-m-d',mode: 'range',locale: locale,allowInput: true,enableTime: false,maxDate: end_date,minDate: calculate1YearAgo,onReady: addCustomButton,closeOnSelect:false}"/>
              <i class="fa-solid fa-xmark cursor-pointer" @click="date = null; clearDateRange()"
                 v-if="date"></i>
            </div>
          </div>

          <div style="margin-bottom: 20px;">
            <div class="left-tabs">
              <span class="tab-item" @click="order_status_id = 0; setOrderStatus();" :class="order_status_id === 0 ? 'active-tab' : ''">{{$t('All')}}</span>

              <span class="tab-item" v-for="status in statuses" :key="status.$index" :class="order_status_id === status.value ? 'active-tab' : ''" @click="order_status_id = status.value; setOrderStatus()"  v-if="status.code === 'waiting-approval' || status.code === 'approved' || status.code === 'shipped' || status.code === 'completed'">{{status.text}}</span>
            </div>
          </div>
          <div class="table-responsive">
            <table id="productTable" class="w-100 no-last">
              <thead>
              <tr class="product-listing-table-head">
                <!--                <th>-->
                <!--                  <label class="product-listing-checkbox-label">-->
                <!--                    <input type="checkbox">-->
                <!--                    <span class="checkmark"></span>-->
                <!--                  </label>-->
                <!--                </th>-->
                <th>
                  <div class="d-flex align-items-center cursor-pointer gap-2">
                    ID
                    <!--                    <i class="fa-solid fa-chevron-down"></i>-->
                  </div>
                </th>
                <th>
                  <div class="d-flex align-items-center cursor-pointer gap-2">
                    {{$t('Date')}}
                    <!--                    <i class="fa-solid fa-chevron-down"></i>-->
                  </div>
                </th>
                <th>
                  <div class="d-flex align-items-center cursor-pointer gap-2">
                    {{$t('Customer')}}
                    <!--                    <i class="fa-solid fa-chevron-down"></i>-->
                  </div>
                </th>
                <th>
                  <div class="d-flex align-items-center cursor-pointer gap-2">
                    {{$t('Category')}}
                    <!--                    <i class="fa-solid fa-chevron-down"></i>-->
                  </div>
                </th>
                <th>
                  <div class="d-flex align-items-center cursor-pointer gap-2">
                    {{$t('Price')}}
                    <!--                    <i class="fa-solid fa-chevron-down"></i>-->
                  </div>
                </th>
                <th v-if="is_erp_integrated">
                  <div class="d-flex align-items-center cursor-pointer gap-2">
                    {{$t('Receipt No')}}
                    <!--                    <i class="fa-solid fa-chevron-down"></i>-->
                  </div>
                </th>
                <th>
                  <div class="d-flex align-items-center cursor-pointer gap-2">
                    {{$t('Order Status')}}
                    <!--                    <i class="fa-solid fa-chevron-down"></i>-->
                  </div>
                </th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="order in orders" :key="order.$index">
                <!--                <td>-->
                <!--                  <b-form-checkbox name="check-button"/>-->
                <!--                </td>-->
                <td>#{{order.id}}</td>
                <td v-if="is_erp_integrated">{{order.order_date ? dateTimeFormat(order.order_date) : '-'}}</td>
                <td>
                  <div v-if="order.dealer">
                    {{order.dealer.name}}
                  </div>
                  <div v-else>-</div>
                </td>
                <td>
                  <span v-for="i in order.categories" :key="i.$index">
                    <span class="category-badge tire-badge" v-if="i.name === 'Lastik'">{{$t('Tire')}}</span>
                    <span class="category-badge oil-badge" v-else-if="i.name === 'Yağ'">{{$t('Oil')}}</span>
                    <span class="category-badge part-badge" v-else-if="i.name === 'Yedek Parça'">{{$t('Spare Parts')}}</span>
                    <span class="category-badge rim-badge" v-else-if="i.name === 'Jant'">{{$t('Rim')}}</span>
                    <span class="category-badge default-badge" v-else>{{i.name}}</span>
                  </span>
                  <!--                    <span v-if="order%4 === 3" class="category-badge part-badge">{{$t('Spare Parts')}}</span>-->
                  <!--                    <span v-if="order%4 === 0" class="category-badge oil-badge">{{$t('Oil')}}</span>-->
                  <!--                    <span v-if="order%4 === 2" class="category-badge rim-badge">{{$t('Rim')}}</span>-->
                </td>
                <td>{{currenctFormat('tr-TR', order.total_amount ?? 0)}}</td>
                <td v-if="is_erp_integrated">{{order.ref_code !== null && order.ref_code !== "" ? order.ref_code : '-'}}</td>
                <td>
                  <span v-if="order.orderStatus">
                    <span v-if="order.orderStatus.code === 'completed' " class="category-badge order-success-badge">{{$t('Completed')}}</span>
                    <span v-else-if="order.orderStatus.code === 'canceled' " class="category-badge order-cancelled-badge">{{$t('Cancelled')}}</span>
                    <span v-else-if="order.orderStatus.code === 'waiting-approval' " class="category-badge order-other-badge">{{$t('Approve Waiting')}}</span>
                    <span v-else-if="order.orderStatus.code === 'preparing' " class="category-badge order-other-badge">{{$t('Preparing')}}</span>
                    <span v-else class="category-badge default-badge">{{order.orderStatus.name}}</span>
                  </span>
                </td>
                <td>
                  <div class="d-flex justify-content-end">
                    <button class="btn see-detail-btn opacitied-bg" @click="$router.push(`/orders/${order.id}`)">{{$t('See Detail')}}</button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <hr>

          <div class="d-flex justify-content-between pagination-count align-items-center">
                <span>
                  <span class="pagination-text">{{ $t('Show by per page:') }} &nbsp;</span>
                  <select name="pagination" id="pagination-select" @change="getOrderList()" v-model="size">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </span>
            <span class="d-flex align-items-center" style="gap: 1rem;">
                   <span>
             <span class="fw-bolder">{{ $store.state.order.pagination.total }}</span> siparişten
                  <span
                      class="fw-bolder">{{
                      $store.state.order.pagination.current_page === 1 ? 1 : (($store.state.order.pagination.current_page - 1) * $store.state.order.pagination.per_page) + 1
                    }} - {{
                      $store.state.order.pagination.total_page === $store.state.order.pagination.current_page ? $store.state.order.pagination.total : $store.state.order.pagination.current_page * $store.state.order.pagination.per_page
                    }}</span> gösteriliyor.</span>
                <b-pagination
                    :total-rows="$store.state.order.pagination.total"
                    :per-page="size"
                    align="center"
                    size="sm"
                    class="my-1"
                    first-number
                    last-number
                    v-model="currentPage"
                    @input="getOrderList()"
                />
              </span>
          </div>
        </div>


        <div class="table-content product-listing-table neu col-sm-12 col-lg-12" v-else>
          <loader />
        </div>
      </div>
    </Default>
  </div>
</template>

<script>
import Default from "@/layouts/default.vue";
import {Turkish} from "@/locales/flatpickr/tr";
import {English} from "@/locales/flatpickr/en";
import axios from "@/plugins/axios";
import i18n from "@/i18n";
export default{
  name:'OrderList',
  components:{
    Default
  },
  data(){
    return{
      currentPage: 1,
      size: 10,
      search_text: '',
      order_status_id: 0,
      end_date: '',
      date: null,
      locale: localStorage.getItem('locale') === 'tr' ? Turkish : English,
    }
  },
  mounted(){
    const currentDate2 = new Date();
    const year2 = currentDate2.getFullYear();
    const month2 = String(currentDate2.getMonth() + 1).padStart(2, '0');
    const day2 = String(currentDate2.getDate()).padStart(2, '0');

    this.end_date = `${year2}-${month2}-${day2}`;
    this.$store.dispatch('order/getOrderStatusList')
    this.getOrderList()
  },
  computed:{
    calculate1YearAgo() {
      const oneYearAgoDate = new Date(new Date().getTime() - 365 * 24 * 60 * 60 * 1000);

      const oneYearAgoDateString = oneYearAgoDate.toISOString().replace(/T/, '-').replace(/\..+$/, '');

      return oneYearAgoDateString
    },
    orders(){
      return this.$store.state.order.list
    },
    color(){
      return localStorage.getItem('color')
    },
    statuses() {
      return this.$store.state.order.statuses
    },
    is_erp_integrated(){
      return JSON.parse(localStorage.getItem('is_erp_integrated'))
    }
  },
  watch:{
    search_text(){
      if(this.search_text.length === 0){
        this.getOrderList()
      }
    }
  },
  methods:{
    addCustomButton(selectedDates, dateStr, instance) {
      // Create a custom button
      const customButton = document.createElement('button');
      customButton.innerHTML = this.$i18n.t('Apply Range');
      customButton.className = 'btn dealer-bg custom-flatpickr-button'; // Add your custom classes here
      customButton.onclick = () => {
        this.setDateRange()
      };

      instance.calendarContainer.appendChild(customButton);
    },
    clearDateRange() {
      delete this.$route.query['start_date']
      delete this.$route.query['end_date']
      const currentQuery = this.$route.query;
      const newQuery = {
        ...currentQuery
      };
      const queryString = Object.keys(newQuery).map(key => `${key}=${newQuery[key]}`).join('&');
      const url = `${window.location.pathname}?${queryString}`;

      window.location.href = url;
    },
    setDateRange() {
      if (this.date && this.date.length === 23) {
        var ar = this.date.split(' ')
        const currentQuery = this.$route.query;
        const newQuery = {
          ...currentQuery,
          start_date: ar[0],
          end_date: ar[2],
        };
        const queryString = Object.keys(newQuery).map(key => `${key}=${newQuery[key]}`).join('&');
        const url = `${window.location.pathname}?${queryString}`;
        window.location.href = url;
      }
    },
    setSearch(){
      const currentQuery = this.$route.query;
      const newQuery = {
        ...currentQuery,
        search_text: this.search_text
      };
      const queryString = Object.keys(newQuery).map(key => `${key}=${newQuery[key]}`).join('&');
      const url = `${window.location.pathname}?${queryString}`;
      window.location.href = url;
    },
    clearSearch(){
      delete this.$route.query['search_text']
      const currentQuery = this.$route.query;
      const newQuery = {
        ...currentQuery
      };
      const queryString = Object.keys(newQuery).map(key => `${key}=${newQuery[key]}`).join('&');
      const url = `${window.location.pathname}?${queryString}`;

      window.location.href = url;
    },
    setOrderStatus(){
      const currentQuery = this.$route.query;
      const newQuery = {
        ...currentQuery,
        order_status_id: this.order_status_id
      };
      const queryString = Object.keys(newQuery).map(key => `${key}=${newQuery[key]}`).join('&');
      const url = `${window.location.pathname}?${queryString}`;
      window.location.href = url;
    },
    getOrderList(){
      if(this.$route.query.search_text){
        this.search_text = this.$route.query.search_text
      }
      if(this.$route.query.order_status_id){
        this.order_status_id = parseInt(this.$route.query.order_status_id)
      }
      if (this.$route.query.start_date && this.$route.query.end_date) {
        this.date = this.$route.query.start_date + ' - ' + this.$route.query.end_date
      }
      this.$store.dispatch('order/getOrders',{
        page: this.currentPage,
        size: this.size,
        order_status_id: this.order_status_id,
        search_text: this.search_text,
        start_date: this.$route.query.start_date && this.$route.query.end_date ? this.$route.query.start_date + 'T00:00:00' : null,
        end_date: this.$route.query.start_date && this.$route.query.end_date ? this.$route.query.end_date + 'T23:59:59' : null,
      })
    },
    async excel() {
      try {
        var data = {
          start_date: this.$route.query.start_date ? this.$route.query.start_date + 'T00:00:00' : null,
          end_date: this.$route.query.end_date ? this.$route.query.end_date + 'T23:59:00' : null,
        }

        if(this.$route.query.search_text || parseInt(this.$route.query.order_status_id) > 0) {
          data.filter = {}
          if(this.$route.query.search_text) {
            data.filter.searchText = this.$route.query.search_text
          }
          if(parseInt(this.$route.query.order_status_id) > 0){
            data.filter.order_status_id = parseInt(this.$route.query.order_status_id)
          }
        }

        const response = await axios.post('/v1/customers/orders/download',
            data
            , {
              responseType: 'arraybuffer', // Important to set the response type
              headers: {
                'Authorization': "Bearer " + localStorage.getItem('access_token'), // Add your token if needed
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              }
            });

        // Create a blob from the response data
        const blob = new Blob([response.data], { type: response.headers['content-type'] });

        // Create a link element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = response.headers['content-disposition'].split('filename*=UTF-8\'\'')[1]; // Extract filename

        // Append to the body
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Clean up and remove the link
        document.body.removeChild(link);
      } catch (error) {
        this.$swal({
          iconHtml: '<img src="/images/toast/error.svg" width="32" height="32">',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          title: i18n.t('Error') + ' !',
          text: error.response.data.statusMessage,
          customClass: {
            icon: 'swal2-no-border'
          }
        })
      }
    }
  }
}

</script>


<style scoped>
.table-content{
  padding: 30px;
  margin-top: 30px;
}

.trash-icon img{
  width: 12px;
  height: auto;
}
.product-image img{
  width: 100%;
}

.filters{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}
.search-area input, .search-area input:focus{
  background-color: transparent !important;
  min-width: 300px !important;
  font-size: 14px !important;
  border: none !important;
}
.search-area input::placeholder{
  color: v-bind('color') !important;
}
.category-badge{
  padding: 5px 20px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 10px;
}
.tire-badge{
  background-color: #2A41951F;
  color: #2A4195;
}
.part-badge{
  background-color: #D1548F1F;
  color: #D1548F;
}
.oil-badge{
  background-color: #F4B2061F;
  color: #F4B206;
}
.rim-badge{
  background-color: #8D82FD1F;
  color: #8D82FD;
}
.order-success-badge{
  color: #28C76F;
  background-color: #28C76F1F;
}
.order-cancelled-badge{
  color: #EA5455;
  background-color: #EA54551F;
}
.order-other-badge{
  color: #FF9F43;
  background-color: #FF9F431F;
}
.table-action span{
  font-weight: 500;
}
.filter-input{
  display: flex;
  border: 1px solid v-bind('color');
  align-items: center;
  border-radius: 5px;
  padding: 4px 16px;
  color: v-bind('color');
  min-width: 300px;
}
.filter-input input, .filter-input input:focus{
  color: v-bind('color');
  padding-left: 0 !important;
  font-size: 14px;
  border: none !important;
}
.filter-input input::placeholder {
  color: v-bind('color');
}
.tab-item{
  padding: 10px 16px;
  color: #323338;
  border: 1px solid #6D6E6F80;
  border-radius: 200px;
  cursor: pointer;
  transition: .3s;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  letter-spacing: 0.4000000059604645px;
  text-align: center;
  margin-right: 10px;

}
.product-count-badge{
  font-size: 11px;
  border-radius: 10px;
  background-color: #e8e8e8;
  color: #6d6e6f;
  padding: 4px 7px;
  margin-left: 5px;
}
.active-tab .product-count-badge{
  background-color: #f4f4f4;
  color: v-bind('color');
}
.active-tab, .tab-item:hover {
  color: #f4f4f4;
  border: 1px solid v-bind('color');
  background-color: v-bind('color');
}
td span{
  color: #777777E5;
  font-size: 13px;
  font-family: Inter;
}
.see-detail-btn, .see-detail-btn:hover{
  border: 1px solid v-bind('color');
  color: v-bind('color');
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  padding: 6px 25px;
}
.default-badge{
  color: #06A5D0;
  background-color: rgba(6, 165, 208, 0.12);
}

.title-border {
  padding-bottom: 16px;
  border-bottom: 1px solid #00000033;
}

.table-action {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  color: #777;
}

.table-title {
  font-family: Inter;
  font-size: 17px;
  font-weight: 600;
  line-height: 16px;
  color: #1C1C1C;
}

.table-description {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  margin-top: 8px;
  text-align: left;
  color: #323338;
}
.filter-action, .filter-action:hover {
  background-color: #6D6E6F1F;
  border-radius: 5px;
  padding: 10px 18px;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: #6D6E6F;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
}
</style>
