import Vue from "vue";
import Vuex from 'vuex';

Vue.use(Vuex);

import axiosInstance from "@/plugins/axios";

const state = {
    list:[],
    loader: true,
    selected:{}
};

const mutations = {
    setList(state, data) {
        state.list = data
    },
    setSelected(state,data){
        state.selected = data
    }
};
const actions = {
    getCountries({commit}, data) {
        try{
            axiosInstance.get('/v1/region/countries').then(response=>{
                if(response.data.status){
                    commit("setList",response.data.responseData.countries)
                    return true
                }
            })
            return true
        }catch(error) {
            console.log(error)
            return false
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
