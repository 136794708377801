<template>
  <b-sidebar id="notification-sidebar" aria-label="Sidebar with custom footer" right backdrop  shadow>
    <template #header="{ hide }">
      <div class="d-flex justify-content-end align-items-center w-100">
        <img src="/images/icons/close.svg" alt="" class="cursor-pointer" @click="hide">
      </div>
    </template>

    <div class="px-4 py-2">
      <div class="sidebar-content-title">{{$t('Notifications')}}</div>

      <div v-if="$store.state.notification.loader">
        <b-skeleton width="100" v-for="i in 15" class="mt-2" :key="i" />
      </div>
      <div v-else>
        <span class="notification-date" v-if="notifications.length === 0">{{$t('You have no notifications yet.')}}</span>
        <div class="notification-list" v-else>
          <div v-for="item in notifications" :key="item.$index" class="notification-item">
          <span style="min-width: 40px;">
            <span class="icon-badge shop-badge" v-if="item.title.includes('sipariş')">
            <i class="fa-solid fa-message"></i>
          </span>
          </span>
            <div class="d-flex flex-column">
              <span class="notification-title">{{item.title}} (#{{item.record_id}})</span>
              <span class="notification-date">{{dateFormat(item.date)}} - {{dateTimeFormat(item.date).substring(10,16)}}</span>
            </div>

          </div>
        </div>
      </div>

<!--      <div class="sidebar-content-title finance-area">{{$t('Finance and Payments')}}</div>-->

<!--      <div v-for="i in 4" :key="i.$index" class="finance-item">-->
<!--        <div class="finance-title">Deniz Özcan</div>-->
<!--        <div class="finance-date">18/01/2023 - 12:00</div>-->
<!--      </div>-->

<!--      <div class="sidebar-content-title finance-area">{{$t('Campaigns')}}</div>-->
<!--      <div class="campaign-item" v-for="k in 5" :key="k">-->
<!--        <img class="campaign-img" src="/images/campaign_image.png" alt="">-->
<!--        <div class="campaign-info">-->
<!--          <span class="campaign-title">18’’ ve Üzeri Tüm Lastik Alışverişlerinizde 1.500TL Değerinde Shell Yakıt Çeki Hediye!</span>-->
<!--          <span class="campaign-date">Bitiş Tarihi: 24/09/2024</span>-->
<!--        </div>-->
<!--      </div>-->
    </div>

    <template #footer="{}">
      <div style="padding: 1rem 0;">

      </div>
    </template>
  </b-sidebar>
</template>

<script>
export default {
  name: "NotificationsSidebar",
  computed:{
    notifications() {
      return this.$store.state.notification.list
    }
  }
}
</script>

<style scoped>
.sidebar-content-title{
  font-size: 18px;
  font-weight: 600;
}
.notification-item {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin-top: 12px;
}
.icon-badge{
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}
.shop-badge{
  background-color: #28C76F1F;
  color: #28C76F;
}
.appointment-badge{
  background-color: #F4B20614;
}
.car-badge {
  background-color: #07C7E314;
}
.product-badge {
  background-color: #D1548F14;
}
.notification-title{
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.notification-date {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 4px;
  color: #00000066;
}
.finance-area{
  margin-top: 32px;
}
.finance-item{
  margin-top: 12px;
}

.finance-title{
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.finance-date {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #00000066;
  margin-top: 4px;
}
.campaign-info{
  display: flex;
  flex-direction: column;
}
.campaign-img{
  width: 100%;
  height: auto;
  border-radius: 10px;
}
.campaign-title{
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 10px;
}
.campaign-date{
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #00000066;
  margin-top: 4px;
}
.campaign-item{
  margin: 16px 0;
}
</style>
