import Vue from "vue";
import Vuex from 'vuex';

Vue.use(Vuex);

import axiosInstance from "@/plugins/axios";
import i18n from "@/i18n";

const state = {
    list:[],
    pagination:{},
    loader: true,
    order:{},
    statuses: []
};

const mutations = {
    setLoader(state,data){
        state.loader = data
    },
    setList(state, data) {
        state.list = data.items
        state.pagination = data.pagination
    },
    setOrder(state,data){
        state.order = data
    }
};
const actions = {
    getOrders({commit}, data) {
        commit('setLoader',true)
        var params = {
            page: data.page,
            size: data.size,
            filter:{
                order_status_id: data.order_status_id === 0 ? null : data.order_status_id
            },
            start_date: data.start_date ?? null,
            end_date: data.end_date ?? null
        }
        if(data.search_text){
            params.filter.searchText = data.search_text
        }

        axiosInstance.get('/v1/customers/orders',{
            params
        }).then(response=>{
            if(response.data.status){
                commit("setList",response.data.responseData.order)
                commit('setLoader',false)
            }
        })
    },
    getOrderDetail({state,commit},data){
        commit('setLoader',true)
        axiosInstance.get(`/v1/customers/orders/${data.id}`).then(response=>{
            if(response.data.status){
                commit("setOrder",response.data.responseData.order)
                commit('setLoader',false)
            }
        })
    },
    getOrderStatusList({state}){
        try{
            var status = [
                {
                    value: 0,
                    code: null,
                    text: i18n.t('Choose')
                }
            ]
            axiosInstance.get(`/v1/customers/order-statuses`,{
                page: 1,
                size: 100
            }).then(response=>{
                if(response.data.status){
                    response.data.responseData.orderStatus.items.forEach(item=>{
                        status.push({
                            value: item.id,
                            text: item.name,
                            code: item.code
                        })
                    })
                    state.statuses = status
                    return true
                }
            })
            return true
        }catch(error) {
            return false
        }
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
