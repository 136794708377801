<template>
  <div class="srvslt-select" :class="show ? 'select-open' : 'select-close'" v-click-outside="close">
    <div class="input" :class="{ 'select-open': show===true, 'select-close': show!==true}" @click="list.length > 0 && show === false ? show = !show : ''">
      <div class="text-overflow" v-if="list.length > 0">
        <span v-if="!show">
          <span class="select-content" v-if="Object.keys(model).length>0">
               {{model.dealer_title}}
           </span>
          <span class="select-content-place-holder" v-else>
            <i class="fa-solid fa-magnifying-glass fs-14" style="color: rgba(109, 110, 111, 0.4);"></i>
            {{$t('Choose')}}</span>
        </span>
        <span v-else class="d-flex align-items-center gap-2">
          <i class="fa-solid fa-magnifying-glass fs-14" style="color: rgba(109, 110, 111, 0.4);"></i>
          <b-form-input class="border-0 px-0" :placeholder="$t('Find Dealer')" v-model="search" />
        </span>

      </div>
      <div v-else>
        <div v-if="show">
           <span class="d-flex align-items-center gap-2">
          <i class="fa-solid fa-magnifying-glass fs-14" style="color: rgba(109, 110, 111, 0.4);"></i>
          <b-form-input class="border-0 px-0" :placeholder="$t('Find Dealer')" v-model="search" />
        </span>
        </div>
        <div v-else>
          <span class="select-content-placeholder">{{ $t('Choose') }}</span>
        </div>
      </div>
      <div class="icons ml-3">
        <b-spinner variant="secondary" v-if="loading" small></b-spinner>
        <span v-else>
          <span v-if="dealers.length>0">
                    <i class="fa" :class="show ? 'fa-chevron-up' : 'fa-chevron-down'"></i>

          </span>
        </span>
        <!--        <i class="fa-solid fa-xmark mr-1 font-servislet-dark" v-if="Object.keys(model).length>0" @click="removeModel()"></i>-->
      </div>
    </div>
    <div class="select-list" v-if="show">
      <hr>
      <div class="scrolling-area">
        <div class="list-content" v-for="item in list" :key="item.$index" @click="setItem(item)" v-if="list.length > 0">
          <span class="text-overflow">{{ item.dealer_title }} - {{item.dealer_code}}</span>
        </div>
      </div>
      <div class="list-content no-hover" v-if="list.length === 0">
        <span>Sonuç bulunamadı...</span>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";
import axios from "@/plugins/axios";

export default {
  name: "CurrentSelect",
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {},
  data() {
    return {
      loading: true,
      show: false,
      search: '',
      model: '',
      selected_array: [],
      dealers: []
    }
  },
  mounted() {
    this.getDealerList()
  },
  computed: {
    list() {
      if(this.search && this.search.length >3) {
        return this.search
            ? this.dealers.filter(item => item.dealer_code.toLocaleLowerCase('tr').includes(this.search.toLocaleLowerCase('tr')) ||
                item.dealer_title.toLocaleLowerCase('tr').includes(this.search.toLocaleLowerCase('tr')) ||
                item.contact_name.toLocaleLowerCase('tr').includes(this.search.toLocaleLowerCase('tr'))
            )
            : this.dealers
      }else{
        return this.dealers
      }
    },
    color() {
      return localStorage.getItem('color')
    }
  },
  methods: {
    getDealerList(){
      this.loading = true
      axios.get('/v1/customers/integration/erp/dealers').then(response=>{
        this.dealers = response.data.responseData.integrationDealer
        this.loading = false
      })
    },
    close() {
      this.show = false
      this.search = ''
    },
    setItem(item) {
      this.model = item
      this.close()
      this.$emit('setDealer', this.model)
    },
    removeModel() {
      this.model = ''
      this.close()
      this.$emit('setDealer', {})
    }
  }

}
</script>

<style scoped>
.srvslt-select {
  position: relative;
  width: 100%;
  z-index: 3;
  margin-top: 21px;
}

.input.select-open {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid v-bind('color');
  border-bottom: none;
}

.input.select-close {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.input {
  background-color: transparent;
  padding: 2px 12px;
  height: 42px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  z-index: 2;
  border: 1px solid #D8D6DE;
  border-radius: 5px;
}

.select-list {
  background-color: #fff;
  position: absolute;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  width: 100%;
  z-index: 3;
  max-height: 330px;
  overflow-y: auto;
  border: 1px solid v-bind('color');
  border-top: none;
}

.select-list::-webkit-scrollbar {
  display: none !important;
}

.list-content {
  display: flex;
  align-items: center;
  padding: 10px 12px;
  gap: 10px;
  font-size: 14px;
}

.select-content {
  display: block;
  max-width: 215px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: #323338;
}

.icons {
  display: flex;
  gap: 5px;
  font-size: 14px;
  color: #777777E5;
}

.select-content-placeholder {
  color: #D8D6DE;
}

.list-content:hover {
  background-color: #2A41951F !important;
  color: v-bind('color') !important;
}

@media only screen and (max-width: 575px) {
  .srvslt-select {
    max-width: 100%;
  }
}

.input .select-close {
  border-bottom: 1px solid #D8D6DE !important;
}

.select-content-place-holder {
  color: #323338;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 40px;
}

hr {
  margin: 0 13px .5rem 13px;
}

.select-content-placeholder {
  font-size: 14px;
}

.no-hover:hover {
  background-color: #fff !important;
  color: #323338 !important;
}

.scrolling-area {
  max-height: 270px;
  overflow-y: scroll;
}

.scrolling-area::-webkit-scrollbar {
  display: none;
}
.fs-14{
  font-size: 14px;
}
</style>
