<template>
  <div>
    <div class="col-sm-12 col-lg-12 d-flex flex-column justify-content-between" style="min-height: 400px;">
      <b-skeleton animation="wave" width="100%" v-for="i in 15" :key="i.$index"></b-skeleton>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader"
}
</script>

<style scoped>

</style>
