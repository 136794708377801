<template>
  <svg width="31" height="16" viewBox="0 0 31 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.26789 0H20.3066C20.8589 0 21.3066 0.447716 21.3066 1V9.33079C21.3066 9.88307 20.8589 10.3308 20.3066 10.3308H1.26789C0.715603 10.3308 0.267889 9.88307 0.267889 9.33079V1C0.267889 0.447715 0.715603 0 1.26789 0Z" fill="black"/>
    <path d="M24.8943 11.5518C26.077 11.5518 27.0356 12.5104 27.0356 13.6931C27.0356 14.8758 26.077 15.8334 24.8943 15.8334C23.712 15.8334 22.7523 14.8744 22.7523 13.6931C22.7527 12.5104 23.712 11.5518 24.8943 11.5518ZM24.8943 14.6234C25.4079 14.6234 25.825 14.2067 25.825 13.6931C25.825 13.1788 25.4076 12.7624 24.8943 12.7624C24.3804 12.7624 23.9637 13.1791 23.9637 13.6931C23.9637 14.2067 24.3807 14.6234 24.8943 14.6234Z" fill="black"/>
    <path d="M22.1762 3.09375H26.0039C27.1515 3.09375 28.1087 3.15718 28.5548 4.24131C29.0012 5.32578 30.0216 8.13279 30.0216 8.13279V11.6624H30.6924V13.949H27.6746C27.6828 13.8651 27.6876 13.7806 27.6876 13.696C27.6876 12.1542 26.4377 10.9037 24.8949 10.9037C23.3524 10.9037 22.1025 12.1542 22.1025 13.696C22.1025 13.7806 22.1073 13.8651 22.1148 13.949H18.7871L17.3987 12.6883H14.0529C13.79 12.0113 13.2719 11.4609 12.6131 11.1601H22.1759L22.1762 3.09375ZM23.5465 6.31784L25.1974 7.83883H28.783C28.5548 7.21645 28.0102 5.73741 27.737 5.07479C27.3875 4.2246 26.6369 4.17481 25.7372 4.17481H23.5465V6.31784ZM8.8488 12.6886H7.69783C7.43489 12.0103 6.91619 11.4599 6.258 11.1591H10.289C9.63112 11.4609 9.11242 12.01 8.8488 12.6886ZM3.93287 11.1591C3.27536 11.4612 2.75564 12.01 2.4927 12.6886H-0.000217438V11.1591H3.93287Z" fill="black"/>
    <path d="M11.4516 11.5518C12.6339 11.5518 13.5933 12.5104 13.5933 13.6931C13.5933 14.8758 12.6339 15.8334 11.4516 15.8334C10.2682 15.8334 9.30926 14.8744 9.30926 13.6931C9.30926 12.5104 10.2682 11.5518 11.4516 11.5518ZM11.4516 14.6234C11.9652 14.6234 12.3823 14.2067 12.3823 13.6931C12.3823 13.1788 11.9635 12.7624 11.4516 12.7624C10.9373 12.7624 10.5209 13.1791 10.5209 13.6931C10.5209 14.2067 10.9373 14.6234 11.4516 14.6234Z" fill="black"/>
    <path d="M5.09511 11.5518C6.27848 11.5518 7.23779 12.5104 7.23779 13.6931C7.23779 14.8758 6.27848 15.8334 5.09511 15.8334C3.91276 15.8334 2.95481 14.8744 2.95481 13.6931C2.95481 12.5104 3.9131 11.5518 5.09511 11.5518ZM5.09511 14.6234C5.60938 14.6234 6.02578 14.2067 6.02578 13.6931C6.02578 13.1788 5.60768 12.7624 5.09511 12.7624C4.58152 12.7624 4.16444 13.1791 4.16444 13.6931C4.16546 14.2067 4.58186 14.6234 5.09511 14.6234Z" fill="black"/>
    <path d="M5.05849 11.4463C3.80332 11.4463 2.78174 12.4676 2.78174 13.7227C2.78174 14.9776 3.80332 15.9995 5.05849 15.9995C6.31397 15.9995 7.33525 14.9782 7.33525 13.7227C7.33525 12.4673 6.31397 11.4463 5.05849 11.4463Z" :fill="color"/>
    <path d="M11.4457 11.4453C10.1905 11.4453 9.16895 12.4666 9.16895 13.7218C9.16895 14.9766 10.1905 15.9985 11.4457 15.9985C12.7012 15.9985 13.7225 14.9772 13.7225 13.7218C13.7225 12.4663 12.7012 11.4453 11.4457 11.4453Z" :fill="color"/>
    <path d="M24.9716 11.4463C23.7164 11.4463 22.6948 12.4676 22.6948 13.7227C22.6948 14.9776 23.7164 15.9995 24.9716 15.9995C26.2271 15.9995 27.2483 14.9782 27.2483 13.7227C27.2483 12.4673 26.2271 11.4463 24.9716 11.4463Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: "Truck",
  computed:{
    color(){
      return localStorage.getItem('color')
    }
  }
}
</script>

<style scoped>

</style>
