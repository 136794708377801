import Vue from "vue";
import Vuex from 'vuex';
import axiosInstance from "@/plugins/axios";

Vue.use(Vuex);

const state = {
    list: [],
    pagination: {},
    loader: true
};

const mutations = {
    setLoader(state, data) {
        state.loader = data
    },
    setList(state, data) {
        state.list = data.items
        state.pagination = data.pagination
    }
};
const actions = {
    getPlaciers({state,commit},data){
        state.loader = true

        var params = {
            page: data.page ?? 1,
            size: data.size,
            sort:{
                column: data.sort_column,
                direction: data.sort_direction
            },
            filter:{}
        }

        if(data.search){
            params.filter.searchText = data.search
        }
        axiosInstance.get(`/v1/customers/salesmen`,{
            params
        }).then(response => {
            if (response.data.status) {
                commit('setList',response.data.responseData.salesman)
                state.loader = false
            }
        })
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
