<template>
  <svg width="35" height="14" viewBox="0 0 35 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M33.091 4.66478C28.9161 3.5661 26.3525 3.71259 25.7665 3.49286C25.4736 3.41961 25.7665 3.49286 21.2986 0.782804C20.8592 0.489825 20.2732 0.343337 19.7605 0.270092C14.7066 -0.315866 8.77377 -0.169377 4.74531 2.98015C4.52557 3.12664 4.37908 3.27313 4.15935 3.27313L1.81552 4.00557C1.37605 4.15206 1.00983 4.44504 0.863337 4.81126C0.0576449 6.20291 -0.235334 7.96079 0.204134 9.57217C0.350624 10.2314 0.863337 10.6708 1.44929 10.8173L2.03525 10.9638C2.03525 10.7441 1.96201 10.5976 1.96201 10.3779C1.96201 7.74105 4.08611 5.61696 6.72292 5.61696C9.35973 5.61696 11.4838 7.74105 11.4838 10.3779C11.4838 10.7441 11.4106 11.1103 11.3373 11.4033H24.7411C24.6679 11.0371 24.5946 10.7441 24.5946 10.3779C24.5946 7.74105 26.7187 5.61696 29.3555 5.61696C31.9923 5.61696 34.1164 7.74105 34.1164 10.3779C34.1164 10.6708 34.1164 10.9638 34.0432 11.2568C34.6292 10.9638 34.9954 10.3046 34.9954 9.64542V7.37483C35.0686 6.05642 34.2629 4.95775 33.091 4.66478ZM11.5571 4.59153C11.3373 4.59153 11.1176 4.51829 10.9711 4.29855L9.21324 2.17445C10.8246 1.66174 12.729 1.36876 14.8531 1.36876L15.732 4.59153H11.5571ZM23.5692 4.51829C23.4227 4.59153 23.3495 4.59153 23.203 4.59153H17.2702L16.3912 1.44201C17.4166 1.51525 18.4421 1.5885 19.4675 1.66174C19.7605 1.66174 20.1267 1.80823 20.4197 1.95472L24.0087 4.15206L23.5692 4.51829Z" :fill="main_color"/>
    <path d="M6.72277 13.6009C8.54311 13.6009 10.0188 12.1253 10.0188 10.3049C10.0188 8.48459 8.54311 7.00891 6.72277 7.00891C4.90243 7.00891 3.42676 8.48459 3.42676 10.3049C3.42676 12.1253 4.90243 13.6009 6.72277 13.6009Z" :fill="color"/>
    <path d="M29.4278 13.6009C31.2482 13.6009 32.7239 12.1253 32.7239 10.3049C32.7239 8.48459 31.2482 7.00891 29.4278 7.00891C27.6075 7.00891 26.1318 8.48459 26.1318 10.3049C26.1318 12.1253 27.6075 13.6009 29.4278 13.6009Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: "Passenger",
  computed:{
    color(){
      return localStorage.getItem('color')
    }
  },
  props:{
    main_color: {
      type: String,
      default: '#1c1c1c'
    }
  }
}
</script>

<style scoped>

</style>
