<template>
<!--  <div style="position: fixed" :class="expanded_menu ? 'w-260' : 'w&#45;&#45;100'">-->
  <div class="d-flex flex-column">
    <div style="height: 25px; background-color: #f9f9f9; position: fixed;top: 0; width: 100%; z-index: 999;"></div>
    <div class="topbar w-310">
      <div></div>
      <div class="d-flex align-items-center">
        <span class="name cursor-pointer" @click="$store.dispatch('notification/getNotifications')" v-b-toggle.notification-sidebar>
          <notification :color="'#f9f9f9'" />
        </span>

        <b-dropdown id="profil-dropdown" no-caret>
          <template #button-content>
            <span class="b2b-logo">
              <img :src="logo" alt="B2B" style="width: 85px;">
            </span>
          </template>
          <b-dropdown-item href="#"><i class="fa-regular fa-user" style="margin-right: .5rem;"></i>{{$t('Profile')}}</b-dropdown-item>
          <b-dropdown-item @click="logout()"><i class="fa-solid fa-right-from-bracket" style="margin-right: .5rem;"></i>{{$t('Logout')}}</b-dropdown-item>
        </b-dropdown>
<!--        <div class="b2b-logo">-->
<!--          <img src="https://cdn.servislet.com/content/xDRcAvIGrWFMEZUyDZaNIrGqch1rwsTiQBFrtjy5.png" alt="B2B" style="width: 85px;">-->
<!--        </div>-->
      </div>
    </div>
    <NotificationSidebar />
  </div>
</template>

<script>
import Notification from "@/components/topbar/Notification.vue";
import NotificationSidebar from "@/components/NotificationSidebar.vue";
export default{
  name: 'Topbar',
  components:{
    Notification,
    NotificationSidebar
  },
  data(){
    return{
      expanded_menu: this.$store.state.menu.status
    }
  },
  computed:{
    color(){
      return localStorage.getItem('color')
    },
    opacity(){
      return localStorage.getItem('color').substring(0,localStorage.getItem('color').length-1+', 0.1)')
    },
    logo(){
      return localStorage.getItem('logo')
    }
  },
  watch:{
    expanded_menu(){
      return this.$store.state.menu.status
    }
  },
  methods:{
    logout(){
      localStorage.clear()
      this.$router.replace({ name: 'Login' });
    }
  }
}
</script>

<style scoped>
.topbar{
  //height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: v-bind('color');
  color: #f4f4f4;
  border-radius: 10px;
  padding: 12px 15px;
  position: fixed;
  top: 25px;
  z-index: 999;
}
.b2b-logo{
  background-color: #F9F9F9;
  border-radius: 5px;
  width: 113px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 22px;
}
.b2b-logo img{
  max-height: 46px;
  width: auto;
}
.w-310{
  width: calc(100% - 310px)
}
.w--150{
  width: calc(100% - 150px)
}
</style>

<style>
#profil-dropdown__BV_toggle_ {
  font-family: Montserrat !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  padding: 0 !important;
  background-color: transparent !important;
  border: none !important;
}
#profil-dropdown__BV_toggle_ i {
  margin-left: 10px !important;
}
#profil-dropdown .dropdown-menu {
  margin-top: 4px !important;
  box-shadow: -2px 1px 10px 0px #0000001F !important;
  border: none !important;
  padding: 0 !important;
}

#profil-dropdown .dropdown-menu .dropdown-item {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  padding: 12px 20px !important;
}

#profil-dropdown__BV_toggle_ {
  color: v-bind('color') !important;
}
#profil-dropdown .dropdown-menu .dropdown-item:hover{
  background-color: v-bind('opacity') !important;
  color:  v-bind('color') !important;
}
#finance-actions__BV_toggle_ {
  border: 1px solid  v-bind('color') !important;
  background-color: v-bind('opacity') !important;
  color:  v-bind('color') !important;
}
#finance-actions .dropdown-menu .dropdown-item:hover{
  background-color: v-bind('opacity') !important;
  color:  v-bind('color') !important;
}
.dropdown-menu .dropdown-item:focus{
  background-color: v-bind('opacity') !important;
  color:  v-bind('color') !important;
}
</style>
