<template>
   <span class="position-relative">
     <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M19.0834 15.5833C19.0834 16.1333 18.7167 16.5 18.1667 16.5H-0.166646C-0.716646 16.5 -1.08331 16.1333 -1.08331 15.5833C-1.08331 15.0333 -0.716646 14.6667 -0.166646 14.6667C0.841687 14.6667 1.66669 13.8417 1.66669 12.8333V8.25C1.66669 4.21666 4.96669 0.916664 9.00002 0.916664C13.0334 0.916664 16.3334 4.21666 16.3334 8.25V12.8333C16.3334 13.8417 17.1584 14.6667 18.1667 14.6667C18.7167 14.6667 19.0834 15.0333 19.0834 15.5833ZM11.3834 19.7083C10.8334 20.625 9.91669 21.0833 9.00002 21.0833C8.54169 21.0833 8.08335 20.9917 7.62502 20.7167C7.16669 20.4417 6.89169 20.1667 6.61669 19.7083C6.34169 19.25 6.52502 18.7 6.98335 18.425C7.44169 18.15 7.99169 18.3333 8.26669 18.7917C8.29443 18.8194 8.32218 18.8556 8.35248 18.895L8.35249 18.895C8.42226 18.9859 8.50553 19.0944 8.63335 19.1583C9.09169 19.4333 9.64169 19.25 9.91669 18.7917C10.1917 18.3333 10.7417 18.2417 11.2 18.425C11.6584 18.6083 11.6584 19.25 11.3834 19.7083ZM14.5 12.8333C14.5 13.475 14.6834 14.1167 14.9584 14.6667H3.04169C3.31669 14.1167 3.50002 13.475 3.50002 12.8333V8.25C3.50002 5.225 5.97502 2.75 9.00002 2.75C12.025 2.75 14.5 5.225 14.5 8.25V12.8333Z" fill="#F9F9F9"/>
        <mask id="mask0_81_20682" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="-2" y="0" width="22" height="22">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.0834 15.5833C19.0834 16.1333 18.7167 16.5 18.1667 16.5H-0.166646C-0.716646 16.5 -1.08331 16.1333 -1.08331 15.5833C-1.08331 15.0333 -0.716646 14.6667 -0.166646 14.6667C0.841687 14.6667 1.66669 13.8417 1.66669 12.8333V8.25C1.66669 4.21666 4.96669 0.916664 9.00002 0.916664C13.0334 0.916664 16.3334 4.21666 16.3334 8.25V12.8333C16.3334 13.8417 17.1584 14.6667 18.1667 14.6667C18.7167 14.6667 19.0834 15.0333 19.0834 15.5833ZM11.3834 19.7083C10.8334 20.625 9.91669 21.0833 9.00002 21.0833C8.54169 21.0833 8.08335 20.9917 7.62502 20.7167C7.16669 20.4417 6.89169 20.1667 6.61669 19.7083C6.34169 19.25 6.52502 18.7 6.98335 18.425C7.44169 18.15 7.99169 18.3333 8.26669 18.7917C8.29443 18.8194 8.32218 18.8556 8.35248 18.895L8.35249 18.895C8.42226 18.9859 8.50553 19.0944 8.63335 19.1583C9.09169 19.4333 9.64169 19.25 9.91669 18.7917C10.1917 18.3333 10.7417 18.2417 11.2 18.425C11.6584 18.6083 11.6584 19.25 11.3834 19.7083ZM14.5 12.8333C14.5 13.475 14.6834 14.1167 14.9584 14.6667H3.04169C3.31669 14.1167 3.50002 13.475 3.50002 12.8333V8.25C3.50002 5.225 5.97502 2.75 9.00002 2.75C12.025 2.75 14.5 5.225 14.5 8.25V12.8333Z" fill="white"/>
        </mask>
        <g mask="url(#mask0_81_20682)">
        <rect x="-2" width="22" height="22" fill="#F9F9F9"/>
        </g>
      </svg>
     <span class="count">{{count > 99 ? '99+' : count}}</span>
   </span>
</template>

<script>
export default {
  name: "Shop",
  props:{
    color:{
      type: String,
      default: '#1c1c1c'
    }
  },
  computed:{
    colorr(){
      return localStorage.getItem('color')
    },
    count(){
      return this.$store.state.notification.count
    }
  },
  mounted(){
    this.$store.dispatch('notification/getNotificationCount')
  },
  watch: {
    '$route': function() {
      this.$store.dispatch('notification/getNotificationCount')
    }
  }
}
</script>

<style scoped>
.count{
  position: absolute;
  background-color: #f9f9f9;
  color: v-bind('colorr');
  border-radius: 100%;
  font-size: 9px;
  min-width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -4px;
  margin-left: 10px;
}
</style>
