<template>
  <div>
    <Default>
      <div class="page-content">
        <ul id="breadcrumb-scroll">
          <li>
            <a href="/" itemprop="item"><span itemprop="name">{{ $t('Summary') }}</span></a>
          </li>
          <li>
          <span class="active">
               {{ $t('Payments') }}
          </span>
          </li>
        </ul>
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3 summary-item-height">
            <div class="summary-item">
              <div class="title">{{ $t('Balance') }}</div>
              <div class="sub-data">{{ currenctFormat('tr-TR', 0) }}</div>
              <div class="description">{{ $t('Payment Date') }}: <span
                  class="fw-bold"> -</span>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3 summary-item-height">
            <div class="summary-item">
              <div class="title">{{ $t('Auto Payment') }}</div>
              <div class="sub-data">{{ currenctFormat('tr-TR', 0) }}</div>
              <div class="description">Mastercard:
                <!--                <span class="fw-bold">***** 4013</span>-->
                <span class="fw-bold"> -</span>
              </div>
            </div>
          </div>
        </div>

        <div class="table-content product-listing-table neu col-sm-12 col-lg-12">

          <div class="filters">
            <div class="d-flex align-items-center gap-3">
              <div class="search-area dealer-border dealer-text">
                <b-form-input v-model="search_text" v-on:keydown.enter="filter()"
                              :placeholder="$t('Search by current, ID or ref code')" class="px-0"/>
                <i class="fa-solid fa-xmark cursor-pointer" @click="search_text = ''; clearRoute()"
                   v-if="search_text"></i>
                <i class="fa-solid fa-filter" v-else></i>
              </div>
              <div class="search-area dealer-border dealer-text">
                <i class="fa-regular fa-calendar-check"></i>
                <flat-pickr :placeholder="$t('Date Range')" v-model="date" class="form-control bg-transparent"
                            :config="{dateFormat: 'Y-m-d',mode: 'range',locale: this.locale,allowInput: true,enableTime: false,maxDate: this.end_date,minDate: this.calculate1YearAgo,onReady: this.addCustomButton,closeOnSelect:false}"/>
                <i class="fa-solid fa-xmark cursor-pointer" @click="date = null; clearDateRange()"
                   v-if="date"></i>
              </div>
              <payment-type-select @setPaymentType="setPaymentType($event)"/>

            </div>
            <!--            <div class="d-flex gap-2">-->
            <button class="btn filter-action" @click="excel()"><img
                src="/images/icons/download.svg"
                alt=""> Excel
            </button>
            <!--            </div>-->
          </div>

          <div v-if="!$store.state.payment.loader">
            <div class="table-responsive">
              <table id="productTable" class="w-100 no-last">
                <thead>
                <tr class="product-listing-table-head">
                  <th>{{ $t('Payment Date') }}</th>
                  <th>{{ $t('Dealer') }}</th>
                  <th>{{ $t('Payment') }}</th>
                  <th>
                    <div class="d-flex align-items-center cursor-pointer">
                      {{ $t('Payment Method') }}
                      <!--                      <i class="fa-solid fa-chevron-down ml-2"></i>-->
                    </div>
                  </th>
                  <th>
                    <div class="d-flex align-items-center cursor-pointer">
                      {{ $t('Bank') }}
                      <!--                      <i class="fa-solid fa-chevron-down ml-2"></i>-->
                    </div>
                  </th>
                  <th>
                    <div class="d-flex align-items-center justify-content-center cursor-pointer">
                      {{ $t('Card Number') }}
                      <!--                      <i class="fa-solid fa-chevron-down ml-2"></i>-->
                    </div>
                  </th>
                  <th>
                    <div class="d-flex align-items-center justify-content-center cursor-pointer">
                      {{ $t('Cash / Installment') }}
                      <!--                      <i class="fa-solid fa-chevron-down ml-2"></i>-->
                    </div>
                  </th>
                  <th></th>
                </tr>
                </thead>
                <tbody v-if="payments.length>0">
                <tr v-for="i in payments" :key="i.id">
                  <td>
                    {{ dateTimeFormat(i.created_at) }}
                  </td>
                  <td>
                    {{ i.dealer ? i.dealer.name : '-' }}
                  </td>
                  <td>{{ currenctFormat('tr-TR', i.total_amount) }}</td>
                  <td>
                    {{ i.paymentType ? i.paymentType.name : '-' }}
                    <!--                    <span class="category-badge tire-badge" v-if="i%2 === 0">{{ $t('EFT / Transfer') }}</span>-->
                    <!--                    <span class="category-badge part-badge" v-else>{{ $t('Bank / Credit Card') }}</span>-->
                  </td>
                  <td>{{ i.bank_name ?? '-' }}</td>
                  <td class="text-center">
                    {{ i.card_number ?? '-' }}
                  </td>
                  <td class="text-center">
                    {{ i.installment_count === 0 ? $t('Cash') : i.installment_count + ' ' + $t('Installment') }}
                  </td>
                  <td>
                    <div class="d-flex justify-content-end">
                      <button class="btn see-detail-btn opacitied-bg" @click="$router.push(`/payments/${i.id}`)">
                        <i class="fa-solid fa-eye"></i>
                      </button>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
              <div class="text-center my-3" v-if="payments.length === 0">
                {{ $t('No payment found.') }}
              </div>
            </div>

            <hr>

            <div class="d-flex justify-content-between pagination-count align-items-center">
                <span>
                  <span class="pagination-text">{{ $t('Show by per page:') }} &nbsp;</span>
                  <select name="pagination" id="pagination-select" @change="getList()" v-model="size">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </span>
              <span class="d-flex align-items-center" style="gap: 1rem;">
                   <span>
             <span class="fw-bolder">{{ $store.state.payment.pagination.total }}</span> ödemeden
                  <span
                      class="fw-bolder">{{
                      $store.state.payment.pagination.current_page === 1 ? 1 : (($store.state.payment.pagination.current_page - 1) * $store.state.payment.pagination.per_page) + 1
                    }} - {{
                      $store.state.payment.pagination.total_page === $store.state.payment.pagination.current_page ? $store.state.payment.pagination.total : $store.state.payment.pagination.current_page * $store.state.payment.pagination.per_page
                    }}</span> gösteriliyor.</span>
                <b-pagination
                    :total-rows="$store.state.payment.pagination.total"
                    :per-page="size"
                    align="center"
                    size="sm"
                    class="my-1"
                    first-number
                    last-number
                    v-model="currentPage"
                    @input="changeCurrentPage()"
                />
              </span>
            </div>

            <!--            <div class="d-flex justify-content-between pagination-count">-->
            <!--            <span>-->
            <!--             <span class="fw-bolder">{{ $store.state.payment.pagination.total }}</span> ödemeden-->
            <!--                  <span class="fw-bolder">{{ $store.state.payment.pagination.current_page === 1 ? 1 : (($store.state.payment.pagination.current_page - 1) * $store.state.payment.pagination.per_page) + 1 }} - {{ $store.state.payment.pagination.total_page === $store.state.payment.pagination.current_page ? $store.state.payment.pagination.total : $store.state.payment.pagination.current_page * $store.state.payment.pagination.per_page }}</span>-->
            <!--                  ödeme gösteriliyor.</span>-->
            <!--              <span>-->

            <!--                <b-pagination-->
            <!--                    :total-rows="$store.state.payment.pagination.total"-->
            <!--                    :per-page="$store.state.payment.pagination.per_page"-->
            <!--                    align="center"-->
            <!--                    size="sm"-->
            <!--                    class="my-1"-->
            <!--                    first-number-->
            <!--                    last-number-->
            <!--                    v-model="currentPage"-->
            <!--                    aria-controls="productTable"-->
            <!--                    @input="changeCurrentPage()"-->
            <!--                />-->
            <!--              </span>-->
            <!--            </div>-->
          </div>

          <div v-else>
            <loader/>
          </div>
        </div>
      </div>
    </Default>
  </div>
</template>

<script>
import Default from "@/layouts/default.vue";
import {Turkish} from '@/locales/flatpickr/tr'
import {English} from '@/locales/flatpickr/en'
import PaymentTypeSelect from "@/components/selects/payments/PaymentTypeSelect.vue";
import axios from "@/plugins/axios";
import i18n from "@/i18n";

export default {
  name: 'PaymentList',
  components: {
    Default,
    PaymentTypeSelect
  },
  data() {
    return {
      currentPage: 1,
      size: 10,
      search_text: null,
      sort_direction: null,
      sort_column: null,
      hour: '',
      end_date: '',
      date: null,
      locale: localStorage.getItem('locale') === 'tr' ? Turkish : English,
    }
  },
  mounted() {
    const currentDate2 = new Date();
    const year2 = currentDate2.getFullYear();
    const month2 = String(currentDate2.getMonth() + 1).padStart(2, '0');
    const day2 = String(currentDate2.getDate()).padStart(2, '0');
    const hour2 = String(currentDate2.getHours() + 3).padStart(2, '0');
    const minute2 = String(currentDate2.getMinutes()).padStart(2, '0');
    const second2 = String(currentDate2.getSeconds()).padStart(2, '0');

    this.end_date = `${year2}-${month2}-${day2}T${hour2}:${minute2}:${second2}`;
    this.getList()
  },
  watch: {
    '$route.query': function () {
      this.getList()
    }
  },
  computed: {
    calculate1YearAgo() {
      const oneYearAgoDate = new Date(new Date().getTime() - 365 * 24 * 60 * 60 * 1000);

      const oneYearAgoDateString = oneYearAgoDate.toISOString().replace(/T/, '-').replace(/\..+$/, '');

      return oneYearAgoDateString
    },
    payments() {
      return this.$store.state.payment.list
    },
    color() {
      return localStorage.getItem('color')
    }
  },
  methods: {
    addCustomButton(selectedDates, dateStr, instance) {
      // Create a custom button
      const customButton = document.createElement('button');
      customButton.innerHTML = this.$i18n.t('Apply Range');
      customButton.className = 'btn dealer-bg custom-flatpickr-button'; // Add your custom classes here
      customButton.onclick = () => {
        this.setDateRange()
      };

      instance.calendarContainer.appendChild(customButton);
    },
    clearDateRange() {
      delete this.$route.query['start_date']
      delete this.$route.query['end_date']
      const currentQuery = this.$route.query;
      const newQuery = {
        ...currentQuery
      };
      const queryString = Object.keys(newQuery).map(key => `${key}=${newQuery[key]}`).join('&');
      const url = `${window.location.pathname}?${queryString}`;

      window.location.href = url;
    },
    setDateRange() {
      if (this.date && this.date.length === 23) {
        var ar = this.date.split(' ')
        const currentQuery = this.$route.query;
        const newQuery = {
          ...currentQuery,
          start_date: ar[0],
          end_date: ar[2],
        };
        const queryString = Object.keys(newQuery).map(key => `${key}=${newQuery[key]}`).join('&');
        const url = `${window.location.pathname}?${queryString}`;
        window.location.href = url;
      }
    },
    filter() {
      const currentQuery = this.$route.query;
      const newQuery = {
        ...currentQuery,
        search: this.search_text
      };
      const queryString = Object.keys(newQuery).map(key => `${key}=${newQuery[key]}`).join('&');
      const url = `${window.location.pathname}?${queryString}`;
      window.location.href = url;
    },
    clearRoute() {
      delete this.$route.query['search']
      const currentQuery = this.$route.query;
      const newQuery = {
        ...currentQuery
      };
      const queryString = Object.keys(newQuery).map(key => `${key}=${newQuery[key]}`).join('&');
      const url = `${window.location.pathname}?${queryString}`;

      window.location.href = url;
    },
    setPaymentType(event) {
      if (event) {
        const currentQuery = this.$route.query;
        const newQuery = {
          ...currentQuery,
          payment_type_id: event.paymentType.id
        };
        const queryString = Object.keys(newQuery).map(key => `${key}=${newQuery[key]}`).join('&');
        const url = `${window.location.pathname}?${queryString}`;
        window.location.href = url;
      } else {
        delete this.$route.query['payment_type_id']
        const currentQuery = this.$route.query;
        const newQuery = {
          ...currentQuery
        };
        const queryString = Object.keys(newQuery).map(key => `${key}=${newQuery[key]}`).join('&');
        const url = `${window.location.pathname}?${queryString}`;

        window.location.href = url;
      }
    },
    getList() {
      if (this.$route.query.page) {
        this.currentPage = this.$route.query.page
      } else {
        this.currentPage = 1
      }
      if (this.$route.query.search) {
        this.search_text = this.$route.query.search
      }
      if (this.$route.query.start_date && this.$route.query.end_date) {
        this.date = this.$route.query.start_date + ' - ' + this.$route.query.end_date
      }
      this.$store.dispatch('payment/getPaymentList', {
        page: this.currentPage,
        size: this.size,
        search: this.search_text,
        start_date: this.$route.query.start_date && this.$route.query.end_date ? this.$route.query.start_date + 'T00:00:00' : null,
        end_date: this.$route.query.start_date && this.$route.query.end_date ? this.$route.query.end_date + 'T23:59:00' : null,
        payment_type_id: this.$route.query.payment_type_id
      })
    },
    changeCurrentPage() {
      const currentQuery = this.$route.query;
      const newQuery = {
        ...currentQuery,
        page: this.currentPage
      };
      this.$router.push({name: 'PaymentList', query: newQuery});
    },
    async excel() {
      try {
        var data = {
          start_date: this.$route.query.start_date ? this.$route.query.start_date + 'T00:00:00' : null,
          end_date: this.$route.query.end_date ? this.$route.query.end_date + 'T23:59:00' : null
        }
        if(this.$route.query.payment_type_id) {
          data.filter.payment_type_id = parseInt(this.$route.query.payment_type_id)
        }
        if(this.$route.query.search) {
          data.filter.searchText = this.$route.query.search
        }
        const response = await axios.post('/v1/customers/payments/download',
          data
        , {
          responseType: 'arraybuffer', // Important to set the response type
          headers: {
            'Authorization': "Bearer " + localStorage.getItem('access_token'), // Add your token if needed
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          }
        });

        // Create a blob from the response data
        const blob = new Blob([response.data], { type: response.headers['content-type'] });

        // Create a link element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = response.headers['content-disposition'].split('filename*=UTF-8\'\'')[1]; // Extract filename

        // Append to the body
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Clean up and remove the link
        document.body.removeChild(link);
      } catch (error) {
        this.$swal({
          iconHtml: '<img src="/images/toast/error.svg" width="32" height="32">',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          title: i18n.t('Error') + ' !',
          text: error.response.data.statusMessage,
          customClass: {
            icon: 'swal2-no-border'
          }
        })
      }
    }
  }
}

</script>


<style scoped>
.page-content {
  margin-bottom: 300px;
}

.summary-item-height {
  min-height: 150px;
}

.summary-item {
  background-color: #DCE4E745;
  border-radius: 18px;
  color: #1c1c1c;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: .5rem;
  transition: .3s all;
  cursor: pointer;
  height: 100%;
//min-height: 140px;
}

.summary-item .title {
  font-size: 16px;
  font-weight: 600;
}

.summary-item .sub-data {
  font-size: 32px;
  font-weight: 600;
}

.summary-item .description {
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
}

.summary-item:hover {
  background-color: #1c1c1c;
  color: #f4f4f4;
}

.table-content {
  padding: 30px;
  margin-top: 40px;
}

.trash-icon img {
  width: 12px;
  height: auto;
}

.product-image img {
  width: 100%;
}

.category-badge {
  padding: 6px 8px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 10px;
}

.tire-badge {
  background-color: rgba(42, 65, 149, 0.12);
  color: rgba(42, 65, 149, 1);
}

.part-badge {
  background-color: rgba(209, 84, 143, 0.12);
  color: rgba(209, 84, 84, 1);
}

.see-detail-btn, .see-detail-btn:hover {
  border: 1px solid v-bind('color');
  color: v-bind('color');
  font-family: Montserrat;
  font-size: 12px;
  padding: 10px 14px;
}

::placeholder {
  color: v-bind('color') !important;
}

.filter-action, .filter-action:hover {
  background-color: #6D6E6F1F;
  border-radius: 5px;
  padding: 10px 18px;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: #6D6E6F;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
}

.filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  gap: 10px;
  width: 100%;
  overflow-x: clip;
}

.filters::-webkit-scrollbar {
  display: none;
}

.search-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px;
  border-radius: 5px;
}

.search-area input, .search-area input {
  background-color: transparent !important;
  min-width: 265px !important;
  font-size: 14px !important;
  border: none !important;
}

.search-area input {
  color: v-bind('color');
}

.search-area input::placeholder {
  color: v-bind('color');
}
</style>
