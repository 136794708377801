import Vue from "vue";
import Vuex from 'vuex';

Vue.use(Vuex);

import axiosInstance from "@/plugins/axios";

const state = {
    list:[],
    pagination:{},
    loader: true
};

const mutations = {
    setLoader(state,data){
      state.loader = data
    },
    setList(state, data) {
        state.list = data.items
        state.pagination = data.pagination
    }
};
const actions = {
    getDealerAddress({commit}, data) {
        commit('setLoader',true)
        commit('setList',[])
        axiosInstance.get(`/v1/customers/dealer-addresses`,{
            params:{
                page:1,
                size: 100,
                filter:{
                    dealer_id: data
                }
            }
        }).then(response=>{
            if(response.data.status){
                commit("setList",response.data.responseData.dealerAddress)
                commit('setLoader',false)
            }
        })
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
