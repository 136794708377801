<template>
  <div>
    <Default>
      <div class="page-content">
        <ul id="breadcrumb-scroll">
          <li>
            <a href="/" itemprop="item"><span itemprop="name">{{ $t('Summary') }}</span></a>
          </li>
          <li>
            <a href="/orders" itemprop="item"><span itemprop="name">{{ $t('Orders') }}</span></a>
          </li>
          <li>
          <span class="active">
               {{ $t('Order Detail') }}
          </span>
          </li>
        </ul>
        <div class="order-tab-content row" v-if="!$store.state.order.loader">
          <div class="col-sm-10 col-md-10 col-lg-10 neu card-content">
            <!--            <img src="https://s3-alpha-sig.figma.com/img/ccf9/bcd8/4d26f54e79e72dd9355c270a21478a97?Expires=1722816000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=MN9JtbLkKs7F7Y61TWz9VQVb9llwrNrbffP8miKQrSF586j~W0pWgUGa0b~MkaherpJ0nQtAPbf0e2YeW9GQnsAxx591ClVUD1SLANfNlWNTBqEgM97VSnraHIfs3UyOMHlrPOh84UBRWPdp45ANJhUP-CIIXq28hr6UoSO4rBEuFutRvrBsmSztiaIue3HA7uEKJoiAIcbkOSR-jfCrEfnQCG6-SuMGhfeUPkYC66juI0EdVY7QRycp7NdOxDd9qCZKxR8ER9ehn8FSEFt99DH7pPYAah4WnXxw2iRLM8OlqPD13JCtPy9JeYuSSvv4Otbnru4UjRS7INW8Slkb3A__"-->
            <!--                height="70" alt="">-->
            <div class="info">
              <div class="d-flex flex-column" v-if="order.dealer">
                <span class="company-name">{{ order.dealer.name }}</span>
                <span
                    class="company-address">{{ order.delivery_address }} {{
                    order.delivery_district
                  }}/{{ order.delivery_city }}</span>
              </div>
              <div>
                <div class="left-info"><span>{{ $t('Order') + ' ID' }}: </span>#{{ order.id }}</div>
                <div class="left-info"><span>{{ $t('Order Date') }}: </span> {{ order.order_date ? dateTimeFormat(order.order_date) : '-' }}</div>
                <!--                <div class="left-info"><span>{{ $t('Receipt No') }}: </span>#M00000000003559</div>-->
              </div>
            </div>
            <div class="order-items-table table-responsive-sm">
              <table class="table">
                <thead>
                <tr>
                  <th scope="col" class="text-start th-first-padding">{{ $t('Stock Code') }}</th>
                  <th scope="col">{{ $t('Product/Service') }}</th>
                  <th scope="col" class="text-center">{{ $t('Quantity') }}</th>
                  <th scope="col" class="text-end">{{ $t('Discount') }} <span
                      v-if="order.discount_rate">(%{{ order.discount_rate }})</span></th>
                  <th scope="col" class="text-end">{{ $t('Unit Price') }}</th>
                  <th scope="col" class="text-end th-last-padding">{{ $t('Total') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in order.items" :key="item.$index">
                  <td class="text-start th-first-padding">{{ item.code }}</td>
                  <td>{{ item.name }}</td>
                  <td class="text-center">{{ item.quantity }}</td>
                  <td class="text-end">{{ currenctFormat('tr-TR', item.discount ?? 0) }}</td>
                  <td class="text-end">{{ currenctFormat('tr-TR', item.price ?? 0) }}</td>
                  <td class="text-end th-last-padding">{{ currenctFormat('tr-TR', item.total_amount ?? 0) }}</td>
                </tr>
                </tbody>
              </table>
            </div>

            <div class="bottom-info justify-content-end">
              <div class="bottom-info-right">
                <div class="d-flex justify-content-between align-items-center">
                  <span>{{ $t('Sub Total') }}:</span>
                  <span class="info-title">{{ currenctFormat('tr-TR', order.sub_amount) }}</span>
                </div>

                <div class="d-flex justify-content-between align-items-center">
                  <span>{{ $t('Discount') }} <span
                      v-if="order.discount_rate">(%{{ order.discount_rate }})</span>:</span>
                  <span class="info-title">{{ currenctFormat('tr-TR', order.discount_amount ?? 0) }}</span>
                </div>
                <hr class="m-0">

                <div class="d-flex justify-content-between align-items-center">
                  <span class="info-title">{{ $t('General Total') }} :</span>
                  <span class="info-title">{{ currenctFormat('tr-TR', order.total_amount) }}</span>
                </div>
              </div>
            </div>

            <div class="bottom-info justify-content-start">
              <div class="bottom-info-left">
                <div class="d-flex flex-column">
                  <span class="info-title">{{ $t('Delivery') }}:</span>
                  <span class="mt-2">{{order.delivery_address}}</span>
                  <span class="mt-2">{{order.delivery_city}} / {{order.delivery_district}}</span>
                  <span class="mt-2" v-if="order.delivery_name || order.delivery_surname">{{order.delivery_name}} / {{order.delivery_surname}}</span>
                  <span class="mt-2">{{order.delivery_phone}}</span>
                </div>

                <div class="d-flex flex-column">
                  <span class="info-title">{{ $t('Payment Type') }}:</span>
                  <span class="mt-2">{{order.paymentType ? order.paymentType.name : '-'}}</span>
                </div>

                <!--                <div class="d-flex flex-column">-->
                <!--                  <span class="info-title">{{ $t('Notes') }}:</span>-->
                <!--                  <span class="mt-2">Lorem ipsum dolor sit amet consectetur. Conguid adipiscing varius condimentum blandit tortor vitae. Pulvinar nisl erat venenatis urna rhoncus.</span>-->
                <!--                </div>-->
              </div>
            </div>
          </div>
          <div class="col-sm-2 col-md-2 col-lg-2 pr-0">
            <div class="right-button-content">
              <div style="line-height: 0">{{ $t('Order Status') }}</div>
              <b-form-select @change="openUpdateOrderStatus()" v-model="selected" :options="statuses" class="btn"
                             :class="status === 'completed' ? 'status-btn' : (status === 'canceled' ? 'cancelled-btn' : (status === 'waiting' ? 'order-other-badge' : 'default-status'))"></b-form-select>
              <div class="btn invoice-btn" @click="downloadOrderDetail()">{{ $t('Download Invoice') }}</div>
            </div>
          </div>
        </div>

        <div v-else class="order-tab-content row px-3">
          <div class="col-sm-12 col-md-12 col-lg-12 neu card-content">
            <loader/>
          </div>
        </div>
      </div>


      <b-modal id="order-update-status" ref="order-update-status" centered no-close-on-backdrop hide-header hide-footer>
        <div class="d-flex justify-content-end">
          <img src="/images/icons/close.svg" alt="" class="cursor-pointer" @click="$refs['order-update-status'].hide()">
        </div>

        <div class="status-content">

          <i class="fa-solid fa-circle-question dealer-text"></i>

          <div class="text-center">{{ $t('Are you sure you want to update order status?') }}</div>

          <div class="w-100 d-flex justify-content-end">
            <button class="modal-footer-button cancel-button" @click="$refs['order-update-status'].hide()">
              {{ $t('Cancel') }}
            </button>
            <button class="modal-footer-button save-button dealer-border dealer-bg" @click="updateOrderStatus()">{{ $t('Update') }}</button>
          </div>
        </div>

      </b-modal>
    </Default>
  </div>
</template>

<script>
import Default from "@/layouts/default.vue";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import axiosInstance from "@/plugins/axios";

export default {
  name: 'OrderDetail',
  components: {
    Default
  },
  data() {
    return {
      selected: null,
      status: null
    }
  },
  mounted() {
    this.$store.dispatch('order/getOrderStatusList')
    this.getDetail()
  },
  computed: {
    order() {
      return this.$store.state.order.order
    },
    statuses() {
      return this.$store.state.order.statuses
    },
    color(){
      return localStorage.getItem('color')
    }
  },
  watch: {},
  methods: {
    getDetail() {
      this.$store.commit('order/setLoader', true)
      axiosInstance.get(`/v1/customers/orders/${this.$route.params.id}`).then(response => {
        if (response.data.status) {
          this.$store.commit("order/setOrder", response.data.responseData.order)
          this.$store.commit('order/setLoader', false)

          if (this.order.orderStatus) {
            this.selected = this.order.orderStatus.id
            this.status = this.order.orderStatus.code
          }
        }
      })
    },
    downloadOrderDetail() {
      const divToPrint = document.querySelector('.col-lg-10');
      html2canvas(divToPrint, {
        useCORS: true,
        logging: true,
        scale: 2,
        backgroundColor: '#fff',
        width: divToPrint.offsetWidth
      }).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('l', 'px', [divToPrint.offsetWidth, divToPrint.offsetHeight]);
        pdf.addImage(imgData, 'PNG', 0, 0, divToPrint.offsetWidth, divToPrint.offsetHeight);
        const pdfBlob = pdf.output('blob');
        const link = document.createElement('a');
        link.href = URL.createObjectURL(pdfBlob);
        link.download = `${this.order.id}` + ' ' + this.$i18n.t('Order Detail') + '.pdf';
        link.click();
      });
      // const pageContent = document.querySelector('.col-lg-10');
      // pageContent.style.fontFamily = 'Inter';
      // pageContent.style.fontSize = '12px';
      // pageContent.style.fontWeight = '400';
      // const options = {
      //   bgcolor: '#ffffff', // set white background
      // };
      //
      // domtoimage.toPng(pageContent, options).then((dataUrl) => {
      //   const pdf = new jsPDF('p', 'mm', 'a4'); // 'p' for portrait, 'mm' for units, 'a4' for page size
      //   const pdfWidth = pdf.internal.pageSize.getWidth();
      //   const pdfHeight = '125'
      //
      //   pdf.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight);
      //   pdf.save(`order-detail.pdf`);
      // });
    },
    openUpdateOrderStatus() {
      if (this.selected > 0) {
        this.$refs["order-update-status"].show();
      } else {
        this.$swal({
          iconHtml: '<img src="/images/toast/warning.png" width="32" height="32">',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          title: this.$i18n.t('Warning') + ' !',
          text: this.$i18n.t('Please select a status to update.'),
          customClass: {
            icon: 'swal2-no-border'
          }
        })
      }
    },
    updateOrderStatus() {
      axiosInstance.put(`/v1/customers/orders/${this.$route.params.id}`,{
        order_status_id: this.selected
      }).then(response => {
        if (response.data.status) {
          this.$refs["order-update-status"].hide();
          this.$swal({
            iconHtml: '<img src="/images/toast/success.svg" width="32" height="32">',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            title: this.$i18n.t('Success') + ' !',
            text: this.$i18n.t('Order status successfully updated.'),
            customClass: {
              icon: 'swal2-no-border'
            }
          })
          this.getDetail()
        }
      }).catch(error => {
        this.$swal({
          iconHtml: '<img src="/images/toast/error.svg" width="32" height="32">',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          title: this.$i18n.t('Error') + ' !',
          text: error.response.data.statusMessage,
          customClass: {
            icon: 'swal2-no-border'
          }
        })
      })
    }
  }
}

</script>


<style scoped>
.order-tab-content {
  margin-top: 25px;
  padding: 0 13px;
}

.card-content {
  padding: 24px 30px;
}

.info {
  display: flex;
  justify-content: space-between;
  margin-top: 22px;
}

.company-name {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 17.07px;
  text-align: left;
}

.company-address {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  margin-top: 10px;
}

.left-info {
  text-align: right;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  line-height: 19.5px;
  margin-bottom: .5rem;
}

.left-info span {
  font-size: 14px;
  font-weight: 400;
  line-height: 17.07px;
}

.order-items-table {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 40px;
}

.order-items-table thead {
  background-color: #EEEEEE !important;
}

.order-items-table thead th {
  color: #777777 !important;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;

}

.order-items-table thead th:first-child {
  border-radius: 5px 0 0 5px !important;
}

.order-items-table thead th:last-child {
  border-radius: 0 5px 5px 0 !important;
}

.order-items-table thead tr {
  border: 1px solid transparent;
}

.order-items-table tbody td {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.bottom-info {
  display: flex;
  align-items: flex-start;
  margin-top: 30px;
  padding: 0 24px 0 0;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

.info-title {
  font-weight: 700;
  line-height: 12px;
}

.bottom-info-left, .bottom-info-right {
  display: flex;
  flex-direction: column;
  gap: 22px;
  text-align: left;
}

.bottom-info-left {
  max-width: 50%;
}

.bottom-info-right div {
  gap: 87px;
}

.right-button-content {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  padding: 0;
  padding-left: 1rem;
}

.status-btn, .status-btn:hover {
  background-color: #28C76F29;
  color: #28C76F;
  border: 1px solid #28C76F;
  font-size: 14px;
  width: 100%;
}

.invoice-btn, .invoice-btn:hover {
  background-color: #1c1c1c;
  color: #f4f4f4;
  border: 1px solid #1c1c1c;
  font-size: 14px;
  widtH: 100%;
}

.history-card {
  padding: 30px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
  color: #777;
}

.history-content {
  background-color: #fff;
  padding: 14px 38px;
  border-radius: 8px;
}

.bottom-border {
  border-bottom: 1px solid #E9ECEF99;
}

.fw-400 {
  font-weight: 400;
}

.cancelled-btn, .cancelled-btn:hover {
  background-color: #EA54551F;
  color: #EA5455;
  border: 1px solid #EA54551F;
  font-size: 14px;
  width: 100%;
}

.default-status, .default-status:hover {
  border: 1px solid rgba(6, 165, 208, 0.12);
  font-size: 14px;
  width: 100%;
  color: #06A5D0;
  background-color: rgba(6, 165, 208, 0.12);
}

.modal-footer-button {
  padding: 10px 30px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: center;
  border-radius: 5px;
  margin-left: .5rem;
  width: 108px;
  transition: .3s all;
  white-space: nowrap;
}

.modal-footer-button.cancel-button {
  background-color: transparent;
  border: 1px solid transparent;
  color: #777777E5;
}

.modal-footer-button.cancel-button:hover {
  background-color: #f4f4f4;
  border: 1px solid #f4f4f4;
  color: #777777E5;
}

.modal-footer-button.save-button {
  color: #fff;
}


@media screen and (max-width: 1000px) {
  .status-btn,
  .cancelled-btn,
  .default-status,
  .invoice-btn {
    font-size: 10px;
  }
}

.status-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}

.fa-circle-question {
  font-size: 120px;
}
.order-other-badge, .order-other-badge:hover{
  background-color: #FF9F431F;
  color: #FF9F43;
  border: 1px solid #FF9F431F;
  font-size: 14px;
  width: 100%;
}
</style>
