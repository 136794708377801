<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="size" height="33.958" viewBox="0 0 37.978 33.958">
    <g id="Group_427319914" data-name="Group 427319914" transform="translate(-0.828 0.646)">
      <path id="Path_9152" data-name="Path 9152" d="M16.387,1l.778,1.9" transform="translate(6.502 0)" fill="none" stroke="#efc518" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2.5"/>
      <path id="Path_9153" data-name="Path 9153" d="M22.33,1.042,21.3,2.824" transform="translate(8.736 0.019)" fill="none" stroke="#efc518" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2.5"/>
      <path id="Path_9154" data-name="Path 9154" d="M25.981,4.491,24,5.044" transform="translate(9.962 1.586)" fill="none" stroke="#efc518" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2.5"/>
      <path id="Path_9155" data-name="Path 9155" d="M27.023,8.826l-2.045-.22" transform="translate(10.406 3.456)" fill="none" stroke="#efc518" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2.5"/>
      <path id="Path_9156" data-name="Path 9156" d="M25.7,13.347,24,12.182" transform="translate(9.962 5.082)" fill="none" stroke="#efc518" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2.5"/>
      <path id="Path_9158" data-name="Path 9158" d="M24.683,10.091a5.049,5.049,0,0,1-1.92,3.971,7.122,7.122,0,0,0-4.625-2.531A10.543,10.543,0,0,0,16,6.513V6.5A5.032,5.032,0,0,1,19.592,5,5.1,5.1,0,0,1,24.683,10.091Z" transform="translate(6.327 1.818)" fill="none" stroke="#efc518" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2.5"/>
      <path id="Path_9159" data-name="Path 9159" d="M21.825,27.29a7.743,7.743,0,0,0,5.265-2.022c4.756-4.16,2.211-12.508-4.058-13.293C20.793-1.61,1.2,3.539,5.841,16.469" transform="translate(1.388 1.345)" fill="none" :stroke="color" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2.5"/>
      <path id="Path_9160" data-name="Path 9160" d="M9.643,13.152a5.3,5.3,0,0,0-2.487-.582c-6.778.48-6.763,10.341,0,10.821" transform="translate(0 5.259)" fill="none" :stroke="color" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2.5"/>
      <path id="Path_9161" data-name="Path 9161" d="M15.82,10.076a5.583,5.583,0,0,1,2.414-.6" transform="translate(6.245 3.853)" fill="none" :stroke="color" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2.5"/>
      <path id="Path_9162" data-name="Path 9162" d="M10.88,20,7.971,22.909" transform="translate(2.678 8.635)" fill="none" :stroke="color" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2.5"/>
      <path id="Path_9163" data-name="Path 9163" d="M14.88,20l-2.909,2.909" transform="translate(4.496 8.635)" fill="none" :stroke="color" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2.5"/>
      <path id="Path_9164" data-name="Path 9164" d="M14.88,16l-2.909,2.909" transform="translate(4.496 6.817)" fill="none" :stroke="color" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2.5"/>
      <path id="Path_9165" data-name="Path 9165" d="M10.88,16,7.971,18.909" transform="translate(2.678 6.817)" fill="none" :stroke="color" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2.5"/>
    </g>
  </svg>

</template>

<script>
export default {
  name: "AllSeason",
  props:{
    size: {
      type: Number,
      default: 25
    }
  },
  computed:{
    color(){
      return localStorage.getItem('color')
    }
  }
}
</script>

