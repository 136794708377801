<template>
  <div id="app">
    <navigation />
    <div style="width: 100%;">
      <div id="main-content">
        <Topbar />
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>

import Navigation from "@/components/Navigation.vue";
import Topbar from "@/components/Topbar.vue";
export default {
  name: "default",
  components: {
      Navigation,
    Topbar
  },
  computed: {
    expanded_menu() {
      return this.$store.state.menu.status
    }
  },
}
</script>

<style scoped>
#app{
  display: flex;
}
#main-content{
  padding: 25px;
  width: 100%
}
.w-260{
  width: calc(100% - 260px)
}
.w--100{
  width: calc(100% - 100px)
}
</style>
