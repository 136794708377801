import { render, staticRenderFns } from "./NotificationSidebar.vue?vue&type=template&id=21e691fc&scoped=true&"
import script from "./NotificationSidebar.vue?vue&type=script&lang=js&"
export * from "./NotificationSidebar.vue?vue&type=script&lang=js&"
import style0 from "./NotificationSidebar.vue?vue&type=style&index=0&id=21e691fc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21e691fc",
  null
  
)

export default component.exports