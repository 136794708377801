<template>
  <div>
    <Default>
      <div class="page-content">
        <ul id="breadcrumb-scroll">
          <li>
            <a href="/" itemprop="item"><span itemprop="name">{{ $t('Summary') }}</span></a>
          </li>
          <li>
          <span class="active">
               {{ $t('Discounts') }}
          </span>
          </li>
        </ul>


        <b-tabs content-class="mt-3" id="dealer-tab">
          <b-tab :title="$t('Payment Types')" :active="tab === 1" @click="search_text = ''; currentPage = 1; size= 10; sort_direction = 'desc'; sort_column='id'; getPaymentList()">

            <div class="table-content product-listing-table neu col-sm-12 col-lg-12">
              <div class="title-border">
                <div class="space-between">
                  <div class="table-title">{{ $t('Payment Types') }}</div>
                  <div class="table-action"/>
                </div>

                <div class="table-description">{{ $t('Easily manage and update payment methods for your users.') }}
                </div>
              </div>


              <div class="filters">
                <div class="filter-input">
                  <b-form-input :placeholder="$t('Search')" v-model="search_text" autocomplete="off"
                                class="px-0 bg-transparent" v-on:keyup.enter="getPaymentList()"/>
                  <i class="fa-solid fa-xmark cursor-pointer" v-if="search_text" @click="search_text=''; getPaymentList()"></i>
                  <i class="fa-solid fa-filter" v-else></i>
                </div>

                <span class="add-current" v-b-modal.add-payment-type-form>
              <i class="fa-solid fa-plus"></i>
              {{ $t('Add') }}
            </span>
              </div>

              <div class="table-responsive" v-if="!$store.state.payment.type_loader">
                <table id="productTable" class="w-100">
                  <thead>
                  <tr class="product-listing-table-head">
                    <th>
                      <div class="d-flex align-items-center cursor-pointer" @click="sort_column = 'id'; sort_direction = sort_direction === 'desc' ? 'asc' : 'desc'; getPaymentList()">
                        {{ $t('ID') }}
                        <span v-if="sort_column === 'id'">
                         <i class="fa-solid fa-chevron-up ml-2" v-if="sort_direction === 'asc'"></i>
                         <i class="fa-solid fa-chevron-down ml-2" v-else></i>
                      </span>
                        <span v-else>
                        <i class="fa-solid fa-chevron-down ml-2"></i>
                      </span>
                      </div>
                    </th>
                    <th class="text-center">
                      {{ $t('Default') }}
                    </th>
                    <th class="text-start">
                      {{ $t('Payment Type') }}
                    </th>
                    <!--                    <th class="text-start">-->
                    <!--                      {{ $t('Code') }}-->
                    <!--                    </th>-->
                    <th class="text-start">
                      {{ $t('Ref. ID') }}
                    </th>
                    <th class="text-start">
                      {{ $t('Ref. Code') }}
                    </th>
                    <th class="text-start">
                      {{ $t('Ref. No.') }}
                    </th>
                    <th class="text-start">
                      {{ $t('Description') }}
                    </th>
                    <th class="text-center">
                      {{ $t('Status') }}
                    </th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in payments" :key="item.$index">
                    <td>#{{ item.id }}</td>
                    <td class="text-center">
                      <span class="category-badge order-success-badge" v-if="item.is_default">{{ $t('Yes') }}</span>
                      <span class="category-badge order-cancelled-badge" v-else>{{ $t('No') }}</span>
                    </td>
                    <td class="text-start">{{ item.paymentType ? item.paymentType.name : '-' }}</td>
                    <td class="text-start">{{ item.ref_id ? '#' + item.ref_id : '-' }}</td>
                    <td class="text-start">{{ item.ref_code ?? '-' }}</td>
                    <td class="text-start">{{ item.ref_no ?? '-' }}</td>
                    <td class="text-start">{{ item.description ?? '-' }}</td>
                    <td class="text-center">
                      <span class="category-badge order-success-badge" v-if="item.is_active">{{ $t('Open') }}</span>
                      <span class="category-badge order-cancelled-badge" v-else>{{ $t('Close') }}</span>
                    </td>
                    <td>
                      <div class="d-flex justify-content-end">
                        <b-dropdown id="actions" class="m-md-2" no-caret>
                          <template #button-content>
                            {{ $t('Actions') }} <i class="fa-solid fa-chevron-down"></i>
                          </template>
                          <b-dropdown-item @click="payment = item; $refs['update-payment-type-form'].show()">{{ $t('Edit') }}</b-dropdown-item>
                          <b-dropdown-item @click="payment = item; deletePayment()">{{ $t('Delete') }}</b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <div v-else>
                <loader/>
              </div>


              <div class="d-flex justify-content-between pagination-count align-items-center">
                <span>
                  <span class="pagination-text">{{ $t('Show by per page:') }} &nbsp;</span>
                  <select name="pagination" id="pagination-select" @change="getPaymentList()" v-model="size">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </span>
                <span class="d-flex align-items-center" style="gap: 1rem;">
                   <span>
             <span class="fw-bolder">{{ $store.state.payment.type_pagination.total }}</span> ödeme tipinden
                  <span
                      class="fw-bolder">{{
                      $store.state.payment.type_pagination.current_page === 1 ? 1 : (($store.state.payment.type_pagination.current_page - 1) * $store.state.payment.type_pagination.per_page) + 1
                    }} - {{
                      $store.state.payment.type_pagination.total_page === $store.state.payment.type_pagination.current_page ? $store.state.payment.type_pagination.total : $store.state.payment.type_pagination.current_page * $store.state.payment.type_pagination.per_page
                    }}</span> gösteriliyor.</span>
                <b-pagination
                    :total-rows="$store.state.payment.type_pagination.total"
                    :per-page="size"
                    align="center"
                    size="sm"
                    class="my-1"
                    first-number
                    last-number
                    v-model="currentPage"
                    @input="getPaymentList()"
                />
              </span>
              </div>
            </div>
          </b-tab>

          <b-tab :title="$t('Banks')" :active="tab === 2" @click="search_text = ''; currentPage = 1; size= 10; sort_direction = 'desc'; sort_column='id'; getBanks()">

            <div class="table-content product-listing-table neu col-sm-12 col-lg-12">
              <div class="title-border">
                <div class="space-between">
                  <div class="table-title">{{ $t('Banks') }}</div>
                  <div class="table-action"/>
                </div>

                <div class="table-description">
                  {{ $t('Add new and manage existing bank accounts integrated into the dashboard.') }}
                </div>
              </div>


              <div class="filters">
                <div class="filter-input">
                  <b-form-input :placeholder="$t('Search')" v-model="search_text" autocomplete="off"
                                class="px-0 bg-transparent" v-on:keyup.enter="getBanks()"/>
                  <i class="fa-solid fa-xmark cursor-pointer" v-if="search_text" @click="search_text=''; getBanks()"></i>
                  <i class="fa-solid fa-filter" v-else></i>
                </div>

                <span class="add-current" v-b-modal.add-bank>
              <i class="fa-solid fa-plus"></i>
              {{ $t('Add') }}
            </span>
              </div>

              <div class="table-responsive" v-if="!$store.state.bank.loader">
                <table id="productTable" class="w-100">
                  <thead>
                  <tr class="product-listing-table-head">
                    <th>
                      <div class="d-flex align-items-center cursor-pointer" @click="sort_column = 'id'; sort_direction = sort_direction === 'desc' ? 'asc' : 'desc'; getBanks()">
                        {{ $t('ID') }}
                        <span v-if="sort_column === 'id'">
                         <i class="fa-solid fa-chevron-up ml-2" v-if="sort_direction === 'asc'"></i>
                         <i class="fa-solid fa-chevron-down ml-2" v-else></i>
                      </span>
                        <span v-else>
                        <i class="fa-solid fa-chevron-down ml-2"></i>
                      </span>
                      </div>
                    </th>
                    <th class="text-start">
                      {{ $t('Bank Name') }}
                    </th>
                    <th class="text-start">
                      {{ $t('Code') }}
                    </th>
                    <th class="text-start">
                      {{ $t('Payment Type') }}
                    </th>
                    <th class="text-center">
                      {{ $t('Status') }}
                    </th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in banks" :key="item.$index">
                    <td>#{{ item.id }}</td>
                    <td class="text-start">{{ item.name }}</td>
                    <td class="text-start">{{ item.bank_code ?? '-' }}</td>
                    <td class="text-start">{{ item.paymentType ? item.paymentType.name : '-' }}</td>
                    <td class="text-center">
                      <span class="category-badge order-success-badge" v-if="item.is_active">{{ $t('Open') }}</span>
                      <span class="category-badge order-cancelled-badge" v-else>{{ $t('Close') }}</span>
                    </td>
                    <td>
                      <div class="d-flex justify-content-end">
                        <b-dropdown id="actions" class="m-md-2" no-caret>
                          <template #button-content>
                            {{ $t('Actions') }} <i class="fa-solid fa-chevron-down"></i>
                          </template>
                          <b-dropdown-item @click="openUpdateBankModal(item)">{{ $t('Edit') }}</b-dropdown-item>
                          <b-dropdown-item @click="bank = item; deleteBank()">{{ $t('Delete') }}</b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <div v-else>
                <loader/>
              </div>


              <div class="d-flex justify-content-between pagination-count align-items-center">
                <span>
                  <span class="pagination-text">{{ $t('Show by per page:') }} &nbsp;</span>
                  <select name="pagination" id="pagination-select" @change="getBanks()" v-model="size">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </span>
                <span class="d-flex align-items-center" style="gap: 1rem;">
                <span>
             <span class="fw-bolder">{{ $store.state.bank.pagination.total }}</span> bankadan
                  <span
                      class="fw-bolder">{{
                      $store.state.bank.pagination.current_page === 1 ? 1 : (($store.state.bank.pagination.current_page - 1) * $store.state.bank.pagination.per_page) + 1
                    }} - {{
                      $store.state.bank.pagination.total_page === $store.state.bank.pagination.current_page ? $store.state.bank.pagination.total : $store.state.bank.pagination.current_page * $store.state.bank.pagination.per_page
                    }}</span> gösteriliyor.</span>
                <b-pagination
                    :total-rows="$store.state.payment.pagination.total"
                    :per-page="size"
                    align="center"
                    size="sm"
                    class="my-1"
                    first-number
                    last-number
                    v-model="currentPage"
                    @input="getBanks()"
                />
              </span>
              </div>
            </div>
          </b-tab>

          <b-tab :title="$t('Placiers')" :active="tab === 3" @click="search_text = ''; currentPage = 1; size= 10; sort_direction = 'desc'; sort_column='id'; getPlacierList()">

            <div class="table-content product-listing-table neu col-sm-12 col-lg-12">
              <div class="title-border">
                <div class="space-between">
                  <div class="table-title">{{ $t('Placiers') }}</div>
                  <div class="table-action"/>
                </div>

                <div class="table-description">
                  {{ $t('Manage your sales team effectively, add new members and follow up.') }}
                </div>
              </div>


              <div class="filters">
                <div class="filter-input">
                  <b-form-input :placeholder="$t('Search')" v-model="search_text" autocomplete="off"
                                class="px-0 bg-transparent" v-on:keyup.enter="getPlacierList()"/>
                  <i class="fa-solid fa-xmark cursor-pointer" v-if="search_text" @click="search_text=''; getPlacierList()"></i>
                  <i class="fa-solid fa-filter" v-else></i>
                </div>

                <span class="add-current" @click="placier={}; $refs['add-placier'].show()">
              <i class="fa-solid fa-plus"></i>
              {{ $t('Add') }}
            </span>
              </div>

              <div class="table-responsive" v-if="!$store.state.placier.loader">
                <table id="productTable" class="w-100">
                  <thead>
                  <tr class="product-listing-table-head">
                    <th>
                      <div class="d-flex align-items-center cursor-pointer" @click="sort_column = 'id'; sort_direction = sort_direction === 'desc' ? 'asc' : 'desc'; getPlacierList()">
                        {{ $t('ID') }}
                        <span v-if="sort_column === 'id'">
                         <i class="fa-solid fa-chevron-up ml-2" v-if="sort_direction === 'asc'"></i>
                         <i class="fa-solid fa-chevron-down ml-2" v-else></i>
                      </span>
                        <span v-else>
                        <i class="fa-solid fa-chevron-down ml-2"></i>
                      </span>
                      </div>
                    </th>
                    <th class="text-start">
                      {{ $t('Default') }}
                    </th>
                    <th class="text-start">
                      {{ $t('Name') }}
                    </th>
                    <th class="text-center">
                      {{ $t('Status') }}
                    </th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody v-if="placiers.length>0">
                  <tr v-for="item in placiers" :key="item.$index">
                    <td>#{{ item.id }}</td>
                    <td class="text-start">
                      <span class="category-badge order-success-badge" v-if="item.is_default">{{ $t('Yes') }}</span>
                      <span class="category-badge order-cancelled-badge" v-else>{{ $t('No') }}</span>
                    </td>
                    <td>{{item.name}}</td>
                    <td class="text-center">
                      <span class="category-badge order-success-badge" v-if="item.is_active">{{ $t('Open') }}</span>
                      <span class="category-badge order-cancelled-badge" v-else>{{ $t('Close') }}</span>
                    </td>
                    <td>
                      <div class="d-flex justify-content-end">
                        <b-dropdown id="actions" class="m-md-2" no-caret>
                          <template #button-content>
                            {{ $t('Actions') }} <i class="fa-solid fa-chevron-down"></i>
                          </template>
                          <b-dropdown-item @click="placier = item; $refs['update-placier'].show()">{{ $t('Edit') }}</b-dropdown-item>
                          <b-dropdown-item @click="placier = item; deletePlacier()">{{ $t('Delete') }}</b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>

                <div class="text-center my-3" v-if="placiers.length === 0">
                  {{ $t('No result.') }}
                </div>
              </div>

              <div v-else>
                <loader/>
              </div>


              <div class="d-flex justify-content-between pagination-count align-items-center">
                <span>
                  <span class="pagination-text">{{ $t('Show by per page:') }} &nbsp;</span>
                  <select name="pagination" id="pagination-select" @change="getPlacierList()" v-model="size">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </span>
                <span class="d-flex align-items-center" style="gap: 1rem;">
                          <span>
             <span class="fw-bolder">{{ $store.state.placier.pagination.total }}</span> plasiyerden
                  <span class="fw-bolder">{{
                      $store.state.placier.pagination.current_page === 1 ? 1 : (($store.state.placier.pagination.current_page - 1) * $store.state.placier.pagination.per_page) + 1
                    }} - {{
                      $store.state.placier.pagination.total_page === $store.state.placier.pagination.current_page ? $store.state.placier.pagination.total : $store.state.placier.pagination.current_page * $store.state.placier.pagination.per_page
                    }}</span> gösteriliyor.</span>
                <b-pagination
                    :total-rows="$store.state.placier.pagination.total"
                    :per-page="size"
                    align="center"
                    size="sm"
                    class="my-1"
                    first-number
                    last-number
                    v-model="currentPage"
                    @input="getPlacierList()"
                />
              </span>
              </div>
            </div>
          </b-tab>
        </b-tabs>

      </div>

      <b-modal id="add-payment-type-form" ref="add-payment-type-form" size="lg" no-close-on-backdrop
               hide-header hide-footer>
        <div>
          <div class="space-between">
            <div class="modal-header-title">{{ $t('Add Payment Type') }}</div>
            <img src="/images/icons/close.svg" alt="" class="cursor-pointer"
                 @click="$refs['add-payment-type-form'].hide();">
          </div>

          <ValidationObserver ref="addPaymentForm">
            <div class="row">
              <div class="col-sm-12 col-md-6 col-lg-6 select-label mt-3">
                <label for="payment_type">{{ $t('Integration Infos') }}</label>
                <pay-plans
                    @setInfo="ref_id = $event.ref_id; ref_code = $event.code; payment_description = $event.name "/>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-sm-12 col-md-6 col-lg-6 select-label">
                <label for="payment_type">{{ $t('Payment Type') }}*</label>
                <payment-type-select @setType="payment.payment_type_id = $event.id; payment_type_error = false"
                                     :error.sync="payment_type_error"/>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-4">
                <div class="form-white">
                  <ValidationProvider :name="$t('Reference ID')" rules="max:60" v-slot="{ errors }">
                    <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                      <b-form-input v-model="ref_id" class="nonrequired-input"
                                    :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                      <label for="name" class="">{{ $t('Reference ID') }}</label>
                    </div>
                    <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                    <span class="error-text opacity-0" v-else>*</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-2">
                <div class="form-white">
                  <ValidationProvider :name="$t('Reference Code')" rules="max:60" v-slot="{ errors }">
                    <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                      <b-form-input v-model="ref_code" class="nonrequired-input"
                                    :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                      <label for="name" class="">{{ $t('Reference Code') }}</label>
                    </div>
                    <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                    <span class="error-text opacity-0" v-else>*</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-2">
                <div class="form-white">
                  <ValidationProvider :name="$t('Reference No')" rules="max:60" v-slot="{ errors }">
                    <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                      <b-form-input v-model="payment.ref_no" class="nonrequired-input"
                                    :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                      <label for="name" class="">{{ $t('Reference No') }}</label>
                    </div>
                    <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                    <span class="error-text opacity-0" v-else>*</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
                <div class="form-white">
                  <ValidationProvider :name="$t('Description')" rules="max:255" v-slot="{ errors }">
                    <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                      <b-form-textarea v-model="payment_description" class="nonrequired-input"
                                       :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                      <label for="name" class="">{{ $t('Description') }}</label>
                    </div>
                    <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                    <span class="error-text opacity-0" v-else>*</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-2">
                <div class="form-white">
                    <div class="form-group input-material">
                      <b-form-input v-model="payment.payment_term" class="nonrequired-input" required autocomplete="off"/>
                      <label for="name" class="">{{ $t('Payment Term') }}</label>
                    </div>
                    <span class="error-text opacity-0">*</span>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-2">
                <div class="form-white">
                  <ValidationProvider :name="$t('Showing Title')" rules="max:255" v-slot="{ errors }">
                    <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                      <b-form-input v-model="payment.title" class="nonrequired-input"
                                       :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                      <label for="name" class="">{{ $t('Showing Title') }}</label>
                    </div>
                    <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                    <span class="error-text opacity-0" v-else>*</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-0">
                <div class="space-between">
                  <span class="check-text">{{ $t('Set as default payment type') }} <i class="fa-solid fa-circle-info dealer-text cursor-pointer" v-b-tooltip.hover :title="$t('When it comes to the order payment page, the first option is the payment system to be offered.')"></i> </span>

                  <label class="toggle">
                    <input type="checkbox" v-model="payment.is_default">
                    <span class="slider"></span>
                  </label>
                </div>

                <div class="space-between mt-2">
                  <span class="check-text">{{ $t('Show in payment page') }} <i class="fa-solid fa-circle-info dealer-text cursor-pointer" v-b-tooltip.hover :title="$t('You can turn this option on for payment methods that you want to offer as secondary payment methods other than the default payment method on the payment page. (Ex: You can turn on the credit card payment option as a 2nd option on the payment page when the current balance is your default payment method).')"></i> </span>

                  <label class="toggle">
                    <input type="checkbox" v-model="payment.is_show" disabled>
                    <span class="slider"></span>
                  </label>
                </div>

                <div class="space-between mt-2">
                  <span class="check-text">{{ $t('Installment Payment') }}</span>

                  <label class="toggle">
                    <input type="checkbox" v-model="payment.is_installment">
                    <span class="slider"></span>
                  </label>
                </div>

                <div class="form-white" v-if="payment.is_installment">
                  <div class="form-group input-material">
                    <b-form-input type="number" v-model="payment.max_installment_count" class="nonrequired-input" required autocomplete="off"/>
                      <label for="name" class="">{{ $t('Max. Installment Count') }}</label>
                    </div>
                  <span class="error-text opacity-0">*</span>
                </div>

                <div class="space-between mt-2">
                  <span class="check-text">{{ $t('Close the payment method') }} <i class="fa-solid fa-circle-info dealer-text cursor-pointer" v-b-tooltip.hover :title="$t('Payment management temporarily closes your b2b system until you turn it back on.')"></i> </span>

                  <label class="toggle">
                    <input type="checkbox" v-model="payment.is_active" @change="closePayment()">
                    <span class="slider"></span>
                  </label>
                </div>
              </div>


              <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                <div class="d-flex justify-content-end">
                  <button class="modal-footer-button save-button dealer-border dealer-bg" @click="addPayment()">
                    {{ $t('Add') }}
                  </button>
                </div>
              </div>
            </div>
          </ValidationObserver>
        </div>
      </b-modal>


      <b-modal id="update-payment-type-form" ref="update-payment-type-form" size="lg" no-close-on-backdrop
               hide-header hide-footer>
        <div>
          <div class="space-between">
            <div class="modal-header-title">{{ $t('Add Payment Type') }}</div>
            <img src="/images/icons/close.svg" alt="" class="cursor-pointer"
                 @click="$refs['update-payment-type-form'].hide();">
          </div>

          <ValidationObserver ref="updatePaymentForm">
            <div class="row mt-3">
              <div class="col-sm-12 col-md-6 col-lg-6 select-label">
                <label for="payment_type">{{ $t('Payment Type') }}*</label>
                <payment-type-select @setType="payment.payment_type_id = $event.id;"
                                     :id="payment.paymentType ? payment.paymentType.id : 0"/>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-4">
                <div class="form-white">
                  <ValidationProvider :name="$t('Reference ID')" rules="max:60" v-slot="{ errors }">
                    <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                      <b-form-input v-model="payment.ref_id" class="nonrequired-input"
                                    :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                      <label for="name" class="">{{ $t('Reference ID') }}</label>
                    </div>
                    <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                    <span class="error-text opacity-0" v-else>*</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-2">
                <div class="form-white">
                  <ValidationProvider :name="$t('Reference Code')" rules="max:60" v-slot="{ errors }">
                    <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                      <b-form-input v-model="payment.ref_code" class="nonrequired-input"
                                    :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                      <label for="name" class="">{{ $t('Reference Code') }}</label>
                    </div>
                    <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                    <span class="error-text opacity-0" v-else>*</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-2">
                <div class="form-white">
                  <ValidationProvider :name="$t('Reference No')" rules="max:60" v-slot="{ errors }">
                    <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                      <b-form-input v-model="payment.ref_no" class="nonrequired-input"
                                    :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                      <label for="name" class="">{{ $t('Reference No') }}</label>
                    </div>
                    <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                    <span class="error-text opacity-0" v-else>*</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
                <div class="form-white">
                  <ValidationProvider :name="$t('Description')" rules="max:255" v-slot="{ errors }">
                    <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                      <b-form-textarea v-model="payment.description" class="nonrequired-input"
                                       :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                      <label for="name" class="">{{ $t('Description') }}</label>
                    </div>
                    <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                    <span class="error-text opacity-0" v-else>*</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-2">
                <div class="form-white">
                  <div class="form-group input-material">
                    <b-form-input v-model="payment.payment_term" class="nonrequired-input" required autocomplete="off"/>
                    <label for="name" class="">{{ $t('Payment Term') }}</label>
                  </div>
                  <span class="error-text opacity-0">*</span>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-2">
                <div class="form-white">
                  <ValidationProvider :name="$t('Showing Title')" rules="max:255" v-slot="{ errors }">
                    <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                      <b-form-textarea v-model="payment.title" class="nonrequired-input"
                                       :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                      <label for="name" class="">{{ $t('Showing Title') }}</label>
                    </div>
                    <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                    <span class="error-text opacity-0" v-else>*</span>
                  </ValidationProvider>
                </div>
              </div>


              <div class="col-sm-12 col-md-6 col-lg-6 mt-0">
                <div class="space-between">
                  <span class="check-text">{{ $t('Set as default payment type') }} <i class="fa-solid fa-circle-info dealer-text cursor-pointer" v-b-tooltip.hover :title="$t('When it comes to the order payment page, the first option is the payment system to be offered.')"></i></span>

                  <label class="toggle">
                    <input type="checkbox" v-model="payment.is_default">
                    <span class="slider"></span>
                  </label>
                </div>


                <div class="space-between mt-2">
                  <span class="check-text">{{ $t('Show in payment page') }} <i class="fa-solid fa-circle-info dealer-text cursor-pointer" v-b-tooltip.hover :title="$t('You can turn this option on for payment methods that you want to offer as secondary payment methods other than the default payment method on the payment page. (Ex: You can turn on the credit card payment option as a 2nd option on the payment page when the current balance is your default payment method).')"></i></span>

                  <label class="toggle">
                    <input type="checkbox" v-model="payment.is_show">
                    <span class="slider"></span>
                  </label>
                </div>

                <div class="space-between mt-2">
                  <span class="check-text">{{ $t('Installment Payment') }}</span>


                  <label class="toggle">
                    <input type="checkbox" v-model="payment.is_installment">
                    <span class="slider"></span>
                  </label>
                </div>

                <div class="form-white" v-if="payment.is_installment">
                  <div class="form-group input-material">
                    <b-form-input type="number" v-model="payment.max_installment_count" class="nonrequired-input" required autocomplete="off"/>
                    <label for="name" class="">{{ $t('Max. Installment Count') }}</label>
                  </div>
                  <span class="error-text opacity-0">*</span>
                </div>

                <div class="space-between mt-2">
                  <span class="check-text">{{ $t('Close the payment method') }} <i class="fa-solid fa-circle-info dealer-text cursor-pointer" v-b-tooltip.hover :title="$t('Payment management temporarily closes your b2b system until you turn it back on.')"></i></span>

                  <label class="toggle">
                    <input type="checkbox" v-model="payment.is_active" @change="closePayment()">
                    <span class="slider"></span>
                  </label>
                </div>
              </div>


              <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                <div class="d-flex justify-content-end">
                  <button class="modal-footer-button save-button dealer-border dealer-bg" @click="updatePayment()">
                    {{ $t('Save') }}
                  </button>
                </div>
              </div>
            </div>
          </ValidationObserver>
        </div>
      </b-modal>

      <b-modal id="add-bank" ref="add-bank" size="lg" no-close-on-backdrop
               hide-header hide-footer>
        <div>
          <div class="space-between">
            <div class="modal-header-title">{{ $t('Add Bank') }}</div>
            <img src="/images/icons/close.svg" alt="" class="cursor-pointer"
                 @click="$refs['add-bank'].hide();">
          </div>

          <ValidationObserver ref="addBankForm">
            <div class="row">
              <div class="col-sm-12 col-md-6 col-lg-6 select-label mt-3">
                <label for="payment_type">{{ $t('Integration Infos') }}</label>
                <integration-info
                    @setInfo="ref_id = $event.ref_id; ref_code = $event.account_code; ref_no = $event.code "/>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-sm-12 col-md-6 col-lg-6 select-label">
                <label for="payment_type">{{ $t('Payment Type') }}*</label>
                <payment-type-select @setType="bank.payment_type_id = $event.id; payment_type_error = false"
                                     :error.sync="payment_type_error"/>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-4">
                <div class="form-white">
                  <ValidationProvider :name="$t('Bank Name')" rules="required|max:60" v-slot="{ errors }">
                    <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                      <b-form-input v-model="bank.name" class="nonrequired-input"
                                    :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                      <label for="name" class="">{{ $t('Bank Name') }} <span class="small"
                                                                             :class="errors.length>0 ? 'text-danger' : ''">*</span></label>
                    </div>
                    <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                    <span class="error-text opacity-0" v-else>*</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-2">
                <div class="form-white">
                  <ValidationProvider :name="$t('Bank Code')" rules="required|max:20" v-slot="{ errors }">
                    <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                      <b-form-input v-model="bank.bank_code" class="nonrequired-input"
                                    :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                      <label for="name" class="">{{ $t('Bank Code') }} <span class="small"
                                                                             :class="errors.length>0 ? 'text-danger' : ''">*</span></label>
                    </div>
                    <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                    <span class="error-text opacity-0" v-else>*</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-2">
                <div class="form-white">
                  <ValidationProvider :name="$t('Reference ID')" rules="max:60" v-slot="{ errors }">
                    <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                      <b-form-input v-model="ref_id" class="nonrequired-input"
                                    :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                      <label for="name" class="">{{ $t('Reference ID') }}</label>
                    </div>
                    <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                    <span class="error-text opacity-0" v-else>*</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-2">
                <div class="form-white">
                  <ValidationProvider :name="$t('Reference Code')" rules="max:60" v-slot="{ errors }">
                    <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                      <b-form-input v-model="ref_code" class="nonrequired-input"
                                    :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                      <label for="name" class="">{{ $t('Reference Code') }}</label>
                    </div>
                    <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                    <span class="error-text opacity-0" v-else>*</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-2">
                <div class="form-white">
                  <ValidationProvider :name="$t('Reference No')" rules="max:60" v-slot="{ errors }">
                    <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                      <b-form-input v-model="ref_no" class="nonrequired-input"
                                    :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                      <label for="name" class="">{{ $t('Reference No') }}</label>
                    </div>
                    <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                    <span class="error-text opacity-0" v-else>*</span>
                  </ValidationProvider>
                </div>
              </div>


              <div class="col-sm-12 col-md-6 col-lg-6 mt-0">
                <div class="space-between">
                  <span class="check-text" v-if="bank.is_active">{{ $t('Close the payment method') }}</span>
                  <span class="check-text" v-else>{{ $t('Open the payment method') }}</span>


                  <label class="toggle">
                    <input type="checkbox" v-model="bank.is_active">
                    <span class="slider"></span>
                  </label>
                </div>
              </div>


              <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                <div class="d-flex justify-content-end">
                  <button class="modal-footer-button save-button dealer-border dealer-bg" @click="addBank()">
                    {{ $t('Add') }}
                  </button>
                </div>
              </div>
            </div>
          </ValidationObserver>
        </div>
      </b-modal>

      <b-modal id="update-bank" ref="update-bank" size="lg" no-close-on-backdrop
               hide-header hide-footer>
        <div>
          <div class="space-between">
            <div class="modal-header-title">{{ $t('Edit Bank') }}</div>
            <img src="/images/icons/close.svg" alt="" class="cursor-pointer"
                 @click="$refs['update-bank'].hide();">
          </div>

          <ValidationObserver ref="updateBankForm">
            <div class="row mt-3">
              <div class="col-sm-12 col-md-6 col-lg-6 select-label">
                <label for="payment_type">{{ $t('Payment Type') }}*</label>
                <payment-type-select @setType="bank.payment_type_id = $event.id"
                                     :id="bank.paymentType ? bank.paymentType.id : 0"/>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-4">
                <div class="form-white">
                  <ValidationProvider :name="$t('Bank Name')" rules="required|max:60" v-slot="{ errors }">
                    <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                      <b-form-input v-model="bank.name" class="nonrequired-input"
                                    :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                      <label for="name" class="">{{ $t('Bank Name') }} <span class="small"
                                                                             :class="errors.length>0 ? 'text-danger' : ''">*</span></label>
                    </div>
                    <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                    <span class="error-text opacity-0" v-else>*</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-2">
                <div class="form-white">
                  <ValidationProvider :name="$t('Bank Code')" rules="required|max:20" v-slot="{ errors }">
                    <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                      <b-form-input v-model="bank.bank_code" class="nonrequired-input"
                                    :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                      <label for="name" class="">{{ $t('Bank Code') }} <span class="small"
                                                                             :class="errors.length>0 ? 'text-danger' : ''">*</span></label>
                    </div>
                    <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                    <span class="error-text opacity-0" v-else>*</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-2">
                <div class="form-white">
                  <ValidationProvider :name="$t('Reference ID')" rules="max:60" v-slot="{ errors }">
                    <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                      <b-form-input v-model="ref_id" class="nonrequired-input"
                                    :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                      <label for="name" class="">{{ $t('Reference ID') }}</label>
                    </div>
                    <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                    <span class="error-text opacity-0" v-else>*</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-2">
                <div class="form-white">
                  <ValidationProvider :name="$t('Reference Code')" rules="max:60" v-slot="{ errors }">
                    <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                      <b-form-input v-model="ref_code" class="nonrequired-input"
                                    :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                      <label for="name" class="">{{ $t('Reference Code') }}</label>
                    </div>
                    <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                    <span class="error-text opacity-0" v-else>*</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-2">
                <div class="form-white">
                  <ValidationProvider :name="$t('Reference No')" rules="max:60" v-slot="{ errors }">
                    <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                      <b-form-input v-model="ref_no" class="nonrequired-input"
                                    :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                      <label for="name" class="">{{ $t('Reference No') }}</label>
                    </div>
                    <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                    <span class="error-text opacity-0" v-else>*</span>
                  </ValidationProvider>
                </div>
              </div>


              <div class="col-sm-12 col-md-6 col-lg-6 mt-0">
                <div class="space-between">
                  <span class="check-text" v-if="bank.is_active">{{ $t('Close the payment method') }}</span>
                  <span class="check-text" v-else>{{ $t('Open the payment method') }}</span>


                  <label class="toggle">
                    <input type="checkbox" v-model="bank.is_active">
                    <span class="slider"></span>
                  </label>
                </div>
              </div>


              <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                <div class="d-flex justify-content-end">
                  <button class="modal-footer-button save-button dealer-border dealer-bg" @click="updateBank()">
                    {{ $t('Save') }}
                  </button>
                </div>
              </div>
            </div>
          </ValidationObserver>
        </div>
      </b-modal>

      <b-modal id="add-placier" ref="add-placier" size="lg" no-close-on-backdrop hide-header hide-footer>
        <div>
          <div class="space-between">
            <div class="modal-header-title">{{ $t('Add Placier') }}</div>
            <img src="/images/icons/close.svg" alt="" class="cursor-pointer"
                 @click="$refs['add-placier'].hide();">
          </div>

          <ValidationObserver ref="addPlacierForm">
            <div class="row">
              <div class="col-sm-12 col-md-6 col-lg-6 select-label mt-3">
                <label for="payment_type">{{ $t('Integration Infos') }}</label>
                <placier-setting
                    @setInfo="placier_ref_id = $event.ref_id; placier_ref_code = $event.code; "/>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-12 col-md-6 col-lg-6 mt-2">
                <div class="form-white">
                  <ValidationProvider :name="$t('Placier Name')" rules="required|max:60" v-slot="{ errors }">
                    <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                      <b-form-input v-model="placier.name" class="nonrequired-input"
                                    :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                      <label for="name" class="">{{ $t('Placier Name') }} <span class="small"
                                                                             :class="errors.length>0 ? 'text-danger' : ''">*</span></label>
                    </div>
                    <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                    <span class="error-text opacity-0" v-else>*</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-2">
                <div class="form-white">
                  <ValidationProvider :name="$t('Reference ID')" rules="max:60" v-slot="{ errors }">
                    <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                      <b-form-input v-model="placier_ref_id" class="nonrequired-input"
                                    :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                      <label for="name" class="">{{ $t('Reference ID') }}</label>
                    </div>
                    <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                    <span class="error-text opacity-0" v-else>*</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-2">
                <div class="form-white">
                  <ValidationProvider :name="$t('Reference Code')" rules="max:60" v-slot="{ errors }">
                    <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                      <b-form-input v-model="placier_ref_code" class="nonrequired-input"
                                    :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                      <label for="name" class="">{{ $t('Reference Code') }}</label>
                    </div>
                    <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                    <span class="error-text opacity-0" v-else>*</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-2">
                <div class="form-white">
                  <ValidationProvider :name="$t('Reference No')" rules="max:60" v-slot="{ errors }">
                    <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                      <b-form-input v-model="placier.ref_no" class="nonrequired-input"
                                    :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                      <label for="name" class="">{{ $t('Reference No') }}</label>
                    </div>
                    <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                    <span class="error-text opacity-0" v-else>*</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-2">
                <div class="form-white">
                  <ValidationProvider :name="$t('Phone')" rules="min:19|max:19" v-slot="{ errors }">
                    <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                      <i class="fa-solid fa-phone form-icon"></i>
                      <b-form-input v-model="placier.phone" class="nonrequired-input pl-32" v-mask="'+90 (###) ### ## ##'"
                                    :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                      <label for="name" class="pl-21">{{ $t('Phone') }}</label>
                    </div>
                    <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                    <span class="error-text opacity-0" v-else>*</span>
                  </ValidationProvider>
                </div>
              </div>


              <div class="col-sm-12 col-md-6 col-lg-6 mt-0">
                <div class="space-between">
                  <span class="check-text">{{ $t('Set as default placier') }}</span>

                  <label class="toggle">
                    <input type="checkbox" v-model="placier.is_default">
                    <span class="slider"></span>
                  </label>
                </div>
                <div class="space-between mt-2">
                  <span class="check-text" v-if="placier.is_active">{{ $t('Close the placier') }}</span>
                  <span class="check-text" v-else>{{ $t('Open the placier') }}</span>


                  <label class="toggle">
                    <input type="checkbox" v-model="placier.is_active" @change="closePlacier()">
                    <span class="slider"></span>
                  </label>
                </div>
              </div>


              <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                <div class="d-flex justify-content-end">
                  <button class="modal-footer-button save-button dealer-border dealer-bg" @click="addPlacier()">
                    {{ $t('Add') }}
                  </button>
                </div>
              </div>
            </div>
          </ValidationObserver>
        </div>
      </b-modal>


      <b-modal id="update-placier" ref="update-placier" size="lg" no-close-on-backdrop
               hide-header hide-footer>
        <div>
          <div class="space-between">
            <div class="modal-header-title">{{ $t('Edit Placier') }}</div>
            <img src="/images/icons/close.svg" alt="" class="cursor-pointer"
                 @click="$refs['update-placier'].hide();">
          </div>

          <ValidationObserver ref="updatePlacierForm">
            <div class="row mt-2">
              <div class="col-sm-12 col-md-6 col-lg-6 mt-2">
                <div class="form-white">
                  <ValidationProvider :name="$t('Placier Name')" rules="required|max:60" v-slot="{ errors }">
                    <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                      <b-form-input v-model="placier.name" class="nonrequired-input"
                                    :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                      <label for="name" class="">{{ $t('Placier Name') }} <span class="small"
                                                                                :class="errors.length>0 ? 'text-danger' : ''">*</span></label>
                    </div>
                    <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                    <span class="error-text opacity-0" v-else>*</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-2">
                <div class="form-white">
                  <ValidationProvider :name="$t('Reference ID')" rules="max:60" v-slot="{ errors }">
                    <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                      <b-form-input v-model="placier_ref_id" class="nonrequired-input"
                                    :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                      <label for="name" class="">{{ $t('Reference ID') }}</label>
                    </div>
                    <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                    <span class="error-text opacity-0" v-else>*</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-2">
                <div class="form-white">
                  <ValidationProvider :name="$t('Reference Code')" rules="max:60" v-slot="{ errors }">
                    <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                      <b-form-input v-model="placier_ref_code" class="nonrequired-input"
                                    :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                      <label for="name" class="">{{ $t('Reference Code') }}</label>
                    </div>
                    <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                    <span class="error-text opacity-0" v-else>*</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-2">
                <div class="form-white">
                  <ValidationProvider :name="$t('Reference No')" rules="max:60" v-slot="{ errors }">
                    <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                      <b-form-input v-model="placier.ref_no" class="nonrequired-input"
                                    :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                      <label for="name" class="">{{ $t('Reference No') }}</label>
                    </div>
                    <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                    <span class="error-text opacity-0" v-else>*</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-2">
                <div class="form-white">
                  <ValidationProvider :name="$t('Phone')" rules="min:19|max:19" v-slot="{ errors }">
                    <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                      <i class="fa-solid fa-phone form-icon"></i>
                      <b-form-input v-model="placier.phone" class="nonrequired-input pl-32" v-mask="'+9# (###) ### ## ##'"
                                    :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                      <label for="name" class="pl-21">{{ $t('Phone') }}</label>
                    </div>
                    <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                    <span class="error-text opacity-0" v-else>*</span>
                  </ValidationProvider>
                </div>
              </div>


              <div class="col-sm-12 col-md-6 col-lg-6 mt-0">
                <div class="space-between">
                  <span class="check-text">{{ $t('Set as default placier') }}</span>

                  <label class="toggle">
                    <input type="checkbox" v-model="placier.is_default">
                    <span class="slider"></span>
                  </label>
                </div>
                <div class="space-between mt-2">
                  <span class="check-text" v-if="placier.is_active">{{ $t('Close the placier') }}</span>
                  <span class="check-text" v-else>{{ $t('Open the placier') }}</span>


                  <label class="toggle">
                    <input type="checkbox" v-model="placier.is_active" @input="closePlacier()">
                    <span class="slider"></span>
                  </label>
                </div>
              </div>


              <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                <div class="d-flex justify-content-end">
                  <button class="modal-footer-button save-button dealer-border dealer-bg" @click="updatePlacier()">
                    {{ $t('Save') }}
                  </button>
                </div>
              </div>
            </div>
          </ValidationObserver>
        </div>
      </b-modal>

    </Default>
  </div>
</template>

<script>
import Default from "@/layouts/default.vue";
import AllPaymentType from "@/components/selects/discount/AllPaymentType.vue";
import Operator from "@/components/selects/discount/Operator.vue";
import axios from "@/plugins/axios";
import PaymentTypeSelect from "@/components/selects/settings/PaymentTypeSelect.vue";
import IntegrationInfo from "@/components/selects/settings/IntegrationInfo.vue";
import PayPlans from "@/components/selects/settings/PayPlans.vue";
import PlacierSetting from "@/components/selects/settings/PlacierSetting.vue";

export default {
  name: 'DiscountList',
  components: {
    IntegrationInfo,
    AllPaymentType,
    Default,
    Operator,
    PaymentTypeSelect,
    PayPlans,
    PlacierSetting
  },
  data() {
    return {
      tab: 1,
      currentPage: 1,
      size: 10,
      search_text: '',
      bank: {
        is_active: false
      },
      ref_id: null,
      ref_code: null,
      ref_no: null,
      payment_type_error: null,
      placier:{},
      payment: {
        is_show: true,
        is_active: false
      },
      payment_description: '',
      sort_direction: 'desc',
      sort_column: 'id',
      placier_ref_id: null,
      placier_ref_code: null
    }
  },
  mounted() {
    this.getPaymentList()
  },
  computed: {
    payments() {
      return this.$store.state.payment.types
    },
    banks() {
      return this.$store.state.bank.list
    },
    placiers(){
      return this.$store.state.placier.list
    },
    color() {
      return localStorage.getItem('color')
    }
  },
  methods: {
    closePayment(){
      if(!this.payment.is_active){
        this.payment.is_default = false
        this.payment.is_show = false
      }else{
        this.payment.is_show = true
      }
    },
    closePlacier(){
      if(!this.placier.is_active){
        this.placier.is_default = false
      }
    },
    getBanks() {
      this.$store.dispatch('bank/getBankList', {
        page: this.currentPage,
        size: this.size,
        sort_direction: this.sort_direction,
        sort_column: this.sort_column,
        search: this.search_text
      })
    },
    getPaymentList() {
      this.$store.dispatch('payment/getPaymentTypes', {
        page: this.currentPage,
        size: this.size,
        sort_direction: this.sort_direction,
        sort_column: this.sort_column,
        search: this.search_text
      })
    },
    getPlacierList(){
      this.$store.dispatch('placier/getPlaciers',{
        page: this.currentPage,
        size: this.size,
        sort_direction: this.sort_direction,
        sort_column: this.sort_column,
        search: this.search_text
      })
    },
    addBank() {
      this.$refs.addBankForm.validate().then(success => {
        if (success) {
          if (this.bank.payment_type_id) {
            axios.post('/v1/customers/bank-accounts', {
              customer_id: JSON.parse(localStorage.getItem('user')).id,
              payment_type_id: this.bank.payment_type_id,
              bank_code: this.bank.bank_code,
              name: this.bank.name,
              is_active: this.bank.is_active,
              ref_id: this.ref_id.toString(),
              ref_code: this.ref_code,
              ref_no: this.ref_no
            }).then(response => {
              if (response.data.status) {
                this.$refs["add-bank"].hide()
                this.ref_id = null
                this.ref_code = null
                this.ref_no = null
                this.currentPage = 1
                this.size = 10
                this.$swal({
                  iconHtml: '<img src="/images/toast/success.svg" width="32" height="32">',
                  toast: true,
                  position: 'top-end',
                  showConfirmButton: false,
                  timer: 3000,
                  title: this.$i18n.t('Success') + ' !',
                  text: this.$i18n.t('Bank successfully added.'),
                  customClass: {
                    icon: 'swal2-no-border'
                  }
                })
                this.getBanks()
              }
            }).catch(error => {
              this.$swal({
                iconHtml: '<img src="/images/toast/error.svg" width="32" height="32">',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                title: this.$i18n.t('Error') + ' !',
                text: error.response.data.statusMessage,
                customClass: {
                  icon: 'swal2-no-border'
                }
              })
            })
          } else {
            this.payment_type_error = true
          }
        } else {
          if (this.bank.payment_type_id) {

          } else {
            this.payment_type_error = true
          }
          this.$swal({
            iconHtml: '<img src="/images/toast/warning.png" width="32" height="32">',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            title: this.$i18n.t('Warning') + ' !',
            text: this.$i18n.t('Please check fields.'),
            customClass: {
              icon: 'swal2-no-border'
            }
          })
        }
      })
    },
    openUpdateBankModal(item) {
      this.bank = item;
      this.ref_id = item.ref_id;
      this.ref_no = item.ref_no;
      this.ref_code = item.ref_code;
      this.$refs["update-bank"].show();
    },
    updateBank() {
      this.$refs.updateBankForm.validate().then(success => {
        if (success) {
          axios.put(`/v1/customers/bank-accounts/${this.bank.id}`, {
            customer_id: JSON.parse(localStorage.getItem('user')).id,
            payment_type_id: this.bank.payment_type_id,
            bank_code: this.bank.bank_code,
            name: this.bank.name,
            is_active: this.bank.is_active,
            ref_id: this.ref_id.toString(),
            ref_code: this.ref_code,
            ref_no: this.ref_no
          }).then(response => {
            if (response.data.status) {
              this.$refs["update-bank"].hide()
              this.bank = {}
              this.ref_id = null
              this.ref_code = null
              this.ref_no = null
              this.currentPage = 1
              this.size = 10
              this.$swal({
                iconHtml: '<img src="/images/toast/success.svg" width="32" height="32">',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                title: this.$i18n.t('Success') + ' !',
                text: this.$i18n.t('Bank successfully updated.'),
                customClass: {
                  icon: 'swal2-no-border'
                }
              })
              this.getBanks()
            }
          }).catch(error => {
            this.$swal({
              iconHtml: '<img src="/images/toast/error.svg" width="32" height="32">',
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              title: this.$i18n.t('Error') + ' !',
              text: error.response.data.statusMessage,
              customClass: {
                icon: 'swal2-no-border'
              }
            })
          })
        } else {
          this.$swal({
            iconHtml: '<img src="/images/toast/warning.png" width="32" height="32">',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            title: this.$i18n.t('Warning') + ' !',
            text: this.$i18n.t('Please check fields.'),
            customClass: {
              icon: 'swal2-no-border'
            }
          })
        }
      })
    },
    deleteBank() {
      axios.delete(`/v1/customers/bank-accounts/${this.bank.id}`).then(response => {
        if (response.data.status) {
          this.currentPage = 1
          this.size = 10
          this.tab = 2
          this.$swal({
            iconHtml: '<img src="/images/toast/success.svg" width="32" height="32">',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            title: this.$i18n.t('Success') + ' !',
            text: this.$i18n.t('Bank successfully deleted.'),
            customClass: {
              icon: 'swal2-no-border'
            }
          })
          this.getBanks()
        }
      }).catch(error => {
        this.$swal({
          iconHtml: '<img src="/images/toast/error.svg" width="32" height="32">',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          title: this.$i18n.t('Error') + ' !',
          text: error.response.data.statusMessage,
          customClass: {
            icon: 'swal2-no-border'
          }
        })
      })
    },
    addPlacier(){
      this.$refs.addPlacierForm.validate().then(success=>{
        if(success){
          axios.post('/v1/customers/salesmen',{
            customer_id: JSON.parse(localStorage.getItem('user')).id,
            name: this.placier.name,
            ref_id: this.placier_ref_id ? this.placier_ref_id.toString() : null,
            ref_code: this.placier_ref_code,
            ref_no: this.placier.ref_no,
            is_default: this.placier.is_default,
            is_active: this.placier.is_active,
            phone: this.placier.phone
          }).then(response=>{
            if(response.data.status){
              this.currentPage = 1
              this.size = 10
              this.placier = {}
              this.placier_ref_id = null
              this.placier_ref_code = null
              this.$refs["add-placier"].hide()
              this.$swal({
                iconHtml: '<img src="/images/toast/success.svg" width="32" height="32">',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                title: this.$i18n.t('Success') + ' !',
                text: this.$i18n.t('Placier successfully added.'),
                customClass: {
                  icon: 'swal2-no-border'
                }
              })
              this.tab = 3
              this.getPlacierList()
            }
          }).catch(error => {
            this.$swal({
              iconHtml: '<img src="/images/toast/error.svg" width="32" height="32">',
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              title: this.$i18n.t('Error') + ' !',
              text: error.response.data.statusMessage,
              customClass: {
                icon: 'swal2-no-border'
              }
            })
          })
        }else{
          this.$swal({
            iconHtml: '<img src="/images/toast/warning.png" width="32" height="32">',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            title: this.$i18n.t('Warning') + ' !',
            text: this.$i18n.t('Please check fields.'),
            customClass: {
              icon: 'swal2-no-border'
            }
          })
        }
      })
    },
    updatePlacier(){
      this.$refs.updatePlacierForm.validate().then(success=>{
        if(success){
          axios.put(`/v1/customers/salesmen/${this.placier.id}`,{
            customer_id: JSON.parse(localStorage.getItem('user')).id,
            name: this.placier.name,
            ref_id: this.placier_ref_id,
            ref_code: this.placier_ref_code,
            ref_no: this.placier.ref_no,
            is_default: this.placier.is_default,
            is_active: this.placier.is_active,
            phone: this.placier.phone
          }).then(response=>{
            if(response.data.status){
              this.currentPage = 1
              this.size = 10
              this.placier = {}
              this.$refs["update-placier"].hide()
              this.$swal({
                iconHtml: '<img src="/images/toast/success.svg" width="32" height="32">',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                title: this.$i18n.t('Success') + ' !',
                text: this.$i18n.t('Placier successfully updated.'),
                customClass: {
                  icon: 'swal2-no-border'
                }
              })
              this.tab = 3
              this.getPlacierList()
            }
          }).catch(error => {
            this.$swal({
              iconHtml: '<img src="/images/toast/error.svg" width="32" height="32">',
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              title: this.$i18n.t('Error') + ' !',
              text: error.response.data.statusMessage,
              customClass: {
                icon: 'swal2-no-border'
              }
            })
          })
        }else{
          this.$swal({
            iconHtml: '<img src="/images/toast/warning.png" width="32" height="32">',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            title: this.$i18n.t('Warning') + ' !',
            text: this.$i18n.t('Please check fields.'),
            customClass: {
              icon: 'swal2-no-border'
            }
          })
        }
      })
    },
    deletePlacier(){
      axios.delete(`/v1/customers/salesmen/${this.placier.id}`).then(response => {
        if (response.data.status) {
          this.currentPage = 1
          this.size = 10
          this.tab = 3
          this.$swal({
            iconHtml: '<img src="/images/toast/success.svg" width="32" height="32">',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            title: this.$i18n.t('Success') + ' !',
            text: this.$i18n.t('Placier successfully deleted.'),
            customClass: {
              icon: 'swal2-no-border'
            }
          })
          this.getPlacierList()
        }
      }).catch(error => {
        this.$swal({
          iconHtml: '<img src="/images/toast/error.svg" width="32" height="32">',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          title: this.$i18n.t('Error') + ' !',
          text: error.response.data.statusMessage,
          customClass: {
            icon: 'swal2-no-border'
          }
        })
      })
    },
    addPayment(){
      this.$refs.addPaymentForm.validate().then(success=>{
        if(success){
            if(this.payment.payment_type_id){
              axios.post('/v1/customers/payment-types',{
                is_active: this.payment.is_active ,
                is_default: this.payment.is_default ,
                is_show: true ,
                description: this.payment_description ,
                payment_type_id: this.payment.payment_type_id ,
                ref_id: this.ref_id.toString() ,
                ref_no: this.payment.ref_no ,
                ref_code: this.ref_code ,
                payment_term: this.payment.payment_term,
                title: this.payment.title,
                is_installment: this.payment.is_installment,
                max_installment_count: this.payment.max_installment_count ? parseInt(this.payment.max_installment_count) : null
              }).then(response=>{
                if(response.data.status){
                  this.tab = 1
                  this.ref_id = ''
                  this.ref_code = ''
                  this.payment_description = ''
                  this.payment = {}
                  this.currentPage = 1
                  this.size = 10
                  this.$refs["add-payment-type-form"].hide()
                  this.$swal({
                    iconHtml: '<img src="/images/toast/success.svg" width="32" height="32">',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    title: this.$i18n.t('Success') + ' !',
                    text: this.$i18n.t('Payment type successfully added.'),
                    customClass: {
                      icon: 'swal2-no-border'
                    }
                  })
                  this.getPaymentList()
                }
              }).catch(error => {
                this.$swal({
                  iconHtml: '<img src="/images/toast/error.svg" width="32" height="32">',
                  toast: true,
                  position: 'top-end',
                  showConfirmButton: false,
                  timer: 3000,
                  title: this.$i18n.t('Error') + ' !',
                  text: error.response.data.statusMessage,
                  customClass: {
                    icon: 'swal2-no-border'
                  }
                })
              })
            }else{
              this.payment_type_error = true
            }
        }else{
          if(!this.payment.payment_type_id){
            this.payment_type_error = true
          }
          this.$swal({
            iconHtml: '<img src="/images/toast/warning.png" width="32" height="32">',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            title: this.$i18n.t('Warning') + ' !',
            text: this.$i18n.t('Please check fields.'),
            customClass: {
              icon: 'swal2-no-border'
            }
          })
        }
      })
    },
    updatePayment(){
      this.$refs.updatePaymentForm.validate().then(success=>{
        if(success){
          if(this.payment.payment_type_id){
            axios.put(`/v1/customers/payment-types/${this.payment.id}`,{
              is_active: this.payment.is_active ,
              is_default: this.payment.is_default ,
              is_show: this.payment.is_show ,
              description: this.payment.description ,
              payment_type_id: this.payment.payment_type_id ,
              ref_id: this.payment.ref_id ,
              ref_no: this.payment.ref_no ,
              ref_code: this.payment.ref_code,
              payment_term: this.payment.payment_term,
              title: this.payment.title,
              is_installment: this.payment.is_installment,
              max_installment_count: this.payment.max_installment_count ? parseInt(this.payment.max_installment_count) : null
            }).then(response=>{
              if(response.data.status){
                this.tab = 1
                this.currentPage = 1
                this.size = 10
                this.$refs["update-payment-type-form"].hide()
                this.$swal({
                  iconHtml: '<img src="/images/toast/success.svg" width="32" height="32">',
                  toast: true,
                  position: 'top-end',
                  showConfirmButton: false,
                  timer: 3000,
                  title: this.$i18n.t('Success') + ' !',
                  text: this.$i18n.t('Payment type successfully updated.'),
                  customClass: {
                    icon: 'swal2-no-border'
                  }
                })
                this.getPaymentList()
              }
            }).catch(error => {
              this.$swal({
                iconHtml: '<img src="/images/toast/error.svg" width="32" height="32">',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                title: this.$i18n.t('Error') + ' !',
                text: error.response.data.statusMessage,
                customClass: {
                  icon: 'swal2-no-border'
                }
              })
            })
          }else{
            this.payment_type_error = true
          }
        }else{
          if(!this.payment.payment_type_id){
            this.payment_type_error = true
          }
          this.$swal({
            iconHtml: '<img src="/images/toast/warning.png" width="32" height="32">',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            title: this.$i18n.t('Warning') + ' !',
            text: this.$i18n.t('Please check fields.'),
            customClass: {
              icon: 'swal2-no-border'
            }
          })
        }
      })
    },
    deletePayment(){
      axios.delete(`/v1/customers/payment-types/${this.payment.id}`).then(response => {
        if (response.data.status) {
          this.currentPage = 1
          this.size = 10
          this.tab = 1
          this.$swal({
            iconHtml: '<img src="/images/toast/success.svg" width="32" height="32">',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            title: this.$i18n.t('Success') + ' !',
            text: this.$i18n.t('Payment type successfully deleted.'),
            customClass: {
              icon: 'swal2-no-border'
            }
          })
          this.getPaymentList()
        }
      }).catch(error => {
        this.$swal({
          iconHtml: '<img src="/images/toast/error.svg" width="32" height="32">',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          title: this.$i18n.t('Error') + ' !',
          text: error.response.data.statusMessage,
          customClass: {
            icon: 'swal2-no-border'
          }
        })
      })
    },
  }
}

</script>


<style scoped>
.table-content {
  padding: 30px;
  margin-top: 30px;
}

.product-image img {
  width: 100%;
}

.search-area input, .search-area input:focus {
  background-color: transparent !important;
  min-width: 300px !important;
  font-size: 14px !important;
  border: none !important;
}

.search-area input::placeholder {
  color: v-bind('color');
}

.category-badge {
  padding: 5px 20px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
}

.order-success-badge {
  color: #28C76F;
  background-color: #28C76F1F;
}

.order-cancelled-badge {
  color: #EA5455;
  background-color: #EA54551F;
}

.table-action span {
  font-weight: 500;
}

.filters {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.filter-input input, .filter-input input:focus {
  color: v-bind('color');
  padding-left: 0 !important;
  font-size: 14px;
  border: none !important;
}

.add-current {
  background-color: v-bind('color');
  color: #f4f4f4;
  display: flex;
  text-align: center;
  align-items: center;
  border-radius: 5px;
  font-size: 14px;
  padding: 11px 22px;
  cursor: pointer;
  gap: .5rem;
}

.save-button {
  padding: 10px 30px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: center;
  border-radius: 5px;
  margin-left: .5rem;
  transition: .3s all;
  color: #fff;
}

.checkbox-text {
  font-size: 14px;
  cursor: pointer;
  margin-left: .5rem;
}

.see-detail-btn, .see-detail-btn:hover {
  border: 1px solid v-bind('color');
  color: v-bind('color');
  font-family: Montserrat;
  font-size: 12px;
  padding: 10px 14px;
}

.title-border {
  padding-bottom: 16px;
  border-bottom: 1px solid #00000033;
}

.table-action {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  color: #777;
}

.table-title {
  font-family: Inter;
  font-size: 17px;
  font-weight: 600;
  line-height: 16px;
  color: #1C1C1C;
}

.table-description {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  margin-top: 8px;
  text-align: left;
  color: #323338;
}

.filter-input {
  display: flex;
  border: 1px solid v-bind('color');
  align-items: center;
  border-radius: 5px;
  padding: 4px 16px;
  color: v-bind('color');
  min-width: 300px;
}

.filter-input input, .filter-input input:focus {
  color: v-bind('color');
  padding-left: 0 !important;
  font-size: 14px;
  border: none !important;
}

.filter-input input::placeholder {
  color: v-bind('color');
}

.modal-footer-button {
  padding: 10px 30px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: center;
  border-radius: 5px;
  margin-left: .5rem;
  width: 108px;
  transition: .3s all;
  white-space: nowrap;
}

.modal-footer-button.cancel-button {
  background-color: transparent;
  border: 1px solid transparent;
  color: #777777E5;
}

.modal-footer-button.cancel-button:hover {
  background-color: #f4f4f4;
  border: 1px solid #f4f4f4;
  color: #777777E5;
}
</style>
