import Vue from "vue";
import Vuex from 'vuex';

Vue.use(Vuex);

import axiosInstance from "@/plugins/axios";

const state = {
    list:[],
    pagination:{},
    loader: true
};

const mutations = {
    setLoader(state,data){
      state.loader = data
    },
    setList(state, data) {
        state.list = data.items
        state.pagination = data.pagination
    }
};
const actions = {
    getDealers({commit}, data) {
        commit('setLoader',true)
        var params = {
            page: data.page,
            size: data.size,
            filter:{}
        }
        if(data.search){
            params.filter.searchText = data.search
        }
        if(data.approve === 1 || data.approve === 0 ){
            params.filter.is_approved = data.approve
        }
        if(data.is_active === 1 || data.is_active === 0 ){
            params.filter.is_active = data.is_active
        }
        axiosInstance.get('/v1/customers/dealers',{
            params
        }).then(response=>{
            if(response.data.status){
                commit("setList",response.data.responseData.dealer)
                commit('setLoader',false)
            }
        })
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
