import Vue from 'vue'
import VueRouter from 'vue-router'

import HomePage from "@/views/HomePage.vue";
import Login from "@/views/Login.vue";
import ForgetPassword from "@/views/ForgetPassword.vue";
import SendPasswordSuccess from "@/views/success/sendpassword.vue";
import CreatePassword from "@/views/CreatePassword.vue";
import CurrentList from "@/views/currents/CurrentList.vue";
import OrderList from "@/views/orders/OrderList.vue";
import OrderDetail from "@/views/orders/OrderDetail.vue";
import CurrentDetail from "@/views/currents/CurrentDetail.vue";
import PaymentList from "@/views/payments/PaymentList.vue";
import PaymentDetail from "@/views/payments/PaymentDetail.vue";
import DiscountList from "@/views/discounts/DiscountList.vue";
import Settings from "@/views/settings/Settings.vue";
import ProductList from "@/views/products/ProductList.vue";
import DownloadedFiles from "@/views/files/DownloadedFiles.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'HomePage',
        component: HomePage
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/forget-password',
        name: 'ForgetPassword',
        component: ForgetPassword
    },
    {
        path: '/send-password',
        name: 'SendPasswordSuccess',
        component: SendPasswordSuccess
    },
    {
        path: '/new-password/:token',
        name: 'CreatePassword',
        component: CreatePassword
    },
    {
        path: '/currents',
        name: 'CurrentList',
        component: CurrentList
    },
    {
        path: '/currents/:id',
        name: 'CurrentDetail',
        component: CurrentDetail
    },
    {
        path: '/orders',
        name: 'OrderList',
        component: OrderList
    },
    {
        path: '/orders/:id',
        name: 'OrderDetail',
        component: OrderDetail
    },
    {
        path: '/payments',
        name: 'PaymentList',
        component: PaymentList
    },
    {
        path: '/payments/:id',
        name: 'PaymentDetail',
        component: PaymentDetail
    },
    {
        path: '/discounts',
        name: 'DiscountList',
        component: DiscountList
    },
    {
        path: '/settings',
        name: 'Settings',
        component: Settings
    },
    {
        path: '/products',
        name: 'ProductList',
        component: ProductList
    },
    {
        path: '/downloaded-files',
        name: 'DownloadedFiles',
        component: DownloadedFiles
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next)=>{
    next()
})



export default router
