<template>
  <div class="dot" :style="`background-color: ${bg_color}`"></div>
</template>

<script>
export default {
  name: "StockDot",
  props:{
    bg_color: {
      type: String,
      default: '#1c1c1c'
    }
  }
}
</script>

<style scoped>

.dot{
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-right: 5px;
}

</style>
