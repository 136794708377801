export default {
    currenctFormat(locale, price, currency = 'TRY') {
        return new Intl.NumberFormat(locale, { style: 'currency', currency: currency }).format(price)
    },
    dateFormat(string){
        if(string){
            const [year, month, dayTime] = string.split('-');
            const [day] = dayTime.split('T');
            const formattedDate = `${day}/${month}/${year}`;
            return formattedDate;
        }
    },
    dateTimeFormat(string){
        if(string){
            const [year, month, dayTime] = string.split('-');
            const [day, time] = dayTime.split('T');
            const timeWithoutMilliseconds = time.split('.')[0];
            const formattedDate = `${day}/${month}/${year} ${timeWithoutMilliseconds}`;
            return formattedDate.substring(0,formattedDate.length-3);
        }
    }
};
