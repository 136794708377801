<template>
  <div id="left-sidebar" :class="{ 'w-100': expanded_menu === true, 'max-width-100': !expanded_menu }">
    <div id="left-sidebar-wrapper" :class="{ 'max-width-100': expanded_menu === false }">
<!--      <i class="fa-regular fa-circle-dot" id="toggle-ico" @click="menuToggle"></i>-->


     <img @click="$route.path !== '/' ? $router.push('/') : ''"  alt="Servislet B2B Management" class="logo cursor-pointer" src="/logo.png" v-if="expanded_menu === true"><!--  @click="openCloseMenu(false)"-->

      <img  @click="$route.path !== '/' ? $router.push('/') : ''" alt="Servislet B2B Management" class="logo cursor-pointer" src="/close-navigation-icon.png" v-if="expanded_menu === false"><!--  @click="openCloseMenu(true)"-->

<!--      <ul id="navigation-list" style="max-height: 70vh; overflow-y: scroll;">-->
      <ul id="navigation-list">
        <router-link to="/" :class="{ 'router-link-exact-active': currentPath === '/' }">
          <li :class="{ justifyContentCenter: expanded_menu === false }">
            <div class="navigation-list-flex">
              <img src="/images/icons/summary.svg" alt="" class="menu-ico" v-if="currentPath === '/'">
              <img src="/images/icons/summary-dark.svg" alt="" class="menu-ico" v-else>
              <span class="menu-txt" v-if="expanded_menu === true">{{ $t('Summary') }}</span>
            </div>
          </li>
        </router-link>

        <router-link to="/currents" :class="{ 'router-link-exact-active': currentPath === '/currents'}">
          <li :class="{ justifyContentCenter: expanded_menu === false }">
            <span class="menu-txt" v-if="expanded_menu === true">{{ $t('Currents') }}</span>
          </li>
        </router-link>

        <router-link to="/orders" :class="{ 'router-link-exact-active': currentPath === '/orders'}">
          <li :class="{ justifyContentCenter: expanded_menu === false }">
            <span class="menu-txt" v-if="expanded_menu === true">{{ $t('Orders') }}</span>
          </li>
        </router-link>

        <router-link to="/payments" :class="{ 'router-link-exact-active': currentPath === '/payments'}">
          <li :class="{ justifyContentCenter: expanded_menu === false }">
            <span class="menu-txt" v-if="expanded_menu === true">{{ $t('Payments') }}</span>
          </li>
        </router-link>

        <router-link to="#" style="pointer-events: none" :class="{ 'router-link-exact-active': currentPath === '/reports'}" class="disabled">
          <li :class="{ justifyContentCenter: expanded_menu === false }">
            <span class="menu-txt" v-if="expanded_menu === true">{{ $t('Reports') }}</span>
          </li>
        </router-link>

        <router-link to="#" style="pointer-events: none" :class="{ 'router-link-exact-active': currentPath === '/invoices'}" class="disabled">
          <li :class="{ justifyContentCenter: expanded_menu === false }">
            <span class="menu-txt" v-if="expanded_menu === true">{{ $t('Invoices') }}</span>
          </li>
        </router-link>


        <router-link to="/products" :class="{ 'router-link-exact-active': currentPath === '/products'}">
          <li :class="{ justifyContentCenter: expanded_menu === false }">
            <span class="menu-txt" v-if="expanded_menu === true">{{ $t('Products') }}</span>
          </li>
        </router-link>

        <router-link to="#" style="pointer-events: none" :class="{ 'router-link-exact-active': currentPath === '/cargo'}" class="disabled">
          <li :class="{ justifyContentCenter: expanded_menu === false }">
            <span class="menu-txt" v-if="expanded_menu === true">{{ $t('Cargoes') }}</span>
          </li>
        </router-link>

        <router-link to="#" style="pointer-events: none" :class="{ 'router-link-exact-active': currentPath === '/campaigns'}" class="disabled">
          <li :class="{ justifyContentCenter: expanded_menu === false }">
            <span class="menu-txt" v-if="expanded_menu === true">{{ $t('Campaigns') }}</span>
          </li>
        </router-link>

        <router-link to="/discounts" :class="{ 'router-link-exact-active': currentPath === '/discounts'}">
          <li :class="{ justifyContentCenter: expanded_menu === false }">
            <span class="menu-txt" v-if="expanded_menu === true">{{ $t('Discounts') }}</span>
          </li>
        </router-link>

        <router-link to="/downloaded-files" :class="{ 'router-link-exact-active': currentPath === '/downloaded-files'}">
          <li :class="{ justifyContentCenter: expanded_menu === false }">
            <span class="menu-txt" v-if="expanded_menu === true">{{ $t('Downloaded Files') }}</span>
          </li>
        </router-link>
      </ul>

      <div class="menu-footer">
        <ul id="navigation-list">
          <router-link to="#" style="pointer-events: none" :class="{ 'router-link-exact-active': currentPath === '/help'}">
            <li :class="{ justifyContentCenter: expanded_menu === false }">
              <i class="fa-regular fa-circle-question menu-ico"></i>
              <span class="menu-txt" v-if="expanded_menu === true">{{ $t('Help & Support') }}</span>
            </li>
          </router-link>

          <router-link to="/settings":class="{ 'router-link-exact-active': currentPath === '/settings'}">
            <li :class="{ justifyContentCenter: expanded_menu === false }">
              <span class="menu-ico">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.94891 2.38619C8.34336 0.760267 10.6563 0.760267 11.0508 2.38619C11.1099 2.63045 11.226 2.85728 11.3894 3.04823C11.5528 3.23917 11.759 3.38884 11.9912 3.48503C12.2234 3.58123 12.475 3.62124 12.7256 3.60181C12.9761 3.58238 13.2186 3.50407 13.4332 3.37323C14.8619 2.50286 16.498 4.13804 15.6276 5.56767C15.497 5.78217 15.4188 6.02447 15.3994 6.27487C15.38 6.52528 15.42 6.77672 15.5161 7.00877C15.6122 7.24081 15.7616 7.44691 15.9524 7.61031C16.1431 7.77372 16.3697 7.88981 16.6137 7.94916C18.2397 8.3436 18.2397 10.6566 16.6137 11.051C16.3695 11.1102 16.1426 11.2262 15.9517 11.3896C15.7607 11.553 15.6111 11.7592 15.5149 11.9914C15.4187 12.2236 15.3787 12.4752 15.3981 12.7258C15.4175 12.9764 15.4959 13.2188 15.6267 13.4334C16.4971 14.8621 14.8619 16.4982 13.4322 15.6279C13.2177 15.4972 12.9755 15.419 12.725 15.3996C12.4746 15.3802 12.2232 15.4202 11.9912 15.5163C11.7591 15.6124 11.553 15.7619 11.3896 15.9526C11.2262 16.1433 11.1101 16.3699 11.0508 16.614C10.6563 18.2399 8.34336 18.2399 7.94891 16.614C7.88973 16.3697 7.77371 16.1429 7.61029 15.9519C7.44687 15.761 7.24068 15.6113 7.00849 15.5151C6.7763 15.4189 6.52468 15.3789 6.27411 15.3984C6.02354 15.4178 5.78109 15.4961 5.5665 15.6269C4.1378 16.4973 2.50169 14.8621 3.37206 13.4325C3.50271 13.218 3.5809 12.9757 3.60029 12.7253C3.61967 12.4749 3.57969 12.2234 3.48361 11.9914C3.38752 11.7593 3.23804 11.5532 3.04731 11.3898C2.85658 11.2264 2.62999 11.1104 2.38595 11.051C0.760022 10.6566 0.760022 8.3436 2.38595 7.94916C2.63021 7.88997 2.85704 7.77395 3.04798 7.61053C3.23893 7.44712 3.38859 7.24092 3.48479 7.00874C3.58098 6.77655 3.621 6.52493 3.60157 6.27435C3.58214 6.02378 3.50382 5.78133 3.37299 5.56675C2.50261 4.13804 4.1378 2.50193 5.56743 3.3723C6.49336 3.93527 7.69336 3.43712 7.94891 2.38619Z" :stroke="currentPath === '/settings' ? '#f9f9f9' : '#1c1c1c'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.49995 12.278C11.0341 12.278 12.2777 11.0343 12.2777 9.50019C12.2777 7.96607 11.0341 6.72241 9.49995 6.72241C7.96582 6.72241 6.72217 7.96607 6.72217 9.50019C6.72217 11.0343 7.96582 12.278 9.49995 12.278Z" :stroke="currentPath === '/settings' ? '#f9f9f9' : '#1c1c1c'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </span>
              <span class="menu-txt" v-if="expanded_menu === true">{{ $t('Settings') }}</span>
            </li>
          </router-link>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  data() {
    return {
      disabled: true,
      currentPath: this.$route.path,
      expanded_menu: this.$store.state.menu.status
    }
  },
  watch: {
    expanded_menu() {
      return this.$store.state.menu.status
    }
  },
  computed:{
    color(){
      return localStorage.getItem('color')
    }
  },
  methods: {
    openCloseMenu(state) {
      this.$store.commit('menu/setStatus',state)
      // this.$store.dispatch('sidemenu/dispatchMenu', !this.expanded_menu)
      // localStorage.setItem('expanded_menu', this.expanded_menu)
    }
  }
}
</script>

<style scoped>
#left-sidebar {
  max-width: 260px;
  min-width: 260px;
  background: var(--background-color-sidebar);
  min-height: 90vh;
  position: sticky;
  z-index: 999;
}

#left-sidebar-wrapper {
  width: 100%;
  max-width: 260px;
  background: var(--background-color-sidebar);
  min-height: 100vh !important;
  height: 100%;
  padding: 30px 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  transition: .5s;
  position: fixed;
  overflow-y: auto;
}

.menu-ico {
  padding-left: .5rem !important;
}

.max-width-100 {
  max-width: 100px !important;
  width: 100% !important;
  min-width: 100px !important;
}

.max-width-100 .menu-ico {
  padding-left: 0 !important;
}
.logo {
  width: 100%;
}

#navigation-list {
  list-style: none;
  line-height: 24px;
  margin: 24px 0 8px 0;
  padding: 0;
}
#navigation-list li {
  transition: .5s;
  border-radius: 10px;
  padding: .5rem .5rem;
  margin: 12px 0px;
  display: flex;
  align-items: center;
  color: #1C1C1C;
  justify-content: flex-start;
}
#navigation-list li:hover {
  background: rgba(0, 0, 0, 0.075);
  transition: .5s;
}
#navigation-list li span {
  padding-left: 1rem;
}
#navigation-list a {
  color: inherit;
  text-decoration: none;
}
.router-link-exact-active li {
  background: v-bind('color') !important;
  color: var(--servislet-white) !important;
}
.navigation-list-flex {
  display: flex;
  align-items: center;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.menu-footer{
  position: absolute;
  bottom: 0;
}
</style>
