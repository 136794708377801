import Vue from "vue";
import Vuex from 'vuex';
import axiosInstance from "@/plugins/axios";

Vue.use(Vuex);

const state = {
    list: [],
    pagination: {},
    loader: true
};

const mutations = {
    setLoader(state, data) {
        state.loader = data
    },
    setList(state, data) {
        state.list = data.items
        state.pagination = data.pagination
    }
};
const actions = {
    getFiles({commit}, data) {
        commit('setLoader', true)
        var params = {
            page: data.page,
            size: data.size,
            start_date: data.start_date,
            end_date: data.end_date,
            filter: {}
        }
        if(data.search){
            params.filter.searchText = data.search
        }
        axiosInstance.get('/v1/customers/files', {
            params
        }).then(response => {
            if (response.data.status) {
                commit("setList", response.data.responseData.customerFile)
                commit('setLoader', false)
            }
        })
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
