<template>
  <div>
    <Default>
      <div class="page-content">
        <ul id="breadcrumb-scroll">
          <li>
            <a href="/" itemprop="item"><span itemprop="name">{{ $t('Summary') }}</span></a>
          </li>
          <li>
          <span class="active">
               {{ $t('Discounts') }}
          </span>
          </li>
        </ul>

        <div class="table-content product-listing-table neu col-sm-12 col-lg-12" v-if="!$store.state.discount.loader">

          <div class="filters">
            <div class="filter-input"></div>

            <span class="add-current" v-b-modal.add-discount-form>
              <i class="fa-solid fa-plus"></i>
              {{ $t('Add Discount') }}
            </span>
          </div>

          <div class="table-responsive">
            <table id="productTable" class="w-100">
              <thead>
              <tr class="product-listing-table-head">
                <th>
                  ID
                </th>
                <th class="text-center">
                  {{ $t('Discount') }}
                </th>
                <th class="text-center">
                  {{ $t('Column Name') }}
                </th>
                <th class="text-center">
                  {{ $t('Discount Rate') }}
                </th>
                <th class="text-center">
                  {{ $t('Discount Amount') }}
                </th>
                <th class="text-center">
                  {{ $t('Installment Count') }}
                </th>
                <th class="text-center">
                  {{ $t('Installment Opt.') }}
                </th>
                <th class="text-center">
                  {{ $t('Show Campaign') }}
                </th>
                <th class="text-center">
                  {{ $t('Status') }}
                </th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in discounts" :key="item.$index">
                <td>#{{ item.id }}</td>
                <td class="text-center">{{ item.title ?? '-' }}</td>
                <td class="text-center">{{ item.column_title }}</td>
                <td class="text-center">{{ item.discount_rate ? '% ' + item.discount_rate : '-' }}</td>
                <td class="text-center">{{ currenctFormat('tr-TR', item.discount_amount ?? 0) }}</td>
                <td class="text-center">{{ item.installment_count ?? '-' }}</td>
                <td class="text-center">{{ item.installment_operator ?? '-' }}</td>
                <td class="text-center">
                  <span class="category-badge order-success-badge" v-if="item.is_show_campaign">{{ $t('Yes') }}</span>
                  <span class="category-badge order-cancelled-badge" v-else>{{ $t('No') }}</span>
                </td>
                <td class="text-center">
                  <span class="category-badge order-success-badge" v-if="item.is_active">{{ $t('Open') }}</span>
                  <span class="category-badge order-cancelled-badge" v-else>{{ $t('Close') }}</span>
                </td>
                <td>
                  <div class="d-flex justify-content-end">
<!--                    <button class="btn see-detail-btn opacitied-bg" @click="update = item; $refs['update-discount-form'].show()">-->
                    <button class="btn see-detail-btn opacitied-bg" @click="setUpdate(item)">
                      <i class="fa-solid fa-eye"></i>
                    </button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="d-flex justify-content-between pagination-count align-items-center">
                <span>
                  <span class="pagination-text">{{ $t('Show by per page:') }} &nbsp;</span>
                  <select name="pagination" id="pagination-select" @change="getDiscountList()" v-model="size">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </span>
            <span class="d-flex align-items-center" style="gap: 1rem;">
                   <span>
             <span class="fw-bolder">{{ $store.state.discount.pagination.total }}</span> indirimden
                  <span
                      class="fw-bolder">{{
                      $store.state.discount.pagination.current_page === 1 ? 1 : (($store.state.discount.pagination.current_page - 1) * $store.state.discount.pagination.per_page) + 1
                    }} - {{
                      $store.state.discount.pagination.total_page === $store.state.discount.pagination.current_page ? $store.state.discount.pagination.total : $store.state.discount.pagination.current_page * $store.state.discount.pagination.per_page
                    }}</span> gösteriliyor.</span>
                <b-pagination
                    :total-rows="$store.state.discount.pagination.total"
                    :per-page="size"
                    align="center"
                    size="sm"
                    class="my-1"
                    first-number
                    last-number
                    v-model="currentPage"
                    @input="getDiscountList()"
                />
              </span>
          </div>
        </div>


        <div class="table-content product-listing-table neu col-sm-12 col-lg-12" v-else>
          <loader/>
        </div>
      </div>

      <b-modal id="add-discount-form" ref="add-discount-form" size="lg" no-close-on-backdrop
               hide-header hide-footer>
        <div>
          <div class="space-between">
            <div class="modal-header-title">{{ $t('Add Discount') }}</div>
            <img src="/images/icons/close.svg" alt="" class="cursor-pointer"
                 @click="$refs['add-discount-form'].hide();">
          </div>

          <ValidationObserver ref="addForm">
            <div class="row">
              <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                <div class="form-white">
                  <ValidationProvider :name="$t('Label')" rules="required" v-slot="{ errors }">
                    <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                      <b-form-input v-model="add.title" class="nonrequired-input"
                                    :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                      <label for="name" class="">{{ $t('Label') }} <span class="small"
                                                                         :class="errors.length>0 ? 'text-danger' : ''">*</span></label>
                    </div>
                    <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                    <span class="error-text opacity-0" v-else>*</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                <div class="form-white">
                  <div class="form-group input-material">
                    <b-form-input v-model="add.column_title" class="nonrequired-input" required autocomplete="off"/>
                    <label for="name" class="">{{ $t('Column Name') }}</label>
                  </div>
                  <span class="error-text opacity-0">*</span>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-3">

                <div class="form-white">
                    <div class="form-group input-material">
                      <i class="fa-solid fa-percent form-icon"></i>
                      <b-form-input v-model="add.discount_rate" class="nonrequired-input pl-32"
                                    required autocomplete="off"/>
                      <label for="email" class="pl-21">{{ $t('Discount Rate') }}</label>
                    </div>
                    <span class="error-text opacity-0">*</span>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                <div class="form-white">
                  <div class="form-group input-material">
                    <money v-model="discount_amount" class="form-control nonrequired-input" required
                           autocomplete="off"/>
                    <label for="tax_number" class="">{{ $t('Discount Amount') }}</label>
                  </div>
                  <span class="error-text opacity-0">*</span>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 select-label">
                <label for="payment_type">{{ $t('Payment Type') }}</label>
                <all-payment-type @setType="add.payment_type_id = $event.paymentType.id"/>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-4">
                <div class="form-white">
                  <div class="form-group input-material">
                    <b-form-input v-model="add.installment_count" class="form-control nonrequired-input" type="number"
                                  required autocomplete="off"/>
                    <label for="tax_number" class="">{{ $t('Installment Count') }}</label>
                  </div>
                  <span class="error-text opacity-0">*</span>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 select-label">
                <label for="payment_type">{{ $t('Installment Count Opt.') }}</label>
                <operator @setOperator="add.installment_operator = $event"/>
              </div>

              <div class="col-sm-12 col-md-3 col-lg-3 mt-3 d-flex align-items-center">
                <b-form-checkbox v-model="add.is_active"/>
                <span class="checkbox-text"
                      @click="add.is_active = !add.is_active">{{ $t('Active Status') }}</span>
              </div>

              <div class="col-sm-12 col-md-3 col-lg-3 mt-3 d-flex align-items-center">
                <b-form-checkbox v-model="add.is_default"/>
                <span class="checkbox-text"
                      @click="add.is_default = !add.is_default">{{ $t('Default') }}</span>
              </div>

              <div class="col-sm-12 col-md-12 col-lg-12 mt-3 d-flex align-items-center">
                <b-form-checkbox v-model="add.is_show_campaign"/>
                <span class="checkbox-text"
                      @click="add.is_show_campaign = !add.is_show_campaign">{{ $t('Is show as campaign?') }}</span>
              </div>

              <div v-if="add.is_show_campaign" class="col-sm-12 col-md-3 col-lg-3 mt-4 select-label">
                <label for="payment_type">{{ $t('Image') }}:</label>

                <input type="file" ref="file" accept="image/*" style="display: none" @change="imageAsset">
                <div class="cover-image mt-3">
                  <div class="edit-icon">
                    <a @click="removeAssetImage"><i class="fa-solid fa-xmark"></i></a>
                  </div>
                  <a @click="$refs.file.click()" class="cursor-pointer">
                    <b-img-lazy fluid :src.sync="add.image" style="width:100%" class="shadow"/>
                  </a>
                </div>
              </div>
              <div v-if="add.is_show_campaign" class="col-sm-12 col-md-9 col-lg-9 d-flex align-items-end">
                <div class="form-white w-100">
                  <div class="form-group input-material">
                    <b-form-textarea rows="7" v-model="add.description" class="form-control nonrequired-input" required
                                     autocomplete="off"/>
                    <label for="tax_number" class="">{{ $t('Description') }}</label>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                <div class="d-flex justify-content-end">
                  <button class="modal-footer-button save-button dealer-border dealer-bg" @click="addDiscount()">
                    {{ $t('Add') }}
                  </button>
                </div>
              </div>
            </div>
          </ValidationObserver>
        </div>
      </b-modal>

      <b-modal id="update-discount-form" ref="update-discount-form" size="lg" no-close-on-backdrop
               hide-header hide-footer>
        <div>
          <div class="space-between">
            <div class="modal-header-title">{{ $t('Add Discount') }}</div>
            <img src="/images/icons/close.svg" alt="" class="cursor-pointer" @click="$refs['update-discount-form'].hide();">
          </div>

          <ValidationObserver ref="updateForm">
            <div class="row">
              <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                <div class="form-white">
                  <ValidationProvider :name="$t('Label')" rules="required" v-slot="{ errors }">
                    <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                      <b-form-input v-model="update.title" class="nonrequired-input"
                                    :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                      <label for="name" class="">{{ $t('Label') }} <span class="small"
                                                                         :class="errors.length>0 ? 'text-danger' : ''">*</span></label>
                    </div>
                    <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                    <span class="error-text opacity-0" v-else>*</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                <div class="form-white">
                  <div class="form-group input-material">
                    <b-form-input v-model="update.column_title" class="nonrequired-input" required autocomplete="off"/>
                    <label for="name" class="">{{ $t('Column Name') }}</label>
                  </div>
                  <span class="error-text opacity-0">*</span>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                <div class="form-white">
                  <div class="form-group input-material">
                    <i class="fa-solid fa-percent form-icon"></i>
                    <b-form-input v-model="update.discount_rate" class="nonrequired-input pl-32" required
                                  autocomplete="off"/>
                    <label for="tax_number" class="pl-21">{{ $t('Discount Rate') }}</label>
                  </div>
                  <span class="error-text opacity-0">*</span>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                <div class="form-white">
                  <div class="form-group input-material">
                    <money v-model="update.discount_amount" class="form-control nonrequired-input" required
                           autocomplete="off"/>
                    <label for="tax_number" class="">{{ $t('Discount Amount') }}</label>
                  </div>
                  <span class="error-text opacity-0">*</span>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 select-label">
                <label for="payment_type">{{ $t('Payment Type') }}</label>
                <all-payment-type @setType="update.payment_type_id = $event.paymentType.id" :id="update.paymentType ? update.paymentType.id: 0 "/>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-4">
                <div class="form-white">
                  <div class="form-group input-material">
                    <b-form-input v-model="update.installment_count" class="form-control nonrequired-input" type="number"
                                  required autocomplete="off"/>
                    <label for="tax_number" class="">{{ $t('Installment Count') }}</label>
                  </div>
                  <span class="error-text opacity-0">*</span>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 select-label">
                <label for="payment_type">{{ $t('Installment Count Opt.') }}</label>
                <operator @setOperator="update.installment_operator = $event" :operator="update.installment_operator"/>
              </div>

              <div class="col-sm-12 col-md-3 col-lg-3 mt-3 d-flex align-items-center">
                <b-form-checkbox v-model="update.is_active"/>
                <span class="checkbox-text"
                      @click="update.is_active = !update.is_active">{{ $t('Active Status') }}</span>
              </div>

              <div class="col-sm-12 col-md-3 col-lg-3 mt-3 d-flex align-items-center">
                <b-form-checkbox v-model="update.is_default"/>
                <span class="checkbox-text"
                      @click="update.is_default = !update.is_default">{{ $t('Default') }}</span>
              </div>

              <div class="col-sm-12 col-md-12 col-lg-12 mt-3 d-flex align-items-center">
                <b-form-checkbox v-model="update.is_show_campaign"/>
                <span class="checkbox-text"
                      @click="update.is_show_campaign = !update.is_show_campaign">{{ $t('Is show as campaign?') }}</span>
              </div>

              <div v-if="update.is_show_campaign" class="col-sm-12 col-md-3 col-lg-3 mt-4 select-label">
                <label for="payment_type">{{ $t('Image') }}:</label>

                <input type="file" ref="file2" accept="image/*" style="display: none" @change="imageAssetUpdate">
                <div class="cover-image mt-3">
                  <div class="edit-icon">
                    <a @click="removeAssetImageUpdate"><i class="fa-solid fa-xmark"></i></a>
                  </div>
                  <a @click="$refs.file2.click()" class="cursor-pointer">
                    <b-img-lazy fluid :src.sync="update.asset_url" style="width:100%" class="shadow"/>
                  </a>
                </div>
              </div>
              <div v-if="update.is_show_campaign" class="col-sm-12 col-md-9 col-lg-9 d-flex align-items-end">
                <div class="form-white w-100">
                  <div class="form-group input-material">
                    <b-form-textarea rows="7" v-model="update.description" class="form-control nonrequired-input" required
                                     autocomplete="off"/>
                    <label for="tax_number" class="">{{ $t('Description') }}</label>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                <div class="d-flex justify-content-end">
                  <button class="modal-footer-button save-button dealer-border dealer-bg" @click="updateDiscount()">
                    {{ $t('Update') }}
                  </button>
                </div>
              </div>
            </div>
          </ValidationObserver>
        </div>
      </b-modal>

    </Default>
  </div>
</template>

<script>
import Default from "@/layouts/default.vue";
import AllPaymentType from "@/components/selects/discount/AllPaymentType.vue";
import Operator from "@/components/selects/discount/Operator.vue";
import axios from "@/plugins/axios";

export default {
  name: 'DiscountList',
  components: {
    AllPaymentType,
    Default,
    Operator
  },
  data() {
    return {
      currentPage: 1,
      size: 10,
      search_text: '',
      discount_amount: 0,
      add: {
        is_show_campaign: false,
        is_active: false,
        is_default: false,
        image: `${window.location.origin}/images/no_image.png`
      },
      selectedFile: null,
      update:{},
      selectedFile2: null
    }
  },
  mounted() {
    this.getDiscountList()
  },
  computed: {
    discounts() {
      return this.$store.state.discount.list
    },
    color() {
      return localStorage.getItem('color')
    }
  },
  methods: {
    getDiscountList() {
      this.$store.dispatch('discount/getDiscounts', {
        page: this.currentPage,
        size: this.size
      })
    },
    imageAsset(e) {
      this.selectedFile = e.target.files[0]
      this.add.image = URL.createObjectURL(this.selectedFile)
    },
    removeAssetImage() {
      this.$refs.file.value = ""
      this.selectedFile = null
      this.add.image = `${window.location.origin}/images/no_image.png`
    },
    addDiscount() {
      this.$refs.addForm.validate().then(success => {
        if (success) {
          const fd = new FormData()
          fd.append('title', this.add.title)
          fd.append('is_default', this.add.is_default === true ? 1 : 0)
          fd.append('is_active', this.add.is_active === true ? 1 : 0)
          fd.append('is_show_campaign', this.add.is_show_campaign === true ? 1 : 0)
          if (this.add.installment_operator) {
            fd.append('installment_operator', this.add.installment_operator)
          }

          if (this.add.installment_count) {
            fd.append('installment_count', this.add.installment_count)
          }
          if (this.discount_amount) {
            fd.append('discount_amount', this.discount_amount)
          }

          if (this.add.discount_rate) {
            fd.append('discount_rate', this.add.discount_rate)
          }
          if (this.selectedFile) {
            fd.append('asset_path', this.selectedFile)
          }
          if (this.add.description) {
            fd.append('description', this.add.description)
          }
          if (this.add.column_title) {
            fd.append('column_title', this.add.column_title)
          }
          if(this.add.payment_type_id){
            fd.append('payment_type_id', this.add.payment_type_id)
          }
          axios.post('/v1/customers/discounts', fd).then(response => {
            if (response.data.status) {
              this.$swal({
                iconHtml: '<img src="/images/toast/success.svg" width="32" height="32">',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                title: this.$i18n.t('Success') + ' !',
                text: this.$i18n.t('Discount successfully added.'),
                customClass: {
                  icon: 'swal2-no-border'
                }
              })
              this.$refs["add-discount-form"].hide();
              this.getDiscountList()
              this.discount_amount = 0
              this.add = {
                is_show_campaign: false,
                is_active: false,
                is_default: false,
                image: `${window.location.origin}/images/no_image.png`
              }
              this.selectedFile = null
            }
          }).catch(error => {
            this.$swal({
              iconHtml: '<img src="/images/toast/error.svg" width="32" height="32">',
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              title: this.$i18n.t('Error') + ' !',
              text: error.response.data.statusMessage,
              customClass: {
                icon: 'swal2-no-border'
              }
            })
          })
        } else {
          this.$swal({
            iconHtml: '<img src="/images/toast/warning.png" width="32" height="32">',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            title: this.$i18n.t('Warning') + ' !',
            text: this.$i18n.t('Please check fields.'),
            customClass: {
              icon: 'swal2-no-border'
            }
          })
        }
      })
    },
    setUpdate(item){
      this.update = item;
      if(!item.asset_url){
        this.update.asset_url = `${window.location.origin}/images/no_image.png`
      }
      this.$refs['update-discount-form'].show()
    },
    imageAssetUpdate(e){
      this.selectedFile2 = e.target.files[0]
      this.update.asset_url = URL.createObjectURL(this.selectedFile2)
    },
    removeAssetImageUpdate(){
      this.$refs.file2.value = ""
      this.selectedFile2 = null
      this.update.asset_url = `${window.location.origin}/images/no_image.png`
    },
    updateDiscount(){
      this.$refs.updateForm.validate().then(success => {
        if (success) {
          const fd = new FormData()
          fd.append('title', this.update.title)
          fd.append('_method', 'put')
          fd.append('is_default', this.update.is_default === true ? 1 : 0)
          fd.append('is_active', this.update.is_active === true ? 1 : 0)
          fd.append('is_show_campaign', this.update.is_show_campaign === true ? 1 : 0)
          if (this.update.installment_operator) {
            fd.append('installment_operator', this.update.installment_operator)
          }else{
            fd.append('installment_operator', '')
          }

          if (this.update.installment_count) {
            fd.append('installment_count', this.update.installment_count)
          }else{
            fd.append('installment_count', '')
          }
          if (this.update.discount_amount) {
            fd.append('discount_amount', this.update.discount_amount)
          }else{
            fd.append('discount_amount', 0)
          }

          if (this.update.discount_rate) {
            fd.append('discount_rate', this.update.discount_rate)
          }else{
            fd.append('discount_rate','')
          }
          if (this.selectedFile2) {
            fd.append('asset_path', this.selectedFile2)
          }else{
            if(this.update.asset_url === `${window.location.origin}/images/no_image.png` ){

            }
          }
          if (this.update.description) {
            fd.append('description', this.update.description)
          }
          if (this.update.column_title) {
            fd.append('column_title', this.update.column_title)
          }
          if(this.update.payment_type_id){
            fd.append('payment_type_id', this.update.payment_type_id)
          }
          axios.post(`/v1/customers/discounts/${this.update.id}`, fd).then(response => {
            if (response.data.status) {
              this.$swal({
                iconHtml: '<img src="/images/toast/success.svg" width="32" height="32">',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                title: this.$i18n.t('Success') + ' !',
                text: this.$i18n.t('Discount successfully updated.'),
                customClass: {
                  icon: 'swal2-no-border'
                }
              })
              this.$refs["update-discount-form"].hide();
              this.getDiscountList()
              this.selectedFile2 = null
            }
          }).catch(error => {
            this.$swal({
              iconHtml: '<img src="/images/toast/error.svg" width="32" height="32">',
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              title: this.$i18n.t('Error') + ' !',
              text: error.response.data.statusMessage,
              customClass: {
                icon: 'swal2-no-border'
              }
            })
          })
        } else {
          this.$swal({
            iconHtml: '<img src="/images/toast/warning.png" width="32" height="32">',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            title: this.$i18n.t('Warning') + ' !',
            text: this.$i18n.t('Please check fields.'),
            customClass: {
              icon: 'swal2-no-border'
            }
          })
        }
      })
    }
  }
}

</script>


<style scoped>
.table-content {
  padding: 30px;
  margin-top: 30px;
}

.product-image img {
  width: 100%;
}

.search-area input, .search-area input:focus {
  background-color: transparent !important;
  min-width: 300px !important;
  font-size: 14px !important;
  border: none !important;
}

.search-area input::placeholder {
  color: v-bind('color');
}

.category-badge {
  padding: 5px 20px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 10px;
}

.order-success-badge {
  color: #28C76F;
  background-color: #28C76F1F;
}

.order-cancelled-badge {
  color: #EA5455;
  background-color: #EA54551F;
}

.table-action span {
  font-weight: 500;
}

.filter-input input, .filter-input input:focus {
  color: v-bind('color');
  padding-left: 0 !important;
  font-size: 14px;
  border: none !important;
}

.filters {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.add-current {
  background-color: v-bind('color');
  color: #f4f4f4;
  display: flex;
  text-align: center;
  align-items: center;
  border-radius: 5px;
  font-size: 14px;
  padding: 10px;
  cursor: pointer;
  gap: .5rem;
}

.save-button {
  padding: 10px 30px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: center;
  border-radius: 5px;
  margin-left: .5rem;
  transition: .3s all;
  color: #fff;
}

.checkbox-text {
  font-size: 14px;
  cursor: pointer;
  margin-left: .5rem;
}
.see-detail-btn, .see-detail-btn:hover {
  border: 1px solid v-bind('color');
  color: v-bind('color');
  font-family: Montserrat;
  font-size: 12px;
  padding: 10px 14px;
}
</style>
