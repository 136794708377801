import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = {
    email: ''
};

const mutations = {
    setEmail(state, data) {
        state.email = data
    },
};

export default {
    namespaced: true,
    state,
    mutations
};
