<template>
  <div>
    <Default>
      <div class="page-content">

        <div class="row" v-if="!widget_loading">
          <div class="col-sm-12 col-md-6 col-lg-2-half">
            <div class="summary-item summary-item-height">
              <div class="space-between">
                <div class="title">Toplam Satış</div>
                <i class="fa-solid fa-bag-shopping"></i>
              </div>
              <div class="sub-data d-flex align-items-center">{{allOrders.total_order_count}}
              <span class="sub-data-rate" :class="allOrders.total_order_count_rate > 0 ? 'success' : 'danger'">%{{allOrders.total_order_count_rate}} <i class="fa-solid fa-arrow-up"></i> </span>
              </div>
              <div class="description"><span class="fw-bold">4</span> Farklı kategoriden <span class="fw-bold">{{allOrders.total_order_product_count}}</span> ürün satışı</div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-2-half">
            <div class="summary-item summary-item-height">
              <div class="space-between">
                <div class="title">Satışlar Toplamı</div>
                <i class="fa-solid fa-credit-card" style="padding: 9px"></i>
              </div>
              <div class="sub-data">{{currenctFormat('tr-TR', allOrders.order_total)}}</div>
              <div class="description">Son 1 ayda <span class="fw-bold">%{{allOrders.order_total_rate}}</span> artış</div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-2-half">
            <div class="summary-item summary-item-height">
              <div class="space-between">
                <div class="title">Ürün Satışları Toplamı</div>
                <i class="fa-solid fa-bag-shopping"></i>
              </div>
              <div class="sub-data">{{currenctFormat('tr-TR', allOrders.order_total)}}</div>
              <div class="description">Toplam <span class="fw-bold">{{allOrders.total_product_count}}</span> ürün</div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-2-half">
            <div class="summary-item summary-item-height">
              <div class="space-between">
                <div class="title">Ek Hizmet Toplamı</div>
                <i class="fa-solid fa-screwdriver-wrench"></i>
              </div>
              <div class="sub-data">{{currenctFormat('tr-TR', 0)}}</div>
              <div class="description">Toplam <span class="fw-bold">0</span> ek hizmet</div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-2-half">
            <div class="summary-item summary-item-height">
              <div class="space-between">
                <div class="title">İptaller</div>
               <div class="svg-area">
                 <svg width="13" height="17" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                   <path fill-rule="evenodd" clip-rule="evenodd" d="M13 11.2308V14.9117C13 15.5453 12.4865 16.0588 11.853 16.0588H1.1473C0.513733 16.0588 0.000244141 15.5453 0.000244141 14.9117V9.13574L13 11.2308Z" fill="black"/>
                   <path fill-rule="evenodd" clip-rule="evenodd" d="M3.05878 4.58824H1.14706C0.513489 4.58824 0 5.10173 0 5.7353V13.0001H12.9998V5.7353C12.9998 5.10173 12.4863 4.58824 11.8527 4.58824H9.941V3.44118C9.941 1.54047 8.40054 0 6.49983 0C4.59912 0 3.05865 1.54047 3.05865 3.44118L3.05878 4.58824ZM9.17654 3.44118C9.17654 1.96304 7.97816 0.764662 6.50002 0.764662C5.02189 0.764662 3.82351 1.96304 3.82351 3.44118V6.49996C3.82351 6.71106 3.9948 6.88236 4.20591 6.88236C4.41701 6.88236 4.5883 6.71106 4.5883 6.49996V4.58824H8.41161V6.49996C8.41161 6.71106 8.5829 6.88236 8.79401 6.88236C9.00511 6.88236 9.17641 6.71106 9.17641 6.49996L9.17654 3.44118Z" fill="black"/>
                   <rect x="4.49829" y="8.34082" width="6.55977" height="0.93711" rx="0.468555" transform="rotate(43.2102 4.49829 8.34082)" fill="#D9D9D9"/>
                   <rect x="9.15479" y="8.85815" width="6.55977" height="0.93711" rx="0.468555" transform="rotate(133.21 9.15479 8.85815)" fill="#D9D9D9"/>
                 </svg>
               </div>
              </div>
              <div class="sub-data d-flex align-items-center">{{cancelledOrders.canceled_order_count}}
                <span class="sub-data-rate" :class="cancelledOrders.canceled_order_rate > 0 ? 'success' : 'danger'">%{{cancelledOrders.canceled_order_rate}} <i class="fa-solid fa-arrow-up"></i> </span>
              </div>
              <div class="description">İptal edilen satış top. <span class="fw-bold">{{currenctFormat('tr-TR',cancelledOrders.total)}}</span></div>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-2-half mt-3">
            <div class="summary-item summary-item-height">
              <div class="space-between">
                <div class="title">Günlük Ort. Siparişler</div>
                <div class="svg-area">
                  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.50143 13.7357H8.49817C9.0734 13.7357 9.54171 13.2676 9.54171 12.6925V5.06575C9.54171 4.49053 9.07364 4.02222 8.49817 4.02222H7.50143C6.9262 4.02222 6.45825 4.49029 6.45825 5.06575V12.6922C6.45825 13.2675 6.92632 13.7357 7.50143 13.7357Z" fill="black"/>
                    <path d="M12.1765 13.757H13.1732C13.7484 13.757 14.2168 13.2889 14.2168 12.7138V1.04354C14.2168 0.46807 13.7484 0 13.1732 0H12.1765C11.6013 0 11.1333 0.46807 11.1333 1.04354V12.7138C11.1333 13.2889 11.6014 13.757 12.1765 13.757Z" fill="black"/>
                    <path d="M2.82662 13.7358H3.82337C4.39859 13.7358 4.86691 13.2677 4.86691 12.6926V8.77279C4.86691 8.19756 4.39883 7.72925 3.82337 7.72925H2.82662C2.2514 7.72925 1.78345 8.19732 1.78345 8.77279V12.6926C1.78345 13.2678 2.25152 13.7358 2.82662 13.7358Z" fill="black"/>
                    <path d="M15.8923 14.9729L15.2251 14.3057C15.0767 14.1608 14.8375 14.1608 14.6928 14.3057C14.6221 14.3798 14.5817 14.4743 14.5817 14.572C14.5817 14.6731 14.6221 14.7674 14.6928 14.8383L14.7165 14.8653L8.84265 14.8655H1.28401L1.30769 14.8384C1.3784 14.7677 1.41885 14.6732 1.41885 14.5721C1.41885 14.4744 1.37839 14.3802 1.30769 14.3058C1.16277 14.1609 0.923459 14.1609 0.775389 14.3058L0.108223 14.973C0.0369347 15.0436 0 15.1413 0 15.2389C0 15.34 0.0370519 15.4344 0.111152 15.5052L0.775137 16.1724C0.845957 16.2433 0.943626 16.2837 1.0413 16.2837C1.14237 16.2837 1.23675 16.2433 1.30757 16.1726C1.37827 16.1019 1.41872 16.0074 1.41872 15.9063C1.41872 15.8053 1.37827 15.7109 1.30757 15.64L1.28389 15.6164H14.7158L14.6921 15.64C14.6214 15.7107 14.5809 15.8053 14.5809 15.9063C14.5809 16.0074 14.6214 16.1018 14.6921 16.1726C14.7631 16.2433 14.8574 16.2837 14.9586 16.2837C15.0563 16.2837 15.154 16.2433 15.2249 16.1726L15.8888 15.5054C15.963 15.4347 16 15.3402 16 15.2392C15.9999 15.1413 15.9629 15.0436 15.892 14.9729L15.8923 14.9729Z" fill="black"/>
                  </svg>
                </div>
              </div>
              <div class="sub-data">{{allOrders.daily_order_count_rate}}
              </div>
              <div class="description">Ortalama sipariş toplamı <span class="fw-bold">{{currenctFormat('tr-TR',allOrders.order_total_average)}}</span></div>
            </div>
          </div>
        </div>

        <div class="row" v-else>
          <div class="col-sm-12 col-md-6 col-lg-2-half" v-for="i in 5" :key="i">
              <b-skeleton-img style="border-radius: 18px;"></b-skeleton-img>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-2-half mt-3">
            <b-skeleton-img style="border-radius: 18px;"></b-skeleton-img>
          </div>
        </div>

        <div class="table-content product-listing-table neu col-sm-12 col-lg-12">
          <div class="space-between title-border" v-if="!loader">
            <div class="table-title">{{$t('Last Notifications')}}</div>
            <div class="table-action">
                {{$t('Last 10 notifications are showing.')}} <span class="font-servislet cursor-pointer text-decoration-underline" v-b-toggle.notification-sidebar>{{$t('Show All')}}</span>
            </div>
          </div>
          <!--            <div class="filters">-->
          <!--                <div class="filter-input">-->
          <!--                  <b-form-input :placeholder="$t('Search')" />-->
          <!--                  <i class="fa-solid fa-filter"></i>-->
          <!--                </div>-->
          <!--            </div>-->
          <div class="table-responsive mt-4" v-if="!loader">
            <table id="productTable" class="w-100 no-last">
              <thead>
              <tr class="product-listing-table-head">
                <th>{{ $t('Action') }}</th>
                <th>
                  {{$t('Date')}}
                </th>
                <th class="text-end">
                  {{ $t('Record ID') }}
                </th>
              </tr>
              </thead>
              <tbody v-if="list.length>0">
                <tr v-for="item in list" :key="item.$index">
                  <td class="pl-0">
                    {{item.title}}
                  </td>
                  <td>
                    {{dateTimeFormat(item.date)}}
                  </td>
                  <td>
                    <div class="d-flex justify-content-end align-items-center">
                      {{item.record_id}}
                        <span class="add-btn ml-2" @click="$router.push(item.link)">
                          <i class="fa-solid fa-eye"></i>
                        </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="text-center my-3" v-if="list.length === 0">
              {{ $t('No data found to list.') }}
            </div>
          </div>
          <div v-else>
            <loader />
          </div>
        </div>
      </div>
    </Default>
  </div>
</template>

<script>
import Default from "@/layouts/default.vue";
import axios from "@/plugins/axios";
export default{
  name:'HomePage',
  components:{
    Default
  },
  data(){
    return{
      currentPage: 1,
      rows: 10,
      search_text: '',
      loader: true,
      list:[],
      widget_loading: true,
      allOrders:{},
      cancelledOrders:{},
      waitingOrders:{}
    }
  },
  mounted(){
    this.getNotifi()
    this.getWidgets()
  },
  computed:{
    expanded_menu(){
      return this.$store.state.menu.status
    },
    color() {
      return localStorage.getItem('color')
    }
  },
  methods:{
    getNotifi(){
      this.loader = true
      axios.get('/v1/customers/notifications',{
        params:{
          page: 1,
          size: 10
        }
      }).then(response=>{
        if(response.data.status){
          this.list = response.data.responseData.notifications.items
          this.loader = false
        }
      })
    },
    getWidgets(){
      this.widget_loading = true
      axios.get('/v1/customers/get-orders').then(response=>{
        if(response.data.status){
          this.allOrders = response.data.responseData.allOrders
          this.cancelledOrders = response.data.responseData.cancelledOrders
          this.waitingOrders = response.data.responseData.waitingOrders
          this.widget_loading = false
        }
      })
    }
  }
}

</script>


<style scoped>
.table-content{
  padding: 30px;
  margin-top: 40px;
}

.trash-icon img{
  width: 12px;
  height: auto;
}
.product-image img{
  width: 100%;
}

.filters{
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  margin-top: 20px;
}
.search-area input, .search-area input:focus{
  background-color: transparent !important;
  min-width: 300px !important;
  font-size: 14px !important;
  border: none !important;
}
.search-area input::placeholder{
  color: v-bind('color') !important;
}

.summary-item{
  background-color: #DCE4E745;
  border-radius: 18px;
  color: #1c1c1c;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: .5rem;
//margin-bottom: 20px;
  transition: .3s all;
  cursor: pointer;
}
.summary-item .title{
  font-size: 12px;
  font-weight: 600;
}

.summary-item .sub-data{
  font-size: 18px;
  font-weight: 600;
}
.summary-item .description{
  font-size: 10px;
  font-weight: 400;
  white-space: nowrap;
}
.summary-item:hover {
  background-color: v-bind('color');
  color: #f4f4f4;
}
.summary-item .space-between i {
  font-size: 12px;
  padding: 8px 9px;
  background-color: #D9DDDFB2;
  border-radius: 100%;
}
.summary-item:hover .space-between i {
  background-color: #f9f9f9;
  color: v-bind('color');
}
.category-badge{
  padding: 5px 20px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 10px;
  font-family: Montserrat;
}
.sale-badge{
  color: #28C76F;
  background-color: #28C76F1F;
}
.product-badge{
  background-color: #07C7E31F;
  color: #07C7E3;
}
.cancel-badge{
  color: #EA5455;
  background-color: #EA54551F;
}
.dealer-badge{
  color: #8D82FD;
  background-color: #8D82FD1F;
}
.add-btn{
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #D3D3D34D;
  border-radius: 3px;
  color: #777;
  cursor: pointer;
}
.summary-item-height {
  min-height: 120px;
}
.title-border{
  padding-bottom: 12px;
  border-bottom: 1px solid #00000033;
}
.table-action{
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  color: #777;
}
.table-title{
  font-family: Inter;
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
  color: #1C1C1C;
}
.table-action span{
  font-weight: 500;
}
.filter-input{
  display: flex;
  border: 1px solid v-bind('color');
  align-items: center;
  border-radius: 5px;
  padding: 4px 16px;
  color: v-bind('color');
  min-width: 300px;
}
.filter-input .form-control::placeholder{
  color: v-bind('color');
}
.filter-input input, .filter-input input:focus{
  color: v-bind('color');
  padding-left: 0 !important;
  font-size: 14px;
  border: none !important;
}
.sub-data-rate{
  font-size: 11px;
  font-weight: 500;
  margin-left: .5rem;
  border-radius: 100px;
  padding: 4px 12px;
}
.sub-data-rate.success{
  background-color: #28C76F24;
  color: #28C76F;
}
.sub-data-rate.success i{
  transform: rotate(45deg)
}
.sub-data-rate.danger i{
  transform: rotate(135deg)
}
.sub-data-rate.danger{
  background-color: #EF212229;
  color: #EF2122;
}
.svg-area {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #D9DDDFB2;
  border-radius: 100%;
  transition: .3s all;
}
.summary-item:hover .svg-area {
  background-color: #f9f9f9;
}

.summary-item:hover svg path {
  fill: v-bind('color') /* Change the fill color to red */
}
.summary-item:hover svg circle {
  fill: #f9f9f9 /* Change the fill color to red */
}
</style>
