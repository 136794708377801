/* eslint-disable */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import global from './global.js'
import helpers from './helpers'
import store from "@/store";

Vue.mixin({
  methods: {
    ...helpers
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
