const text = localStorage.getItem('color');
const bg = localStorage.getItem('color');
const opacitied_bg = localStorage.getItem('color') ? localStorage.getItem('color').substring(0, localStorage.getItem('color').length - 1) + ', .08)' : '';
const styles = `
  .dealer-text {
    color: ${text} !important;
  }
  .dealer-bg {
    background-color: ${bg};
  }
  .dealer-bg-muted{
    background-color: ${bg};
    opacity: .08;
  }
  .opacitied-bg{
    background-color: ${opacitied_bg} !important;
  }
  input[type=checkbox]:checked {
    accent-color: ${bg} !important;
    color: #ffffff !important;
  }
  .dealer-border{
    border: 1px solid ${text} !important;
  }
  #profil-dropdown__BV_toggle_ {
    color: ${text} !important;
  }
  #profil-dropdown .dropdown-menu .dropdown-item:hover{
    background-color: ${opacitied_bg} !important;
    color: ${text} !important;
  }
  #finance-actions__BV_toggle_ {
    border: 1px solid ${text} !important;
    background-color: ${opacitied_bg} !important;
    color: ${text} !important;
  }
  #finance-actions .dropdown-menu .dropdown-item:hover{
    background-color: ${opacitied_bg} !important;
    color: ${text} !important;
  }
  .dropdown-menu .dropdown-item:focus{
    background-color: ${opacitied_bg} !important;
    color: ${text} !important;
  }
  input[type=radio] {
    accent-color: ${bg} !important;
  }
  .form-control:focus{
    border: 1px solid ${text} !important;
  }
  .form-white .form-group.input-material .form-control:focus {
    border: 1px solid ${text} !important;
  }
  .form-group.input-material .form-control[value]:not([value=""]) ~ label,
  .form-group.input-material .form-select:focus ~ label,
  .form-group.input-material .form-select[value]:not([value=""]) ~ label,
  .form-white .form-group.input-material .form-control:focus ~ label {
    top: unset;
    bottom: 100%;
    font-family: Inter;
    bottom: calc(100% - 20px);
    font-size: 12px;
    color: ${text};
    font-weight: 400;
    padding: 0 10px;
    background-color: #fff;
    margin-bottom: 10px;
  }
  .payment-price:focus{
    border: 1px solid ${text} !important;
  }
  .border-0:focus {
    border: none !important;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    right: 18px;
    bottom: 2px;
    background-color: #fff;
    transition: .4s;
    border-radius: 50%;
  }
    .toggle input:checked + .slider {
    background-color: ${text};
  }
  
  .toggle .slider:after {
    position: absolute;
    content: "✓";
    color: ${text};
    font-size: 12px;
    top: 2px;
    right: 5px;
    opacity: 0;
    transition: .4s;
  }
  .flatpickr-day.selected {
  background: ${text} !important;
  border-color: ${text} !important;
  }
  .flatpickr-day:hover {
  background: ${opacitied_bg} !important;
  border-color: ${opacitied_bg} !important;
  color: ${text} !important;
  }
  .custom-flatpickr-button,.custom-flatpickr-button:hover{
    width: 90% !important;
    color: #fff !important;
    margin: .75rem 0 !important;
    background-color: ${text} !important;
  }
}
`;
const styleElement = document.createElement('style');
styleElement.innerHTML = styles;
document.head.appendChild(styleElement);
