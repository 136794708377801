import Vue from "vue";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
window.helper = require("@/helpers").default;


import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);
import Loader from "@/components/Loader.vue";
Vue.component('loader',Loader)

import '@/plugins/validate/validation'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
Vue.component('ValidationObserver',ValidationObserver)
Vue.component('ValidationProvider',ValidationProvider)
localize('tr')


import VueMask from 'v-mask'
Vue.use(VueMask)
import money from 'v-money'
Vue.use(money, {
    decimal: ',',
    precision: 2,
    thousands: '.',
    prefix: '₺',
    suffix: '',
    masked: false
})
import './plugins/styles'

import CountrySelect from "@/components/selects/CountrySelect.vue";
import CitySelect from "@/components/selects/CitySelect.vue";
import DistrictSelect from "@/components/selects/DistrictSelect.vue";
import StreetSelect from "@/components/selects/StreetSelect.vue";
Vue.component('country-select',CountrySelect)
Vue.component('city-select',CitySelect)
Vue.component('district-select',DistrictSelect)
Vue.component('street-select',StreetSelect)


import Passenger from "@/components/icons/Passenger.vue";
import Truck from "@/components/icons/Truck.vue";
import Motorcycle from "@/components/icons/Motorcycle.vue";
import SUV from "@/components/icons/SUV.vue";
import VAN from "@/components/icons/VAN.vue";
Vue.component('passenger',Passenger)
Vue.component('truck',Truck)
Vue.component('motorcycle',Motorcycle)
Vue.component('suv',SUV)
Vue.component('van',VAN)

import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
Vue.component('flat-pickr',flatPickr)
