<template>
  <div>
    <Default>
      <div class="page-content">
        <ul id="breadcrumb-scroll">
          <li>
            <a href="/" itemprop="item"><span itemprop="name">{{ $t('Summary') }}</span></a>
          </li>
          <li>
          <span class="active">
               {{ $t('Downloaded Files') }}
          </span>
          </li>
        </ul>

        <div class="table-content product-listing-table neu col-sm-12 col-lg-12">

          <div class="filters">
            <div class="d-flex align-items-center gap-3">
              <div class="search-area dealer-border dealer-text">
                <b-form-input v-model="search_text" v-on:keydown.enter="filter()"
                              :placeholder="$t('Search')" class="px-0"/>
                <i class="fa-solid fa-xmark cursor-pointer" @click="search_text = ''; clearRoute()"
                   v-if="search_text"></i>
                <i class="fa-solid fa-filter" v-else></i>
              </div>
              <div class="search-area dealer-border dealer-text">
                <i class="fa-regular fa-calendar-check"></i>
                <flat-pickr :placeholder="$t('Date Range')" v-model="date" class="form-control bg-transparent"
                            :config="{dateFormat: 'Y-m-d',mode: 'range',locale: this.locale,allowInput: true,enableTime: false,maxDate: this.end_date,minDate: this.calculate1YearAgo,onReady: this.addCustomButton,closeOnSelect: false}"/>
                <i class="fa-solid fa-xmark cursor-pointer" @click="date = null; clearDateRange()"
                   v-if="date"></i>
              </div>

            </div>
          </div>

          <div v-if="!$store.state.files.loader">
            <div class="table-responsive">
              <table id="productTable" class="w-100 no-last">
                <thead>
                <tr class="product-listing-table-head">
                  <th>{{ $t('File') }}</th>
                  <th class="text-end">{{ $t('Download') }}</th>
                </tr>
                </thead>
                <tbody v-if="files.length>0">
                <tr v-for="i in files" :key="i.id">
                  <td>{{i.name}}</td>
                  <td>
                    <div class="d-flex justify-content-end">
                      <a class="btn see-detail-btn opacitied-bg" download :href="i.path">
                        <i class="fa-solid fa-download"></i>
                      </a>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
              <div class="text-center my-3" v-if="files.length === 0">
                {{ $t('No data found to list.') }}
              </div>
            </div>

            <hr>

            <div class="d-flex justify-content-between pagination-count align-items-center">
                <span>
                  <span class="pagination-text">{{ $t('Show by per page:') }} &nbsp;</span>
                  <select name="pagination" id="pagination-select" @change="getList()" v-model="size">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </span>
              <span class="d-flex align-items-center" style="gap: 1rem;">
                   <span>
             <span class="fw-bolder">{{ $store.state.files.pagination.total }}</span> dosyadan
                  <span
                      class="fw-bolder">{{
                      $store.state.files.pagination.current_page === 1 ? 1 : (($store.state.files.pagination.current_page - 1) * $store.state.files.pagination.per_page) + 1
                    }} - {{
                      $store.state.files.pagination.total_page === $store.state.files.pagination.current_page ? $store.state.files.pagination.total : $store.state.files.pagination.current_page * $store.state.files.pagination.per_page
                    }}</span> gösteriliyor.</span>
                <b-pagination
                    :total-rows="$store.state.files.pagination.total"
                    :per-page="size"
                    align="center"
                    size="sm"
                    class="my-1"
                    first-number
                    last-number
                    v-model="currentPage"
                    @input="changeCurrentPage()"
                />
              </span>
            </div>
          </div>

          <div v-else>
            <loader/>
          </div>
        </div>
      </div>
    </Default>
  </div>
</template>

<script>
import Default from "@/layouts/default.vue";
import {Turkish} from '@/locales/flatpickr/tr'
import {English} from '@/locales/flatpickr/en'
import PaymentTypeSelect from "@/components/selects/payments/PaymentTypeSelect.vue";
import axios from "@/plugins/axios";
import i18n from "@/i18n";

export default {
  name: 'PaymentList',
  components: {
    Default,
    PaymentTypeSelect
  },
  data() {
    return {
      currentPage: 1,
      size: 10,
      search_text: null,
      sort_direction: null,
      sort_column: null,
      hour: '',
      end_date: '',
      date: null,
      locale: localStorage.getItem('locale') === 'tr' ? Turkish : English,
    }
  },
  mounted() {
    const currentDate2 = new Date();
    const year2 = currentDate2.getFullYear();
    const month2 = String(currentDate2.getMonth() + 1).padStart(2, '0');
    const day2 = String(currentDate2.getDate()).padStart(2, '0');
    const hour2 = String(currentDate2.getHours() + 3).padStart(2, '0');
    const minute2 = String(currentDate2.getMinutes()).padStart(2, '0');
    const second2 = String(currentDate2.getSeconds()).padStart(2, '0');

    this.end_date = `${year2}-${month2}-${day2}T${hour2}:${minute2}:${second2}`;
    this.getList()
  },
  watch: {
    '$route.query': function () {
      this.getList()
    },
    search_text(){
      if(this.search_text.length === 0){
        this.clearRoute()
      }
    }
  },
  computed: {
    calculate1YearAgo() {
      const oneYearAgoDate = new Date(new Date().getTime() - 365 * 24 * 60 * 60 * 1000);

      const oneYearAgoDateString = oneYearAgoDate.toISOString().replace(/T/, '-').replace(/\..+$/, '');

      return oneYearAgoDateString
    },
    files() {
      return this.$store.state.files.list
    },
    color() {
      return localStorage.getItem('color')
    }
  },
  methods: {
    addCustomButton(selectedDates, dateStr, instance){
      // Create a custom button
      const customButton = document.createElement('button');
      customButton.innerHTML = this.$i18n.t('Apply Range');
      customButton.className = 'btn dealer-bg custom-flatpickr-button'; // Add your custom classes here
      customButton.onclick = () => {
        this.setDateRange()
      };

      instance.calendarContainer.appendChild(customButton);
    },
    clearDateRange(){
      delete this.$route.query['start_date']
      delete this.$route.query['end_date']
      const currentQuery = this.$route.query;
      const newQuery = {
        ...currentQuery
      };
      const queryString = Object.keys(newQuery).map(key => `${key}=${newQuery[key]}`).join('&');
      const url = `${window.location.pathname}?${queryString}`;

      window.location.href = url;
    },
    setDateRange() {
      if (this.date && this.date.length === 23) {
        var ar = this.date.split(' ')
        const currentQuery = this.$route.query;
        const newQuery = {
          ...currentQuery,
          start_date: ar[0],
          end_date: ar[2],
        };
        const queryString = Object.keys(newQuery).map(key => `${key}=${newQuery[key]}`).join('&');
        const url = `${window.location.pathname}?${queryString}`;
        window.location.href = url;
      }
    },
    filter() {
      const currentQuery = this.$route.query;
      const newQuery = {
        ...currentQuery,
        search: this.search_text
      };
      const queryString = Object.keys(newQuery).map(key => `${key}=${newQuery[key]}`).join('&');
      const url = `${window.location.pathname}?${queryString}`;
      window.location.href = url;
    },
    clearRoute() {
      delete this.$route.query['search']
      const currentQuery = this.$route.query;
      const newQuery = {
        ...currentQuery
      };
      const queryString = Object.keys(newQuery).map(key => `${key}=${newQuery[key]}`).join('&');
      const url = `${window.location.pathname}?${queryString}`;

      window.location.href = url;
    },
    getList() {
      if (this.$route.query.page) {
        this.currentPage = this.$route.query.page
      } else {
        this.currentPage = 1
      }
      if (this.$route.query.search) {
        this.search_text = this.$route.query.search
      }
      if (this.$route.query.start_date && this.$route.query.end_date) {
        this.date = this.$route.query.start_date + ' - ' + this.$route.query.end_date
      }
      this.$store.dispatch('files/getFiles', {
        page: this.currentPage,
        size: this.size,
        search: this.search_text,
        start_date: this.$route.query.start_date && this.$route.query.end_date ? this.$route.query.start_date + 'T00:00:00' : null,
        end_date: this.$route.query.start_date && this.$route.query.end_date ? this.$route.query.end_date + 'T23:59:00' : null
      })
    },
    changeCurrentPage() {
      const currentQuery = this.$route.query;
      const newQuery = {
        ...currentQuery,
        page: this.currentPage
      };
      const queryString = Object.keys(newQuery).map(key => `${key}=${newQuery[key]}`).join('&');
      const url = `${window.location.pathname}?${queryString}`;
      window.location.href = url;
    }
  }
}

</script>


<style scoped>
.page-content {
  margin-bottom: 100px;
}

.table-content {
  padding: 30px;
  margin-top: 40px;
}

.trash-icon img {
  width: 12px;
  height: auto;
}

.product-image img {
  width: 100%;
}

.see-detail-btn, .see-detail-btn:hover {
  border: 1px solid v-bind('color');
  color: v-bind('color');
  font-family: Montserrat;
  font-size: 12px;
  padding: 8px 12px;
}

::placeholder {
  color: v-bind('color') !important;
}

.filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  gap: 10px;
  width: 100%;
  overflow-x: clip;
}

.filters::-webkit-scrollbar {
  display: none;
}

.search-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px;
  border-radius: 5px;
}

.search-area input, .search-area input {
  background-color: transparent !important;
  min-width: 265px !important;
  font-size: 14px !important;
  border: none !important;
}

.search-area input {
  color: v-bind('color');
}

.search-area input::placeholder {
  color: v-bind('color');
}
</style>
