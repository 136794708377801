<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
/* eslint-disable */
//import CreatePassword from "@/views/CreatePassword.vue";

export default {
  name: 'App',
  data() {
    return {

    }
  },
  mounted() {
    if(this.$route.name !== 'Register' && this.$route.name !== 'Login' && this.$route.name !== 'ForgetPassword' && this.$route.name !== 'SendPasswordSuccess' && this.$route.name !== 'CreatePassword'){
      if(!localStorage.getItem('access_token')) {
        if(this.$route.path !== '/login') {
          this.$router.push('/login')
        }
      }
    }
  },
  methods: {

  }

}
</script>

<style>
@import './assets/style.css';
</style>
