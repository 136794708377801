<template>
  <div id="login" class="position-relative">
    <div class="container-fluid">
      <ValidationObserver ref="createRules">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-6" id="left">
            <div class="forms-g-wrapper w-50">
              <h2 class="text-center">{{ $t('Create Password') }}</h2>
              <div class="description text-center">{{$t('Your password must be at least 7 characters and contain letters and numbers.')}}</div>

              <div class="form-wrapper">
                <ValidationProvider :name="$t('Password')" #default="{errors}" rules="required|min:2" vid="password">
                  <div class="input-wrapper">
                    <b-input-group>
                      <template #prepend>
                        <img src="/images/icons/lock.svg" alt="">
                      </template>
                      <b-form-input :placeholder="$t('Password')" type="password" v-model="password"
                                    v-if="!show_password" v-on:keyup.enter="create()"></b-form-input>
                      <b-form-input :placeholder="$t('Password')" type="text" v-model="password" v-if="show_password"
                                    v-on:keyup.enter="create()"></b-form-input>
                      <button class="toggle-password" @click="show_password = !show_password">
                        <i class="fa-sharp fa-regular fa-eye-slash"></i>
                      </button>
                    </b-input-group>
                  </div>
                  <label for="email" class="text-center text-danger" v-if="errors.length>0"><small>* {{errors[0]}}.</small></label>
                </ValidationProvider>
                <ValidationProvider :name="$t('Password Again')" #default="{errors}" rules="required|min:2|confirmed:password">
                  <div class="input-wrapper">
                    <b-input-group>
                      <template #prepend>
                        <img src="/images/icons/lock.svg" alt="">
                      </template>
                      <b-form-input :placeholder="$t('Password')" type="password" v-model="password_confirmation"
                                    v-if="!show_password_confirmation" v-on:keyup.enter="create()"></b-form-input>
                      <b-form-input :placeholder="$t('Password')" type="text" v-model="password_confirmation" v-if="show_password_confirmation"
                                    v-on:keyup.enter="create()"></b-form-input>
                      <button class="toggle-password" @click="show_password_confirmation = !show_password_confirmation">
                        <i class="fa-sharp fa-regular fa-eye-slash"></i>
                      </button>
                    </b-input-group>
                  </div>
                  <label for="email" class="text-center text-danger" v-if="errors.length>0"><small>* {{errors[0]}}.</small></label>
                </ValidationProvider>

                <button @click="create()">{{ $t('Save') }}</button>
              </div>

            </div>
          </div>
          <div class="col-lg-6" id="right">
            <div class="step1">
              <img src="https://s3-alpha-sig.figma.com/img/697b/1900/dc805255d4034e6bf71c8e515080bb8e?Expires=1722816000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=biIwSGjuoOjbdPZi2R5rUwOjI06i40n5DNKCzAvQJM9p1YSoEvud39oDwldeWNkbrAQurtzfxzOEq8FDE0htcVlk~9Axl3Nkz-vKBTdD~F1cNUMz28O7w2aBj5~nb0iGzDqxeFi6I9JcoYB8dNhyKkIr0Us-J6FvRMj0LyPBK4qUeBxE4V8bRr~n5QX9aKd3O-W1qRYEjvWsn9W5UiKeq-9eOczeBN~17FWTszkSXBeRiaH6EYungsxExzjmXTmoVd6Tx2F3hF-Vx5MsWOrSi8G8rnnW9Fl4CT26vo2PssyrrVywLiqcQix4qM4yUqKyv77kjjWy9o804dVcCt~yeA__" alt="">
            </div>
          </div>
        </div>
      </ValidationObserver>
    </div>
    <div class="powered-by">
      powered by <a href="https://www.servislet.com/" target="_blank"><img src="https://www.servislet.com/logo.svg" alt="" width="80"></a>
    </div>
  </div>
</template>

<script>
import axios from "@/plugins/axios";
/* eslint-disable */
export default {
  name: 'CreatePassword',
  data() {
    return {
      password: '',
      password_confirmation:'',
      show_password: false,
      show_password_confirmation: false,
    }
  },
  mounted(){

  },
  methods: {
    create(){
      this.$refs.createRules.validate().then(success=>{
        if(success){
          this.$axios.post('v1/customers/reset',{
            token: this.$route.params.token,
            password_confirmation: this.password_confirmation,
            password: this.password
          }).then(response=>{
            if(response.data.responseData.status){
              this.$swal({
                iconHtml: '<img src="/images/toast/success.svg" width="32" height="32">',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                text: this.$i18n.t('You are redirected to the login page.'),
                title: this.$i18n.t('Your password successfully changed.'),
                customClass: {
                  icon: 'swal2-no-border'
                }
              })
              this.$router.push('/login')
            }
          })
        }else{
          this.$swal({
            iconHtml: '<img src="/images/toast/warning.png" width="32" height="32">',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            title: this.$i18n.t('Warning')+' !',
            text: this.$i18n.t('Please check fields.'),
            customClass: {
              icon: 'swal2-no-border'
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.step1 {
  background-color: #1C1C1C;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 50px 0 0 50px;
}

.step1 img {
  height: 350px;
  width: auto;
  position: absolute;
  bottom: 0;
}

#login .col-lg-6 {
  padding: 0 !important;
}

#left {
  display: flex;
  align-items: center;
  justify-content: center;
}

.forms-g-wrapper h2 {
  color: #2A4195;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 2rem;
}

.input-wrapper:first-child {
  margin-top: 1.75rem !important;
}

.input-wrapper {
  border: 1px solid #D8D6DE;
  padding: 2px 12px;
  border-radius: 5px;
  width: 100%;
  margin-top: 1.5rem;
  position: relative;
}

.form-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%
}

.form-actions a, .form-actions .select-remember {
  color: #1c1c1c;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-top: 1rem;
  text-decoration: none !important;
}

.form-wrapper button {
  border-radius: 5px;
  background: #2A4195;
  color: #f4f4f4;
  width: 100%;
  margin-top: 50px;
  border: 1px solid #2A4195;
  outline: none !important;
  box-shadow: none !important;
  transition: .5s;
  padding: 10px;
}

.input-wrapper svg {
  color: #d9d9d9;
}

.toggle-password {
  border: none !important;
  background: transparent !important;
  outline: none !important;
  box-shadow: none !important;
  color: #d9d9d9 !important;
  margin: 0 !important;
  width: min-content !important;
  padding: 0 !important;
}
.open-text{
  font-size: 16px;
  font-weight: 300;
  line-height: 40px;
  color: #77777799;
}
.powered-by{
  position: absolute;
  bottom: 10px;
  left: 30px;
  display: flex;
  align-items: baseline;
  font-size: 12px;
  font-weight: 400;
  line-height: 50px;
  text-align: left;
  color: #777777;
  gap: .5rem;

}

@media only screen and (max-width: 992px) {
  #right {
    display: none;
  }

  #login {
    height: 100vh;
    display: flex;
    align-items: center;
  }
}
</style>
