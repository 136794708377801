import Vue from "vue";
import Vuex from 'vuex';
import axiosInstance from "@/plugins/axios";

Vue.use(Vuex);

const state = {
    list: [],
    pagination: {},
    loader: true,
    payment: {},
    type_loader: true,
    types:[],
    type_pagination: {}
};

const mutations = {
    setLoader(state, data) {
        state.loader = data
    },
    setList(state, data) {
        state.list = data.items
        state.pagination = data.pagination
    },
    setTypes(state,data){
        state.types = data.items
        state.type_pagination = data.pagination
    }
};
const actions = {
    getPaymentList({commit}, data) {
        commit('setLoader', true)
        var params = {
            page: data.page,
            size: data.size,
            start_date: data.start_date,
            end_date: data.end_date,
            filter: {}
        }
        if(data.search){
            params.filter.searchText = data.search
        }
        if(data.payment_type_id){
            params.filter.payment_type_id = parseInt(data.payment_type_id)
        }
        axiosInstance.get('/v1/customers/payments', {
            params
        }).then(response => {
            if (response.data.status) {
                commit("setList", response.data.responseData.payment)
                commit('setLoader', false)
            }
        })
    },
    getPaymentDetail({state, commit}, data) {
        commit('setLoader', true)
        axiosInstance.get(`/v1/customers/payments/${data.id}`).then(response => {
            if (response.data.status) {
                state.payment = response.data.responseData.payment
                commit('setLoader',false)
            }
        })
    },
    getPaymentTypes({state,commit},data){
        state.type_loader = true
        var params = {
            page: data.page ?? 1,
            size: data.size,
            sort:{
                column: data.sort_column,
                direction: data.sort_direction
            },
            filter:{}
        }
        if(data.search){
            params.filter.searchText = data.search
        }
        axiosInstance.get(`/v1/customers/payment-types`,{
            params
        }).then(response => {
            if (response.data.status) {
                commit('setTypes',response.data.responseData.paymentType)
                state.type_loader = false
            }
        })
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
