<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7499 1.83325H15.5833C17.3117 1.83325 18.176 1.83325 18.7129 2.37022C19.2499 2.90719 19.2499 3.77144 19.2499 5.49992V19.4791H20.1666C20.5463 19.4791 20.8541 19.7869 20.8541 20.1666C20.8541 20.5463 20.5463 20.8541 20.1666 20.8541H1.83325C1.45356 20.8541 1.14575 20.5463 1.14575 20.1666C1.14575 19.7869 1.45356 19.4791 1.83325 19.4791H2.74992V8.24992C2.74992 6.52144 2.74992 5.65719 3.28689 5.12022C3.82386 4.58325 4.6881 4.58325 6.41658 4.58325H10.0833C11.8117 4.58325 12.676 4.58325 13.2129 5.12022C13.7499 5.65719 13.7499 6.52144 13.7499 8.24992V19.4791H15.1249V8.24992L15.1249 8.16789C15.125 7.37439 15.1251 6.64686 15.0455 6.05504C14.9579 5.40332 14.7518 4.71449 14.1852 4.14795C13.6187 3.58141 12.9298 3.37526 12.2781 3.28763C11.6946 3.20919 10.9792 3.20817 10.1988 3.20824C10.276 2.85327 10.405 2.58546 10.6202 2.37022C11.1572 1.83325 12.0214 1.83325 13.7499 1.83325ZM4.81242 7.33325C4.81242 6.95356 5.12022 6.64575 5.49992 6.64575H10.9999C11.3796 6.64575 11.6874 6.95356 11.6874 7.33325C11.6874 7.71295 11.3796 8.02075 10.9999 8.02075H5.49992C5.12022 8.02075 4.81242 7.71295 4.81242 7.33325ZM4.81242 10.0833C4.81242 9.70356 5.12022 9.39575 5.49992 9.39575H10.9999C11.3796 9.39575 11.6874 9.70356 11.6874 10.0833C11.6874 10.4629 11.3796 10.7708 10.9999 10.7708H5.49992C5.12022 10.7708 4.81242 10.4629 4.81242 10.0833ZM4.81242 12.8333C4.81242 12.4536 5.12022 12.1458 5.49992 12.1458H10.9999C11.3796 12.1458 11.6874 12.4536 11.6874 12.8333C11.6874 13.2129 11.3796 13.5208 10.9999 13.5208H5.49992C5.12022 13.5208 4.81242 13.2129 4.81242 12.8333ZM8.24992 16.7291C8.62961 16.7291 8.93742 17.0369 8.93742 17.4166V19.4791H7.56242V17.4166C7.56242 17.0369 7.87022 16.7291 8.24992 16.7291Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: "AddressType",
  props:{
    color: {
      type: String,
      default: '#1c1c1c'
    }
  }
}
</script>

<style scoped>

</style>
