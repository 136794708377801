import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = {
    status: true
};

const mutations = {
    setStatus(state, data) {
        state.status = data
    },
};
const actions = {
    setState({commit}, data) {
        console.log(data)
        commit('setStatus',data)
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
