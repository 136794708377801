import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
import password from "@/store/password";
import menu from "@/store/menu";
import country from "@/store/country";
import city from "@/store/city";
import district from "@/store/district";
import street from "@/store/street";
import dealer from "@/store/dealer"
import order from "@/store/order"
import payment from "@/store/payment"
import address from "@/store/address"
import discount from "@/store/discount"
import bank from "@/store/bank"
import placier from "@/store/placier"
import products from "@/store/products"
import notification from "@/store/notification"
import files from "@/store/files";

export default new Vuex.Store({
    modules: {
        password,
        menu,
        country,
        city,
        district,
        street,
        dealer,
        order,
        payment,
        address,
        discount,
        bank,
        placier,
        products,
        notification,
        files
    },
});
