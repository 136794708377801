<template>
  <div>
    <Default>
      <div class="page-content">
        <ul id="breadcrumb-scroll">
          <li>
            <a href="/" itemprop="item"><span itemprop="name">{{ $t('Summary') }}</span></a>
          </li>
          <li>
            <a href="/payments" itemprop="item"><span itemprop="name">{{ $t('Payments') }}</span></a>
          </li>
          <li>
          <span class="active">
               {{ $t('Payment Detail') }}
          </span>
          </li>
        </ul>
        <div class="order-tab-content row px-3" v-if="!$store.state.payment.loader">
          <div class="col-sm-12 col-md-10 col-lg-10 neu history-card">
            <div class="history-content">
              <div class="detail-title">{{$t('Payment Detail')}}</div>
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-6 px-3">
                  <div class="d-flex justify-content-between bottom-border py-3">
                    <span>{{$t('Dealer')}}</span>
                    <span class="font-servislet-dark">{{payment.dealer ? payment.dealer.name : '-'}}</span>
                  </div>
                  <div class="d-flex justify-content-between bottom-border py-3">
                    <span>{{$t('Payment Date')}}</span>
                    <span class="font-servislet-dark">{{dateFormat(payment.created_at)}}</span>
                  </div>
                  <div class="d-flex justify-content-between bottom-border py-3">
                    <span>{{$t('Payment Method')}}</span>
                    <span class="font-servislet-dark">{{payment.paymentType ? payment.paymentType.name : '-'}}</span>
                  </div>
                  <div class="d-flex justify-content-between bottom-border py-3">
                    <span>{{$t('Card Number')}}</span>
                    <span class="font-servislet-dark">{{payment.card_number ?? '-'}}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6 px-4">
                  <div class="d-flex justify-content-between bottom-border py-3">
                    <span>{{$t('Payment Amount')}}</span>
                    <span class="font-servislet-dark">{{currenctFormat('tr-TR',payment.total_amount ?? 0)}}</span>
                  </div>
                  <div class="d-flex justify-content-between bottom-border py-3">
                    <span>{{$t('Bank')}}</span>
                    <span class="font-servislet-dark">{{payment.bank_name ?? '-'}}</span>
                  </div>
                  <div class="d-flex justify-content-between bottom-border py-3">
                    <span>{{$t('Cash / Installment')}}</span>
                    <span class="font-servislet-dark">{{payment.installment_count === 0 ? $t('Cash') : payment.installment_count + ' ' + $t('Installment')}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-2 col-lg-2">
            <div class="right-button-content">
              <div style="line-height: 0">{{$t('Statement Type')}}</div>
              <div class="btn status-btn">{{$t('Invoice')}}</div>
              <div class="btn invoice-btn" @click="downloadPaymentDetail()">{{$t('Download Invoice')}}</div>
            </div>
          </div>
        </div>

        <div class="order-tab-content px-3" v-else>
          <div class="col-sm-12 col-md-12 col-lg-12 neu history-card">
            <loader />
          </div>
        </div>
      </div>
    </Default>
  </div>
</template>

<script>
import Default from "@/layouts/default.vue";
import jsPDF from "jspdf";
import html2canvas from 'html2canvas';

export default {
  name: 'PaymentDetail',
  components: {
    Default
  },
  data() {
    return {

    }
  },
  mounted(){
    this.getDetail()
  },
  computed:{
    payment(){
      return this.$store.state.payment.payment
    }
  },
  methods: {
    getDetail(){
      this.$store.dispatch('payment/getPaymentDetail',{
        id: this.$route.params.id
      })
    },
    downloadPaymentDetail(){
      const divToPrint = document.querySelector('.col-lg-10');
      html2canvas(divToPrint, {
        useCORS: true,
        logging: true,
        scale: 2,
        backgroundColor: '#fff',
        width: divToPrint.offsetWidth
      }).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('l', 'px', [divToPrint.offsetWidth, divToPrint.offsetHeight]);
        pdf.addImage(imgData, 'PNG', 0, 0, divToPrint.offsetWidth, divToPrint.offsetHeight);
        const pdfBlob = pdf.output('blob');
        const link = document.createElement('a');
        link.href = URL.createObjectURL(pdfBlob);
        link.download = `${this.payment.id}` + ' ' +this.$i18n.t('Payment Detail')+'.pdf';
        link.click();
      });
    }
  }
}

</script>


<style scoped>
.page-content{
  margin-bottom: 349px;
}
.order-tab-content {
  margin-top: 35px;
}

.left-info span {
  font-size: 14px;
  font-weight: 400;
  line-height: 17.07px;
}
.right-button-content{
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  padding: 0;
  padding-left: 1rem;
}
.status-btn, .status-btn:hover{
  background-color: #28C76F29;
  color: #28C76F;
  border: 1px solid #28C76F;
  font-size: 14px;
  widtH: 100%;
}
.invoice-btn, .invoice-btn:hover{
  background-color: #1c1c1c;
  color: #f4f4f4;
  border: 1px solid #1c1c1c;
  font-size: 14px;
  widtH: 100%;
}
.history-card{
  padding: 30px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
  color: #777;
}
.history-content{
  background-color: #fff;
  padding: 14px 38px;
  border-radius: 8px;
}
.bottom-border{
  border-bottom: 1px solid #E9ECEF99;
}

.detail-title{
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  color: #1c1c1c;
}
</style>
