<template>
  <div>
    <Default>
      <div class="page-content">
        <ul id="breadcrumb-scroll">
          <li>
            <a href="/" itemprop="item"><span itemprop="name">{{ $t('Summary') }}</span></a>
          </li>
          <li>
          <span class="active">
               {{ $t('Currents') }}
          </span>
          </li>
        </ul>

        <div class="table-content product-listing-table neu col-sm-12 col-lg-12" v-if="!$store.state.dealer.loader">
          <div class="filters">
            <div class="filter-input">
              <b-form-input placeholder="İsme veya e-postaya göre ara" class="bg-transparent" v-model="search_text" autocomplete="off"
                            v-on:keydown.enter="setSearch()"/>
              <i class="fa-solid fa-xmark cursor-pointer" v-if="search_text" @click="search_text=''; clearSearch()"></i>
              <i class="fa-solid fa-filter" v-else></i>
            </div>

            <span class="add-current" v-b-modal.add-current-form>
              <i class="fa-solid fa-plus"></i>
              {{ $t('Add Current') }}
            </span>
          </div>

          <div style="margin-bottom: 24px;">
            <div class="left-tabs">
              <span class="tab-item" :class="tab === 1 ? 'active-tab': ''" @click="tab = 1; approve = null; is_active= null; clearActive()">{{ $t('All') }}
<!--                <span class="product-count-badge">{{ dealers.length }}</span>-->
              </span>
              <span class="tab-item" :class="tab === 2 ? 'active-tab': ''" @click="tab = 2; is_active = 1; approve = 1; setActive()">{{ $t('Active') }} <span class="product-count-badge d-none">4</span></span>
              <span class="tab-item" :class="tab === 3 ? 'active-tab': ''" @click="tab = 3; is_active = 0; setActive()">{{ $t('Close') }} <span class="product-count-badge d-none">4</span></span>
              <span class="tab-item" :class="tab === 4 ? 'active-tab': ''" @click="tab = 4; is_active = 0; approve = 0; setActive()">{{ $t('Applications') }} <span class="product-count-badge d-none">4</span></span>
            </div>
          </div>
          <div class="table-responsive">
            <table id="productTable" class="w-100 no-last">
              <thead>
              <tr class="product-listing-table-head">
                <th>
                  <label class="product-listing-checkbox-label">
                    <input type="checkbox" v-model="checked">
                    <span class="checkmark"></span>
                  </label>
                </th>
                <th>ID</th>
                <th>
                  {{ $t('Current') }}
                </th>
                <th>
                  {{ $t('Placier') }}
                </th>
                <th>
                  {{ $t('Tax Number') }}
                </th>
                <th>
                  {{$t('Balance')}}
                </th>
                <th>
                  {{$t('Discounting')}}
                </th>
                <th>
                  {{$t('Risk Limit')}}
                </th>
                <th>
                  {{ $t('Active Status') }}
                </th>
                <th>
                  {{ $t('Approve Status') }}
                </th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,key) in dealers" :key="item.$index" v-if="dealers.length>0">
                <td>
                  <input type="checkbox" class="selectAllCheckbox" v-model="item.checked" @input="setChecked(item.id)" :name="'checkbox'+ item.id" />
                </td>
                <td>{{item.id}}</td>
                <td>
                  {{ item.name }}
                </td>
                <td>{{item.salesman ? item.salesman.name : '-'}}</td>
                <td>
                  {{item.tax_number ?? '-'}}
                </td>
                <td>
                  {{item.balance_amount ? currenctFormat('tr-TR',item.balance_amount) : '-'}}
                </td>
                <td>
                  {{item.discount_rate ? '% '+item.discount_rate : '-'}}
                </td>
                <td>
                  {{item.risk_limit ? currenctFormat('tr-TR',item.risk_limit) : '-'}}
<!--                  <br>-->
                  <!--                  <span>-</span>-->
                </td>
                <td>
                  <span v-if="item.is_active === true" class="category-badge active-badge">{{ $t('Active') }}</span>
                  <span v-else class="category-badge danger">{{ $t('Close') }}</span>
                </td>

                <td>
                  <span v-if="item.is_approved === true" class="category-badge active-badge">{{ $t('Approved') }}</span>
                  <span v-else class="category-badge  approve-badge">{{ $t('Approve Waiting') }}</span>
                </td>
                <td class="pr-0">
                  <div class="d-flex justify-content-end">
                    <b-dropdown id="actions" class="m-md-2" no-caret>
                      <template #button-content>
                        {{ $t('Actions') }} <i class="fa-solid fa-chevron-down"></i>
                      </template>
                      <b-dropdown-item @click="$router.push(`/currents/${item.id}`)">{{ $t('View') }}</b-dropdown-item>
                      <!--                      <b-dropdown-item>{{$t('Invoice')}}</b-dropdown-item>-->
                      <!--                      <b-dropdown-item>{{$t('Waybill')}}</b-dropdown-item>-->
                    </b-dropdown>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <hr>


          <div class="d-flex justify-content-between pagination-count align-items-center">
                <span>
                  <span class="pagination-text">{{ $t('Show by per page:') }} &nbsp;</span>
                  <select name="pagination" id="pagination-select" @change="getList()" v-model="size">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </span>
            <span class="d-flex align-items-center" style="gap: 1rem;">
                   <span>
             <span class="fw-bolder">{{ $store.state.dealer.pagination.total }}</span> cariden
                  <span
                      class="fw-bolder">{{
                      $store.state.dealer.pagination.current_page === 1 ? 1 : (($store.state.dealer.pagination.current_page - 1) * $store.state.dealer.pagination.per_page) + 1
                    }} - {{
                      $store.state.dealer.pagination.total_page === $store.state.dealer.pagination.current_page ? $store.state.dealer.pagination.total : $store.state.dealer.pagination.current_page * $store.state.dealer.pagination.per_page
                    }}</span> gösteriliyor.</span>
                <b-pagination
                    :total-rows="$store.state.dealer.pagination.total"
                    :per-page="size"
                    align="center"
                    size="sm"
                    class="my-1"
                    first-number
                    last-number
                    v-model="currentPage"
                    @input="getList()"
                />
              </span>
          </div>
        </div>

        <div v-else class="table-content product-listing-table">
          <loader/>
        </div>
      </div>

      <b-modal id="add-current-form" ref="add-current-form" size="lg" no-close-on-backdrop hide-header hide-footer>
        <div>
          <div class="space-between">
            <div class="modal-header-title">{{ $t('Add Current') }}</div>
            <img src="/images/icons/close.svg" alt="" class="cursor-pointer" @click="$refs['add-current-form'].hide();">
          </div>

          <div class="col-sm-12 col-md-6 col-lg-6">
            <current-select @setDealer="setDealer($event)"/>
          </div>

          <div class="space-between current" @click="openStep1()">
            <div class="d-flex align-items-center" style="gap: .5rem;">
              <i class="fa-solid fa-check-circle dealer-text"
                 v-if="current_name && current_title && current_email && current_phone && current_tax_office && current_tax_number && current.discount_rate && debt >0 && lend >0 && balance>0"></i>
              <span class="number" v-else>1</span>
              <span class="collapse-title">{{ $t('Current Informations') }}</span>
              <span class="warning-area" v-if="showerror1">{{ $t('Please check fields.') }}</span>
            </div>
            <i class="fa-solid" :class="area_1_visible ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
          </div>

          <b-collapse id="area-1" :visible="area_1_visible">
            <ValidationObserver ref="step1">
              <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                  <div class="form-white">
                    <ValidationProvider :name="$t('Current Name')" rules="required|max:100" v-slot="{ errors }">
                      <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                        <b-form-input v-model="current_name" class="nonrequired-input" @blur="inputName()"
                                      :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                        <label for="name" class="">{{ $t('Current Name') }} <span class="small"
                                                                                  :class="errors.length>0 ? 'text-danger' : ''">*</span></label>
                      </div>
                      <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                      <span class="error-text opacity-0" v-else>*</span>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                  <div class="form-white">
                    <ValidationProvider :name="$t('Title')" rules="max:100" v-slot="{ errors }">
                      <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                        <b-form-input v-model="current_title" class="nonrequired-input" @input="focused_title = true"
                                      @blur="focused_title = false"
                                      :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                        <label for="title" class="">{{ $t('Title') }}</label>
                      </div>
                      <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                      <span class="error-text opacity-0" v-else>*</span>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                  <div class="form-white">
                    <ValidationProvider :name="$t('E-Mail')" rules="required|email|max:60" v-slot="{ errors }">
                      <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                        <i class="fa-solid fa-envelope form-icon"></i>
                        <b-form-input v-model="current_email" class="nonrequired-input pl-32" @blur="autoFillEmail()"
                                      :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                        <label for="email" class="pl-21">{{ $t('E-Mail') }}<span class="small"
                                                                                 :class="errors.length>0 ? 'text-danger' : ''">*</span></label>
                      </div>
                      <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                      <span class="error-text opacity-0" v-else>*</span>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                  <div class="form-white" v-if="is_dealer_add">
                    <ValidationProvider :name="$t('Phone')" rules="min:19|max:19" v-slot="{ errors }">
                      <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                        <i class="fa-solid fa-phone form-icon"></i>
                        <input :value="formattedPhone" class="form-control nonrequired-input pl-32"
                               @blur="autoFillPhone()"
                               :class="errors.length>0 ? 'dangered-border' : ''" v-mask="'+9# (###) ### ## ##'"
                               required autocomplete="off" id="input1"/>
                        <label for="phone" class="pl-21">{{ $t('Phone') }}</label>
                      </div>
                      <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                      <span class="error-text opacity-0" v-else>*</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-white" v-if="!is_dealer_add">
                    <ValidationProvider :name="$t('Phone')" rules="min:19|max:19" v-slot="{ errors }">
                      <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                        <i class="fa-solid fa-phone form-icon"></i>
                        <b-form-input v-model="current_phone" class="nonrequired-input pl-32"
                                      @blur="autoFillPhone()"
                                      :class="errors.length>0 ? 'dangered-border' : ''" v-mask="'+90 (###) ### ## ##'"
                                      required autocomplete="off" id="input1"/>
                        <label for="phone" class="pl-21">{{ $t('Phone') }}</label>
                      </div>
                      <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                      <span class="error-text opacity-0" v-else>*</span>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                  <div class="form-white">
                    <ValidationProvider :name="$t('Tax Office')" rules="max:100" v-slot="{ errors }">
                      <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                      <span class="form-icon" style="left: 8px;">
                        <AddressType :color="'#BBBBBB'"/>
                      </span>
                        <b-form-input v-model="current_tax_office" class="nonrequired-input pl-32"
                                      :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                        <label for="tax_office" class="pl-21">{{ $t('Tax Office') }}</label>
                      </div>
                      <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                      <span class="error-text opacity-0" v-else>*</span>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                  <div class="form-white">
                    <ValidationProvider :name="$t('Tax Number')" rules="max:11" v-slot="{ errors }">
                      <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                        <b-form-input v-model="current_tax_number" class="nonrequired-input"
                                      :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                        <label for="tax_number" class="">{{ $t('Tax Number') }}</label>
                      </div>
                      <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                      <span class="error-text opacity-0" v-else>*</span>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                  <div class="form-white">
                    <ValidationProvider :name="$t('Mersis Number')" rules="max:20" v-slot="{ errors }">
                      <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                        <b-form-input v-model="current.mersis_number" class="nonrequired-input" required
                                      autocomplete="off"/>
                        <label for="fax">{{ $t('Mersis Number') }}</label>
                      </div>
                      <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                      <span class="error-text opacity-0" v-else>*</span>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                  <div class="form-white">
                    <ValidationProvider :name="$t('Fax')" rules="min:19|max:19" v-slot="{ errors }">
                      <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                        <i class="fa-solid fa-phone form-icon"></i>
                        <b-form-input v-model="current.fax" class="nonrequired-input pl-32"
                                      :class="errors.length>0 ? 'dangered-border' : ''" v-mask="'+90 (###) ### ## ##'"
                                      required autocomplete="off" id="input1"/>
                        <label for="fax" class="pl-21">{{ $t('Fax') }}</label>
                      </div>
                      <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                      <span class="error-text opacity-0" v-else>*</span>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-sm-3 col-md-3 col-lg-3 select-label">
                  <label for="discount_rate">{{ $t('Discount Rate') }}</label>
                  <b-form-checkbox @change="show_default_discount_rate ? current.discount_rate = default_discount_rate : '' " v-model="show_default_discount_rate">&nbsp;{{ $t('Default') }}: <span class="dealer-text">%{{parseFloat(default_discount_rate)}}</span></b-form-checkbox>
                </div>

                <div class="col-sm-3 col-md-3 col-lg-3 mt-3 pl-0" v-if="!show_default_discount_rate">
                  <div class="form-white">
                    <div class="form-group input-material">
                      <b-form-input v-model="current.discount_rate" v-mask="'###'" class="nonrequired-input"
                                    required autocomplete="off"/>
                    </div>
                    <span class="error-text opacity-0">*</span>
                  </div>
                </div>


                <div class="col-sm-3 col-md-3 col-lg-3 mt-3" v-else></div>

                <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                  <div class="form-white">
                    <div class="form-group input-material">
                      <money v-model="debt" class="form-control has-append-icon nonrequired-input" required
                             autocomplete="off" :disabled="debt_edit"/>
                      <i class="fa-solid form-icon-append cursor-pointer"
                         :class="debt_edit ? 'fa-lock' : 'fa-lock-open'" @click="debt_edit = !debt_edit"></i>

                      <label for="tax_number" class="">{{ $t('Debt') }}</label>
                    </div>
                    <span class="error-text opacity-0">*</span>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                  <div class="form-white">
                    <div class="form-group input-material">
                      <money v-model="lend" class="form-control has-append-icon nonrequired-input"
                             required autocomplete="off" :disabled="lend_edit"/>
                      <i class="fa-solid form-icon-append cursor-pointer"
                         :class="lend_edit ? 'fa-lock' : 'fa-lock-open'" @click="lend_edit = !lend_edit"></i>

                      <label for="tax_number" class="">{{ $t('Lend') }}</label>
                    </div>
                    <span class="error-text opacity-0">*</span>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                  <div class="form-white">
                    <div class="form-group input-material">
                      <money v-model="balance" class="form-control nonrequired-input has-append-icon" required
                             autocomplete="off" :disabled="balance_edit"/>

                      <i class="fa-solid form-icon-append cursor-pointer"
                         :class="balance_edit ? 'fa-lock' : 'fa-lock-open'" @click="balance_edit = !balance_edit"></i>

                      <label for="tax_number" class="">{{ $t('Balance') }}</label>
                    </div>
                    <span class="error-text opacity-0">*</span>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                  <div class="form-white">
                    <div class="form-group input-material">
                      <money v-model="risk_limit" class="form-control has-append-icon nonrequired-input" required
                             autocomplete="off" :disabled="risk_limit_edit"/>
                      <i class="fa-solid form-icon-append cursor-pointer"
                         :class="risk_limit_edit ? 'fa-lock' : 'fa-lock-open'"
                         @click="risk_limit_edit = !risk_limit_edit"></i>
                      <label for="tax_number" class="">{{ $t('Risk Limit') }}</label>
                    </div>
                    <span class="error-text opacity-0">*</span>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                  <div class="form-white">
                    <div class="form-group input-material">
                      <b-form-input v-model="payment_term" class="nonrequired-input" type="number" required
                                    autocomplete="off"/>
                      <label for="payment_term" class="">{{ $t('Payment Term') }}</label>
                    </div>
                    <span class="error-text opacity-0">*</span>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                  <div class="form-white">
                    <div class="form-group input-material">
                      <b-form-input v-model="current.installment_count" class="nonrequired-input" type="number"
                                    required
                                    autocomplete="off"/>
                      <i class="fa-solid fa-circle-info form-icon-append cursor-pointer" v-b-tooltip.hover
                         :title="$t('Max. number of installments according to the number of installments available in the payment method offered')"></i>
                      <label for="payment_term" class="">{{ $t('Max. Installment Count') }}</label>
                    </div>
                    <span class="error-text opacity-0">*</span>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                  <div class="form-white">
                    <div class="form-group input-material">
                      <b-form-input v-model="current.term_installment_count" class="nonrequired-input" type="number"
                                    required autocomplete="off"/>
                      <i class="fa-solid fa-circle-info form-icon-append cursor-pointer" v-b-tooltip.hover
                         :title="$t('How many installments should be offered to current customers with overdue orders?')"></i>

                      <label for="payment_term" class="">{{ $t('Installment Limitation') }}</label>
                    </div>
                    <span class="error-text opacity-0">*</span>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 select-label">
                  <label for="country">{{ $t('Price Display Type') }}</label>
                  <price-type @setType="current.list_price_type = $event.value" :is_auto="true" />
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 select-label">
                  <label for="country">{{ $t('Payment Methods') }} *</label>
                  <multiple-payment-type @setPaymentType="setPaymentType($event)" :error="payment_error" :is_auto="true" :selecteds="null"/>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 select-label">
                  <label for="placier">{{ $t('Placier') }}</label>
                  <placier-select @setPlacier="current.salesman_id = $event.id"/>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6"/>
                <div class="col-sm-12 col-md-6 col-lg-6"/>
                <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                  <div class="d-flex justify-content-end">
                    <button class="modal-footer-button save-button dealer-border dealer-bg" @click="setStep2()">{{ $t('Continue') }}</button>
                  </div>
                </div>
              </div>
            </ValidationObserver>
          </b-collapse>

          <hr>

          <div class="space-between current" @click="setStep2()">
            <div class="d-flex align-items-center" style="gap: .5rem;">
              <i class="fa-solid fa-check-circle dealer-text"
                 v-if="current_authorized_name && current_authorized_surname && current.position && authorized_email && authorized_phone"></i>

              <span class="number" v-else>2</span>
              <span class="collapse-title">{{ $t('Authorized Informations') }}</span>
              <span class="warning-area" v-if="showerror2">{{ $t('Please check fields.') }}</span>
            </div>
            <i class="fa-solid" :class="area_2_visible ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
          </div>

          <b-collapse id="area-2" :visible="area_2_visible">
            <ValidationObserver ref="step2">
              <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                  <div class="form-white">
                    <ValidationProvider :name="$t('Name')" rules="required|max:100" v-slot="{ errors }">
                      <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                        <b-form-input v-model="current_authorized_name" class="nonrequired-input"
                                      :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                        <label for="name" class="">{{ $t('Name') }} <span class="small"
                                                                          :class="errors.length>0 ? 'text-danger' : ''">*</span></label>
                      </div>
                      <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                      <span class="error-text opacity-0" v-else>*</span>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                  <div class="form-white">
                    <ValidationProvider :name="$t('Surname')" rules="max:100" v-slot="{ errors }">
                      <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                        <b-form-input v-model="current_authorized_surname" class="nonrequired-input"
                                      :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                        <label for="surname" class="">{{ $t('Surname') }}</label>
                      </div>
                      <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                      <span class="error-text opacity-0" v-else>*</span>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                  <div class="form-white">
                    <ValidationProvider :name="$t('Position')" rules="max:60" v-slot="{ errors }">
                      <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                        <b-form-input v-model="current.position" class="nonrequired-input"
                                      :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                        <label for="position" class="">{{ $t('Position') }}</label>
                      </div>
                      <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                      <span class="error-text opacity-0" v-else>*</span>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                  <div class="form-white">
                    <ValidationProvider :name="$t('E-Mail')" rules="required|email|max:100" v-slot="{ errors }">
                      <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                        <i class="fa-solid fa-envelope form-icon"></i>
                        <b-form-input v-model="authorized_email" class="nonrequired-input pl-32"
                                      :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                        <label for="email" class="pl-21">{{ $t('E-Mail') }} <span class="small"
                                                                                  :class="errors.length>0 ? 'text-danger' : ''">*</span></label>
                      </div>
                      <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                      <span class="error-text opacity-0" v-else>*</span>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                  <div class="form-white" v-if="!is_dealer_add">
                    <ValidationProvider :name="$t('Phone')" rules="min:19|max:19" v-slot="{ errors }">
                      <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                        <i class="fa-solid fa-phone form-icon"></i>
                        <b-form-input v-model="authorized_phone" class="nonrequired-input pl-32"
                                      v-mask="'+90 (###) ### ## ##'" :class="errors.length>0 ? 'dangered-border' : ''"
                                      required autocomplete="off"/>
                        <label for="phone" class="pl-21">{{ $t('Phone') }}</label>
                      </div>
                      <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                      <span class="error-text opacity-0" v-else>*</span>
                    </ValidationProvider>
                  </div>

                  <div class="form-white" v-if="is_dealer_add">
                    <ValidationProvider :name="$t('Phone')" rules="min:19|max:19" v-slot="{ errors }">
                      <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                        <i class="fa-solid fa-phone form-icon"></i>
                        <input :value="formattedPhone2" class="form-control nonrequired-input pl-32"
                               @input="is_dealer_add = false"
                               :class="errors.length>0 ? 'dangered-border' : ''"
                               required autocomplete="off" id="input2"/>
                        <label for="phone" class="pl-21">{{ $t('Phone') }}</label>
                      </div>
                      <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                      <span class="error-text opacity-0" v-else>*</span>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6"/>
                <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
                  <b-form-checkbox v-model="is_user">&nbsp;{{ $t('Create user for authorized user') }}</b-form-checkbox>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 mt-2" v-if="is_user">
                <span class="font-small">
                  {{ $t('Set a password for the user you will create to log in.') }}
                </span>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 mt-3" v-if="is_user">
                  <div class="form-white">
                    <ValidationProvider :name="$t('Password')" :rules="is_user ? 'required' : ''" vid="password"
                                        v-slot="{ errors }">
                      <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                        <img src="/images/icons/lock.svg" alt="" class="form-icon">
                        <b-form-input type="password" v-model="current.password" class="nonrequired-input pl-32"
                                      required autocomplete="off" :class="errors.length>0 ? 'dangered-border' : ''"
                                      v-if="password_type"/>
                        <b-form-input type="text" v-model="current.password" class="nonrequired-input pl-32" required
                                      autocomplete="off" :class="errors.length>0 ? 'dangered-border' : ''"
                                      v-if="!password_type"/>
                        <label for="email" class="pl-21">{{ $t('Password') }} <span class="small"
                                                                                    :class="errors.length>0 ? 'text-danger' : ''">*</span></label>

                        <i class="fa-sharp fa-regular fa-eye-slash append-icon"
                           @click="password_type = !password_type"></i>
                      </div>
                      <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                      <span class="error-text opacity-0" v-else>*</span>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 mt-3" v-if="is_user">
                  <div class="form-white">
                    <ValidationProvider :name="$t('Password Again')"
                                        :rules="is_user ? 'required|confirmed:password' : ''" v-slot="{ errors }">
                      <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                        <img src="/images/icons/lock.svg" alt="" class="form-icon">
                        <b-form-input type="password" v-model="current.password_confirmation"
                                      class="nonrequired-input pl-32" required autocomplete="off"
                                      :class="errors.length>0 ? 'dangered-border' : ''"
                                      v-if="password_confirmation_type"/>
                        <b-form-input type="text" v-model="current.password_confirmation"
                                      class="nonrequired-input pl-32" required autocomplete="off"
                                      :class="errors.length>0 ? 'dangered-border' : ''"
                                      v-if="!password_confirmation_type"/>
                        <label for="email" class="pl-21">{{ $t('Password Again') }} <span class="small"
                                                                                          :class="errors.length>0 ? 'text-danger' : ''">*</span></label>

                        <i class="fa-sharp fa-regular fa-eye-slash append-icon"
                           @click="password_confirmation_type = !password_confirmation_type"></i>
                      </div>
                      <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                      <span class="error-text opacity-0" v-else>*</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6"/>

                <div class="col-sm-12 col-md-6 col-lg-6 mt-4">
                  <div class="d-flex justify-content-end">
                    <button class="modal-footer-button save-button dealer-border dealer-bg" @click="setStep3()">{{ $t('Continue') }}</button>
                  </div>
                </div>
              </div>
            </ValidationObserver>
          </b-collapse>

          <hr>


          <div class="space-between current" @click="setStep3Open()">
            <div class="d-flex align-items-center" style="gap: .5rem;">
              <i class="fa-solid fa-check-circle dealer-text"
                 v-if="address_title && dealer_address && current.country_id && current.city_id && current.district_id && current.neighborhood_id"></i>

              <span class="number" v-else>3</span>
              <span class="collapse-title">{{ $t('Address Informations') }}</span>
              <span class="warning-area" v-if="showerror3">{{ $t('Please check fields.') }}</span>
            </div>
            <i class="fa-solid" :class="area_3_visible ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
          </div>

          <b-collapse id="area-3" :visible="area_3_visible">
            <ValidationObserver ref="step3">
              <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                  <div class="form-white">
                    <ValidationProvider :name="$t('Address Title')" rules="required|max:60" v-slot="{ errors }">
                      <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                      <span class="form-icon" style="left: 8px;">
                        <AddressType :color="'#BBBBBB'"/>
                      </span>
                        <b-form-input v-model="address_title" class="nonrequired-input pl-32"
                                      :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                        <label for="name" class="pl-21">{{ $t('Address Title') }} <span class="small"
                                                                                        :class="errors.length>0 ? 'text-danger' : ''">*</span></label>
                      </div>
                      <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                      <span class="error-text opacity-0" v-else>*</span>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6"/>

                <div class="col-sm-12 col-md-6 col-lg-6 mt-2 select-label">
                  <label for="country">{{ $t('Country') }} *</label>
                  <country-select @setCountry="setDealerCountry($event)" :is_dealer="dealer_country ? true : false"
                                  :dealer_country.sync="dealer_country"/>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 mt-2 select-label">
                  <label for="city">{{ $t('City') }} *</label>
                  <city-select @setCity="setDealerCity($event)" :is_dealer="dealer_city ? true : false"
                               :dealer_city.sync="dealer_city"/>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 mt-2 select-label">
                  <label for="district">{{ $t('District') }} *</label>
                  <district-select @setDistrict="setDealerDistrict($event)" :is_dealer="dealer_district ? true : false"
                                   :dealer_district.sync="dealer_district"/>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 mt-2 select-label">
                  <label for="street">{{ $t('Neighborhood') }} / {{ $t('Street') }}</label>
                  <street-select @setStreet="setDealerStreet($event)" :is_dealer="dealer_street ? true : false"
                                 :dealer_street.sync="dealer_street"/>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-12 mt-3">
                  <div class="form-white">
                    <ValidationProvider :name="$t('Address')" rules="required|max:200" v-slot="{ errors }">
                      <div class="form-group input-material has-textarea-label"
                           :class="errors.length > 0 ? 'input-error' : ''">
                        <!--                        <b-form-input v-model="dealer_address" style="height: 100px;" class="nonrequired-input pl-32" :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off" />-->
                        <b-form-textarea v-model="dealer_address" style="height: 100px;"
                                         class="nonrequired-input pl-32"
                                         :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                        <label for="name">{{ $t('Address') }} <span class="small"
                                                                    :class="errors.length>0 ? 'text-danger' : ''">*</span></label>
                      </div>
                      <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                      <span class="error-text opacity-0" v-else>*</span>
                    </ValidationProvider>
                  </div>
                </div>


                <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                  <div class="d-flex justify-content-end">
                    <button class="modal-footer-button save-button dealer-border dealer-bg" @click="addDealer()">{{ $t('Add') }}</button>
                  </div>
                </div>
              </div>
            </ValidationObserver>
          </b-collapse>
        </div>
      </b-modal>
    </Default>
  </div>
</template>

<script>
import Default from "@/layouts/default.vue";
import AddressType from "@/components/icons/AddressType.vue";
import axiosInstance from "@/plugins/axios";
import CurrentSelect from "@/components/selects/CurrentSelect.vue";
import MultiplePaymentType from "@/components/selects/MultiplePaymentType.vue";
import PriceType from "@/components/selects/current/PriceType.vue";
import PlacierSelect from "@/components/selects/current/PlacierSelect.vue";

export default {
  name: 'CurrentList',
  components: {
    Default,
    AddressType,
    CurrentSelect,
    MultiplePaymentType,
    PriceType,
    PlacierSelect
  },
  data() {
    return {
      currentPage: 1,
      size: 10,
      search_text: '',
      current: {
        debt: 0,
        lend: 0,
        balance: 0,
        discount_rate: parseFloat(localStorage.getItem('default_discount_rate'))
      },
      area_1_visible: true,
      area_2_visible: false,
      area_3_visible: false,
      is_user: false,
      password_type: true,
      password_confirmation_type: true,
      showerror1: false,
      showerror2: false,
      showerror3: false,
      focused_title: false,
      address_title: null,
      current_title: null,
      authorized_email: null,
      authorized_phone: '',
      current_phone: '',
      current_tax_office: '',
      current_tax_number: '',
      current_authorized_name: '',
      current_authorized_surname: '',
      dealer_country: '',
      dealer_city: '',
      dealer_district: '',
      dealer_address: '',
      dealer_street: '',
      current_name: '',
      is_dealer_add: false,
      ref_code: null,
      ref_id: null,
      dealer_no: null,
      current_email: '',
      payment_term: 0,
      risk_limit: 0,
      debt: 0,
      lend: 0,
      balance: 0,
      risk_limit_edit: false,
      balance_edit: false,
      debt_edit: false,
      lend_edit: false,
      payment_error: null,
      approve: null,
      checked: false,
      checkeds:[],
      is_active: null,
      tab: 1,
      show_default_discount_rate: true,
      default_discount_rate: parseFloat(localStorage.getItem('default_discount_rate'))
    }
  },
  mounted() {
    this.getList()
  },
  watch:{
    checked(){
      if(this.checked){
        this.checkeds = []
        this.dealers.forEach(item=>{
          item.checked = true
          this.checkeds.push(item.id)
        })
      }else{
        this.checkeds = []
        this.dealers.forEach(item=>{
          item.checked = false
        })
      }
    },
    search_text(){
      if(this.search_text.length === 0){
        this.clearSearch()
      }
    }
  },
  computed: {
    dealers() {
      return this.$store.state.dealer.list
    },
    expanded_menu() {
      return this.$store.state.menu.status
    },
    formattedPhone() {
      return this.current_phone.replace(/\D+/g, '').replace(/(\d{1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/, '+9$1 ($2) $3 $4 $5');
    },
    formattedPhone2() {
      return this.authorized_phone.replace(/\D+/g, '').replace(/(\d{1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/, '+9$1 ($2) $3 $4 $5');
    },
    color(){
      return localStorage.getItem('color')
    },
    opacity(){
      return localStorage.getItem('color').substring(0,localStorage.getItem('color').length-1+', 0.1)')
    }
  },
  methods: {
    clearActive(){
      delete this.$route.query['approved']
      delete this.$route.query['is_active']
      const currentQuery = this.$route.query;
      const newQuery = {
        ...currentQuery,
        tab: 1
      };
      const queryString = Object.keys(newQuery).map(key => `${key}=${newQuery[key]}`).join('&');
      const url = `${window.location.pathname}?${queryString}`;

      window.location.href = url;
    },
    setActive() {
        const currentQuery = this.$route.query;
        const newQuery = {
          ...currentQuery,
          approved : this.approve,
          is_active: this.is_active,
          tab: this.tab
        };
        const queryString = Object.keys(newQuery).map(key => `${key}=${newQuery[key]}`).join('&');
        const url = `${window.location.pathname}?${queryString}`;
        window.location.href = url;
    },
    setSearch(){
      const currentQuery = this.$route.query;
      const newQuery = {
        ...currentQuery,
        search_text: this.search_text
      };
      const queryString = Object.keys(newQuery).map(key => `${key}=${newQuery[key]}`).join('&');
      const url = `${window.location.pathname}?${queryString}`;
      window.location.href = url;
    },
    clearSearch(){
      delete this.$route.query['search_text']
      const currentQuery = this.$route.query;
      const newQuery = {
        ...currentQuery
      };
      const queryString = Object.keys(newQuery).map(key => `${key}=${newQuery[key]}`).join('&');
      const url = `${window.location.pathname}?${queryString}`;

      window.location.href = url;
    },
    setChecked(id){
      if(this.checkeds.includes(id)){
        this.checkeds.splice(this.checkeds.indexOf(id),1)
      }else{
        this.checkeds.push(id)
      }
    },
    setPaymentType(event) {
      if(event.length>0){
        var ids = []
        event.forEach(item=>{
          ids.push(item.paymentType.id)
        })
        this.current.payment_types = ids
      }else{
        this.current.payment_types = null
      }
    },
    inputName() {
      this.address_title = this.current_name
      if (!this.current_title) {
        this.current_title = this.current_name
      }
    },
    autoFillEmail() {
      if (!this.authorized_email) {
        this.authorized_email = this.current_email
      }
    },
    autoFillPhone() {
      if (!this.authorized_phone) {
        this.authorized_phone = this.current_phone
      }
    },
    getList() {
      if(this.$route.query.approved){
        this.approve = parseInt(this.$route.query.approved)
      }
      if(this.$route.query.search_text){
        this.search_text = this.$route.query.search_text
      }
      if(this.$route.query.is_active){
        this.is_active = parseInt(this.$route.query.is_active)
      }
      if(this.$route.query.tab){
        this.tab = parseInt(this.$route.query.tab)
      }
      this.$store.dispatch('dealer/getDealers', {
        page: this.currentPage,
        size: this.size,
        search: this.search_text,
        approve: this.approve,
        is_active: this.is_active
      })
    },
    openStep1() {
      this.area_2_visible = false;
      this.area_3_visible = false;
      this.area_1_visible = !this.area_1_visible;
    },
    setStep2() {
      // this.$refs.step1.validate().then(success => {
      //   if (success) {
      this.area_1_visible = false;
      this.area_3_visible = false;
      this.area_2_visible = !this.area_2_visible;
      //this.showerror1 = false
      //   } else {
      //     this.showerror1 = true
      //   }
      // })
    },
    setStep3() {
      // this.$refs.step2.validate().then(success => {
      //   if (success) {
      this.area_2_visible = false;
      this.area_1_visible = false;
      this.area_3_visible = !this.area_3_visible
      //this.showerror2 = false
      //   } else {
      //     this.showerror2 = true
      //   }
      // })
    },
    setStep3Open() {
      // this.$refs.step1.validate().then(success=>{
      //   if(success){
      //     this.$refs.step2.validate().then(success=>{
      //       if(success){
      this.area_3_visible = !this.area_3_visible;
      this.area_1_visible = false;
      this.area_2_visible = false
      //       }else{
      //         this.showerror2 = true
      //       }
      //     })
      //   }else{
      //     this.showerror1 = true
      //   }
      // })
    },
    setDealerCountry(event) {
      this.current.country_id = event.id
      this.$store.dispatch('city/getCities', event.id)
      this.current.city_id = null
      this.current.district_id = null
      this.current.neighborhood_id = null
    },
    setDealerCity(event) {
      this.current.city_id = event.id
      this.current.district_id = null
      this.current.neighborhood_id = null
      if (event.id) {
        this.$store.dispatch('district/getDistricts', event.id)
        this.$store.commit('street/setList', [])
        this.$store.commit('city/setSelected', event)
      } else {
        this.$store.commit('district/setList', [])
      }
    },
    setDealerDistrict(event) {
      this.current.district_id = event.id
      this.current.neighborhood_id = null
      if (event.id) {
        this.$store.dispatch('street/getStreets', event.id)
        this.$store.commit('district/setSelected', event)
      } else {
        this.$store.commit('street/setList', [])
      }
    },
    setDealerStreet(event) {
      if (event.id) {
        this.current.neighborhood_id = event.id
        this.$store.commit('street/setSelected', event)
      }
    },
    addDealer() {
      if(!this.current.payment_types) {
        this.payment_error = true
      }
      this.$refs.step1.validate().then(success => {
        if (success) {
          this.showerror1 = false
          this.$refs.step2.validate().then(success => {
            if (success) {
              this.showerror2 = false
              this.$refs.step3.validate().then(success => {
                if (success) {
                  if (this.current.country_id && this.current.city_id && this.current.district_id) {
                    var cp1 = this.current_phone.replace(/\s+/g, '');
                    var cp2 = cp1.replace(/[()]/g, '')
                    var ap1 = this.authorized_phone.replace(/\s+/g, '');
                    var ap2 = ap1.replace(/[()]/g, '')
                    this.showerror3 = false
                    axiosInstance.post('/v1/customers/dealers', {
                      ref_id: this.ref_id,
                      ref_code: this.ref_code,
                      dealer_ref_no: this.dealer_no,
                      name: this.current_name,
                      dept_amount: this.debt,
                      credit_amount: this.lend,
                      balance_amount: this.balance,
                      discount_rate: this.show_default_discount_rate ? null : (this.current.discount_rate ? this.current.discount_rate : 0),
                      phone: cp2,
                      fax: this.current.fax,
                      tax_office: this.current_tax_office,
                      tax_number: this.current_tax_number,
                      title: this.current_title,
                      email: this.current_email,
                      mersis: this.current.mersis_number,
                      payment_term: this.payment_term ? parseInt(this.payment_term) : 0,
                      risk_limit: this.risk_limit,
                      installment_count: this.current.installment_count,
                      term_installment_count : this.current.term_installment_count,
                      list_price_type: this.current.list_price_type,
                      payment_types: this.current.payment_types,
                      salesman_id: this.current.salesman_id,
                      user: {
                        name: this.current_authorized_name,
                        surname: this.current_authorized_surname,
                        email: this.authorized_email,
                        password: this.current.password
                      },
                      contact: {
                        name: this.current_authorized_name,
                        surname: this.current_authorized_surname,
                        title: this.current.position,
                        phone: ap2,
                        email: this.authorized_email
                      },
                      address: {
                        country_id: this.current.country_id,
                        city_id: this.current.city_id,
                        district_id: this.current.district_id,
                        neighborhood_id: this.current.neighborhood_id,
                        address: this.dealer_address,
                        title: this.address_title,
                        phone: cp2
                      }
                    }).then(response => {
                      if (response.data.status) {
                        this.$refs["add-current-form"].hide()
                        this.$swal({
                          iconHtml: '<img src="/images/toast/success.svg" width="32" height="32">',
                          toast: true,
                          position: 'top-end',
                          showConfirmButton: false,
                          timer: 3000,
                          title: this.$i18n.t('Success') + ' !',
                          text: this.$i18n.t('Current successfully saved.'),
                          customClass: {
                            icon: 'swal2-no-border'
                          }
                        })
                        this.search_text = ''
                        this.current = {
                          discount_rate: this.default_discount_rate
                        }
                        this.area_1_visible = true
                        this.area_2_visible = false
                        this.area_3_visible = false
                        this.is_user = false
                        this.password_type = true
                        this.password_confirmation_type = true
                        this.showerror1 = false
                        this.showerror2 = false
                        this.showerror3 = false
                        this.focused_title = false
                        this.address_title = null
                        this.current_title = null
                        this.authorized_email = null
                        this.authorized_phone = ''
                        this.current_phone = ''
                        this.current_tax_office = ''
                        this.current_tax_number = ''
                        this.current_authorized_name = ''
                        this.current_authorized_surname = ''
                        this.dealer_country = ''
                        this.dealer_city = ''
                        this.dealer_district = ''
                        this.dealer_address = ''
                        this.current_name = ''
                        this.is_dealer_add = false
                        this.ref_code = null
                        this.ref_id = null
                        this.dealer_no = null
                        this.current_email = ''
                        this.dealer_street = ''
                        this.risk_limit = 0
                        this.payment_term = 0
                        this.debt = 0
                        this.lend = 0
                        this.balance = 0
                        this.risk_limit_edit = false
                        this.debt_edit = false
                        this.lend_edit = false
                        this.balance_edit = false
                        this.show_default_discount_rate = true
                        this.getList()
                      }
                    }).catch(error => {
                      this.$swal({
                        iconHtml: '<img src="/images/toast/error.svg" width="32" height="32">',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        title: this.$i18n.t('Error') + ' !',
                        text: error.response.data.statusMessage,
                        customClass: {
                          icon: 'swal2-no-border'
                        }
                      })
                    })
                  } else {
                    this.$store.commit('city/setSelected', null)
                    this.$store.commit('district/setSelected', null)
                    //this.$store.commit('street/setSelected', null)
                  }
                } else {
                  this.showerror3 = true
                }
              })
            } else {
              this.showerror2 = true
            }
          })
        } else {
          this.showerror1 = true
          this.$refs.step2.validate().then(success => {
            if (success) {
              this.showerror2 = false
              this.$refs.step3.validate().then(success => {
                if (success) {

                } else {
                  this.showerror3 = true
                }
                if (!this.current.city_id && !this.current.district_id) {
                  this.$store.commit('city/setSelected', null)
                  this.$store.commit('district/setSelected', null)
                  //this.$store.commit('street/setSelected', null)
                }
              })
            } else {
              this.showerror2 = true
              this.$refs.step3.validate().then(success => {
                if (success) {
                  if (!this.current.city_id && !this.current.district_id) {
                    this.showerror3 = true
                    this.$store.commit('city/setSelected', null)
                    this.$store.commit('district/setSelected', null)
                    //this.$store.commit('street/setSelected', null)
                  } else {
                    this.showerror3 = false
                  }
                } else {
                  this.showerror3 = true
                  if (!this.current.city_id && !this.current.district_id) {
                    this.$store.commit('city/setSelected', null)
                    this.$store.commit('district/setSelected', null)
                    //this.$store.commit('street/setSelected', null)
                  }
                }
              })
            }
          })
        }
      })
    },
    setDealer(event) {
      if (event) {
        this.is_dealer_add = true
      } else {
        this.is_dealer_add = false
      }
      this.ref_code = event.dealer_code
      this.ref_id = event.ref_id
      this.dealer_no = event.dealer_no
      this.current_phone = ''
      this.authorized_phone = ''
      if(event.phone && event.phone.length>0){
        if (event.phone && event.phone.length < 10) {
          this.current_phone = '0000' + event.phone

          this.authorized_phone = '0000' + event.phone
        } else {
          if(event.phone.substring(0,1) === '0'){
            this.current_phone = event.phone
            this.authorized_phone = event.phone
          }else{
            this.current_phone = '0'+event.phone
            this.authorized_phone = '0'+event.phone
          }
        }
      }
      this.current_tax_office = event.dealer_tax_office
      this.current_tax_number = event.dealer_tax_number
      if (event.user_name) {
        this.current_authorized_name = event.user_name
      } else {
        this.current_authorized_name = event.contact_name
      }
      if (event.user_surname) {
        this.current_authorized_surname = event.user_surname
      }
      this.authorized_email = event.contact_email

      this.current_title = event.dealer_title
      if (event.dealer_title) {
        var seperated = event.dealer_title.split(' ')
        this.address_title = seperated[0]
        this.current_name = seperated[0]
        if (seperated.length > 1) {
          this.address_title = seperated[0] + ' ' + seperated[1]
          this.current_name = seperated[0] + ' ' + seperated[1]
        }
      }
      this.dealer_country = event.dealer_address_country
      this.dealer_city = event.dealer_address_city
      this.dealer_district = event.town
      this.dealer_address = event.dealer_address_description
      this.current_email = event.contact_email
      if (event.town) {
        this.dealer_street = event.dealer_address_district
      }

      this.risk_limit = 0
      this.debt = 0
      this.lend = 0
      this.balance = 0
      this.risk_limit_edit = false
      this.debt_edit = false
      this.lend_edit = false
      this.balance_edit = false

      axiosInstance.get(`/v1/customers/integration/erp/risks?filter[dealer_code]=${event.dealer_code}`).then(response => {
        if (response.data.status) {
          if (response.data.responseData.integrationDealer) {
            this.risk_limit = response.data.responseData.integrationDealer.riskLimit
            this.risk_limit_edit = true
            this.debt = response.data.responseData.integrationDealer.deptAmount
            this.debt_edit = true
            this.lend = response.data.responseData.integrationDealer.creditAmount
            this.lend_edit = true
            this.balance = response.data.responseData.integrationDealer.balanceAmount
            this.balance_edit = true
          }
        }
      }).catch(error=>{
        this.$swal({
          iconHtml: '<img src="/images/toast/error.svg" width="32" height="32">',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          title: this.$i18n.t('Error') + ' !',
          text: this.$i18n.t('Current balance information could not be retrieved!'),
          customClass: {
            icon: 'swal2-no-border'
          }
        })
      })
    }
  }
}

</script>


<style scoped>
.warning-area {
  font-family: Inter;
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #FF4343;
  background-color: #FF43431F;
  padding: 2px 6px;
  border-radius: 4px;
}

.table-content {
  padding: 30px;
  margin-top: 30px;
}

.trash-icon img {
  width: 12px;
  height: auto;
}

.product-image img {
  width: 100%;
}

.filters {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.search-area input, .search-area input:focus {
  background-color: transparent !important;
  min-width: 300px !important;
  font-size: 14px !important;
  border: none !important;
}

.search-area input::placeholder {
  color: v-bind('color');
}

.category-badge {
  padding: 5px 20px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 10px;
  font-family: Montserrat;
  white-space: nowrap;
}

.active-badge {
  color: #28C76F;
  background-color: #28C76F1F;
}

.approve-badge {
  color: #FF9F43;
  background-color: #FF9F431F;
}

.product-badge {
  background-color: #07C7E31F;
  color: #07C7E3;
}

.table-action span {
  font-weight: 500;
}

.filter-input {
  display: flex;
  border: 1px solid v-bind('color');
  align-items: center;
  border-radius: 5px;
  padding: 4px 16px;
  color: v-bind('color');
  min-width: 300px;
}

.filter-input input, .filter-input input:focus {
  color: v-bind('color');
  padding-left: 0 !important;
  font-size: 14px;
  border: none !important;
}

.add-current {
  background-color: v-bind('color');
  color: #f4f4f4;
  display: flex;
  text-align: center;
  align-items: center;
  border-radius: 5px;
  font-size: 14px;
  padding: 10px;
  cursor: pointer;
  gap: .5rem;
}

.tab-item {
  padding: 10px 16px;
  color: #323338;
  border: 1px solid #6D6E6F80;
  border-radius: 200px;
  cursor: pointer;
  transition: .3s;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  letter-spacing: 0.4000000059604645px;
  text-align: center;
  margin-right: 10px;

}

.product-count-badge {
  font-size: 11px;
  border-radius: 10px;
  background-color: #e8e8e8;
  color: #6d6e6f;
  padding: 4px 7px;
  margin-left: 5px;
}

.active-tab .product-count-badge {
  background-color: #f4f4f4;
  color: v-bind('color');
}

.active-tab, .tab-item:hover {
  color: #f4f4f4;
  border: 1px solid v-bind('color');
  background-color: v-bind('color');
}

td span {
  color: #777777E5;
  font-size: 13px;
  font-family: Inter;
}

.current {
  margin-top: 24px;
  cursor: pointer;
}

.current .number {
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  line-height: 40px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6D6E6F33;
  color: #777777E5;
  border-radius: 100%;
}

.current i {
  color: #777777E5;
  font-size: 16px;
  cursor: pointer;
}

.current .fa-check-circle {
  font-size: 23px;
}

.current .collapse-title {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  color: #323338;
}

.save-button {
  padding: 10px 30px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: center;
  border-radius: 5px;
  margin-left: .5rem;
  transition: .3s all;
  color: #fff;
}

.font-small {
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: Inter !important;
  color: #323338 !important;
}

.select-label label {
  color: #777777E5;
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
}

::placeholder {
  color: v-bind('color') !important;
}
.danger{
  background-color: #EF212229;
  color: #EF2122;
}
.custom-control.custom-checkbox{
  white-space: nowrap;
}
</style>

<style>
#area-2 .custom-control-label {
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: Inter !important;
  color: #323338 !important;
}
</style>
