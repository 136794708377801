<template>
  <svg width="29" height="19" viewBox="0 0 29 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.4067 0.364258H0.96667C0.432746 0.364258 0 0.797004 0 1.33093V15.435C0 15.9686 0.432746 16.4016 0.96667 16.4016H2.40572C2.40572 14.7805 3.72458 13.4614 5.34569 13.4614C6.96679 13.4614 8.28566 14.7805 8.28566 16.4016H17.3733V1.33093C17.3733 0.797004 16.9406 0.364258 16.4067 0.364258Z" fill="#1C1C1C"/>
    <path d="M28.7986 9.46809L23.8557 3.28913C23.7442 3.15025 23.5102 3.03748 23.3321 3.03748H18.3086V16.402H19.3777C19.3777 14.7809 20.6963 13.4617 22.3174 13.4617C23.9385 13.4617 25.2573 14.7809 25.2573 16.402H28.0333C28.5672 16.402 29 15.9689 29 15.4353V10.042C29 9.8641 28.9101 9.60697 28.7986 9.46809ZM20.046 9.18518V4.37406H22.7189L26.5678 9.18518H20.046Z" fill="#1C1C1C"/>
    <path d="M5.34517 13.9935C4.0189 13.9935 2.93945 15.0726 2.93945 16.3989C2.93945 17.7248 4.0189 18.8046 5.34517 18.8046C6.67177 18.8046 7.75089 17.7255 7.75089 16.3989C7.75089 15.0723 6.67177 13.9935 5.34517 13.9935Z" :fill="color"/>
    <path d="M22.3182 13.9935C20.9916 13.9935 19.9121 15.0726 19.9121 16.3989C19.9121 17.7248 20.9916 18.8046 22.3182 18.8046C23.6441 18.8046 24.7236 17.7255 24.7236 16.3989C24.7236 15.0723 23.6441 13.9935 22.3182 13.9935Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: "VAN",
  computed:{
    color(){
      return localStorage.getItem('color')
    }
  }
}
</script>

<style scoped>

</style>
