(function (global, factory) {
	typeof exports === 'object' && typeof module !== 'undefined' ? factory(exports) :
		typeof define === 'function' && define.amd ? define(['exports'], factory) :
			(global = typeof globalThis !== 'undefined' ? globalThis : global || self, factory(global.tr = {}));
}(this, (function (exports) { 'use strict';

	var fp = typeof window !== "undefined" && window.flatpickr !== undefined
		? window.flatpickr
		: {
			l10ns: {},
		};
	var English = {
		weekdays: {
			shorthand: ['Sun', "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
			longhand: [
				'Sunday',
				"Monday",
				"Tuesday",
				"Wednesday",
				"Thursday",
				"Friday",
				"Saturday",
			],
		},
		months: {
			shorthand: [
				"Jan",
				"Feb",
				"Mar",
				"Apr",
				"May",
				"Jun",
				"Jul",
				"Aug",
				"Sep",
				"Oct",
				"Nov",
				"Dec",
			],
			longhand: [
				"January",
				"February",
				"March",
				"April",
				"May",
				"June",
				"July",
				"August",
				"September",
				"October",
				"November",
				"December",
			],
		},
		firstDayOfWeek: 1,
		ordinal: function () {
			return ".";
		},
		rangeSeparator: " - ",
		weekAbbreviation: "Ww",
		scrollTitle: "Scroll to increase",
		toggleTitle: "On/Off",
		amPM: ["AM", "PM"],
		time_24hr: true,
	};
	fp.l10ns.en = English;
	var en = fp.l10ns;

	exports.English = English;
	exports.default = en;

	Object.defineProperty(exports, '__esModule', { value: true });

})));
