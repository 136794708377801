<template>
  <Default>
    <div class="page-content">
      <ul id="breadcrumb-scroll">
        <li>
          <a href="/" itemprop="item"><span itemprop="name">{{ $t('Summary') }}</span></a>
        </li>
        <li>
          <a href="/currents" itemprop="item"><span itemprop="name">{{ $t('Currents') }}</span></a>
        </li>
        <li>
          <span class="active">
               {{ $t('Users') }}
          </span>
        </li>
      </ul>
      <b-tabs content-class="mt-3" id="dealer-tab" v-if="!loader">
        <b-tab :title="$t('Basic Informations')" :active="tab === 1">
          <div class="order-tab-content row px-3">
            <div class="col-sm-12 col-md-12 col-lg-12 neu card-content">
              <div class="tab-content">
                <!--                <div class="tab-content-card first-card bg-card">-->
                <!--                  <div class="left">-->
                <!--                    <div class="img">-->
                <!--                      <img src="https://cdn.servislet.com/content/xDRcAvIGrWFMEZUyDZaNIrGqch1rwsTiQBFrtjy5.png" alt="">-->
                <!--                    </div>-->
                <!--                    <div class="d-flex flex-column align-items-start">-->
                <!--                      <div class="name">{{ dealer.name }}</div>-->
                <!--                      <div class="email">akbak@gmail.com</div>-->
                <!--                    </div>-->

                <!--                  </div>-->
                <!--                  <button class="edit-btn">-->
                <!--                    <img src="/images/icons/edit.png" alt="" width="14">-->
                <!--                    {{ $t('Edit') }}-->
                <!--                  </button>-->
                <!--                </div>-->

                <div class="tab-content-card bg-card">
                  <div class="space-between">
                    <div class="title">
                      {{ $t('Basic Informations') }}
                    </div>
                    <button class="edit-btn" v-if="!edit" @click="edit = true;">
                      <img src="/images/icons/edit.png" alt="" width="14">
                      {{ $t('Edit') }}
                    </button>
                    <button class="edit-btn" v-else style="opacity: 0;">
                      <img src="/images/icons/edit.png" alt="" width="14">
                      {{ $t('Edit') }}
                    </button>
                  </div>

                  <div class="row" v-if="!edit">
                    <div class="col-sm-12 col-md-5 col-lg-5 mt-4">
                      <div class="space-between bottom-border">
                        <span class="key">{{ $t('Current Name') }}</span>
                        <span class="value">{{ dealer.name ? dealer.name : '-' }}</span>
                      </div>
                      <div class="space-between bottom-border">
                        <span class="key">{{ $t('E-Mail') }}</span>
                        <span class="value">{{ dealer.email ? dealer.email : '-' }}</span>
                      </div>
                      <div class="space-between bottom-border">
                        <span class="key">{{ $t('Tax Office') }}</span>
                        <span class="value">{{ dealer.tax_office ? dealer.tax_office : '-' }}</span>
                      </div>
                      <div class="space-between bottom-border">
                        <span class="key">{{ $t('Mersis Number') }}</span>
                        <span class="value">{{ dealer.mersis ? dealer.mersis : '-' }}</span>
                      </div>
                      <div class="space-between bottom-border">
                        <span class="key">{{ $t('Discount Rate') }}</span>
                        <span class="value">{{ dealer.discount_rate ? dealer.discount_rate : '-' }}</span>
                      </div>
                      <div class="space-between bottom-border">
                        <span class="key">{{ $t('Lend') }}</span>
                        <span class="value">{{
                            dealer.credit_amount ? currenctFormat('tr-TR', dealer.credit_amount) : '-'
                          }}</span>
                      </div>
                      <div class="space-between bottom-border">
                        <span class="key">{{ $t('Risk Limit') }}</span>
                        <span class="value">{{
                            dealer.risk_limit ? currenctFormat('tr-TR', dealer.risk_limit) : '-'
                          }}</span>
                      </div>
                      <div class="space-between bottom-border">
                        <span class="key">{{ $t('Max. Installment Count') }}</span>
                        <span class="value">{{ dealer.installment_count ? dealer.installment_count : '-' }}</span>
                      </div>
                      <div class="space-between bottom-border">
                        <span class="key">{{ $t('Price Display Type') }}</span>
                        <span class="value">{{ dealer.list_price_type ? dealer.list_price_type : '-' }}</span>
                      </div>
                      <div class="space-between bottom-border">
                        <span class="key">{{ $t('Placier') }}</span>
                        <span class="value">{{ dealer.salesman ? dealer.salesman.name : '-' }}</span>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-2 col-lg-2 mt-4">
                    </div>
                    <div class="col-sm-12 col-md-5 col-lg-5 mt-4">
                      <div class="space-between bottom-border">
                        <span class="key">{{ $t('Company Title') }}</span>
                        <span class="value">{{ dealer.title ? dealer.title : '-' }}</span>
                      </div>
                      <div class="space-between bottom-border">
                        <span class="key">{{ $t('Phone') }}</span>
                        <span class="value">
                          {{ dealer.phone ? dealer.phone : '-' }}</span>
                      </div>
                      <div class="space-between bottom-border">
                        <span class="key">{{ $t('Tax Number') }}</span>
                        <span class="value">{{ dealer.tax_number ? dealer.tax_number : '-' }}</span>
                      </div>
                      <div class="space-between bottom-border">
                        <span class="key">{{ $t('Fax') }}</span>
                        <span class="value">{{ dealer.fax ? dealer.fax : '-' }}</span>
                      </div>
                      <div class="space-between bottom-border">
                        <span class="key">{{ $t('Debt') }}</span>
                        <span class="value">{{
                            dealer.dept_amount ? currenctFormat('tr-TR', dealer.dept_amount) : '-'
                          }}</span>
                      </div>
                      <div class="space-between bottom-border">
                        <span class="key">{{ $t('Balance') }}</span>
                        <span class="value">{{
                            dealer.balance_amount ? currenctFormat('tr-TR', dealer.balance_amount) : '-'
                          }}</span>
                      </div>
                      <div class="space-between bottom-border">
                        <span class="key">{{ $t('Payment Term') }}</span>
                        <span class="value">{{ dealer.payment_term ? dealer.payment_term : '-' }}</span>
                      </div>
                      <div class="space-between bottom-border">
                        <span class="key">{{ $t('Installment Limitation') }}</span>
                        <span class="value">{{
                            dealer.term_installment_count ? dealer.term_installment_count : '-'
                          }}</span>
                      </div>
                      <div class="space-between bottom-border">
                        <span class="key">{{ $t('Payment Methods') }}</span>
                        <span class="value">
                          <span v-if="dealer.paymentTypes.length === 1">{{dealer.paymentTypes[0].name}}</span>
                          <span v-if="dealer.paymentTypes.length >1">
                             <span v-for="(i,key) in dealer.paymentTypes" :key="i.$index" v-if="key< dealer.paymentTypes.length-1">{{i.name}}, </span>
                             <span v-if="dealer.paymentTypes.length>1">{{dealer.paymentTypes[dealer.paymentTypes.length-1].name}}</span>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div v-else>
                    <ValidationObserver ref="basicRules">
                      <div class="row">

                        <div class="col-sm-12 col-md-6 col-lg-6 mt-4">
                          <div class="form-white">
                            <ValidationProvider :name="$t('Current Name')" rules="required|max:100" v-slot="{ errors }">
                              <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                                <b-form-input v-model="name" class="nonrequired-input"
                                              :class="errors.length>0 ? 'dangered-border' : ''" required
                                              autocomplete="off"/>
                                <label for="name" class="">{{ $t('Current Name') }} <span class="small"
                                                                                          :class="errors.length>0 ? 'text-danger' : ''">*</span></label>
                              </div>
                              <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                              <span class="error-text opacity-0" v-else>*</span>
                            </ValidationProvider>
                          </div>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6 mt-4">
                          <div class="form-white">
                            <ValidationProvider :name="$t('Company Title')" rules="max:100" v-slot="{ errors }">
                              <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                                <b-form-input v-model="title" class="form-control nonrequired-input pl-21" required
                                       autocomplete="off" />
                                <label for="name-field">{{ $t('Company Title') }}</label>
                              </div>
                              <small class="text-danger" v-if="errors.length>0">* {{ errors[0] }}.</small>
                              <span class="error-text opacity-0" v-else>*</span>
                            </ValidationProvider>
                          </div>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                          <div class="form-white">
                            <ValidationProvider :name="$t('E-Mail')" rules="email|max:60" v-slot="{ errors }">
                              <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                                <b-form-input type="email" v-model="email" class="nonrequired-input pl-21"
                                       required autocomplete="off" />
                                <label for="name-field">{{ $t('E-Mail') }}</label>
                              </div>
                              <small class="text-danger" v-if="errors.length>0">* {{ errors[0] }}.</small>
                              <small class="opacity-0" v-else>*</small>
                            </ValidationProvider>
                          </div>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                          <div class="form-white">
                            <ValidationProvider :name="$t('Phone')" rules="min:19|max:19" v-slot="{ errors }">
                              <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                                <input v-model="phone" v-mask="'+9# (###) ### ## ##'"
                                       class="form-control nonrequired-input pl-21" required autocomplete="off">
                                <label for="name-field">{{ $t('Phone') }}</label>
                              </div>
                              <small class="text-danger" v-if="errors.length>0">* {{ errors[0] }}.</small>
                              <small class="opacity-0" v-else>*</small>
                            </ValidationProvider>
                          </div>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                          <div class="form-white">
                            <ValidationProvider :name="$t('Tax Office')" rules="max:100" v-slot="{ errors }">
                              <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                                <b-form-input v-model="tax_office" class="nonrequired-input pl-21" required
                                       autocomplete="off" />
                                <label for="name-field">{{ $t('Tax Office') }}</label>
                              </div>
                              <small class="text-danger" v-if="errors.length>0">* {{ errors[0] }}.</small>
                              <small class="opacity-0" v-else>*</small>
                            </ValidationProvider>
                          </div>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                          <div class="form-white">
                            <ValidationProvider :name="$t('Tax Number')" rules="max:11" v-slot="{ errors }">
                              <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                                <b-form-input type="number" v-model="tax_number" class="nonrequired-input pl-21"
                                       required autocomplete="off" />
                                <label for="name-field">{{ $t('Tax Number') }}</label>
                              </div>
                              <small class="text-danger" v-if="errors.length>0">* {{ errors[0] }}.</small>
                              <small class="opacity-0" v-else>*</small>
                            </ValidationProvider>
                          </div>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                          <div class="form-white">
                            <ValidationProvider :name="$t('Mersis Number')" rules="max:20" v-slot="{ errors }">
                              <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                                <b-form-input type="number" v-model="mersis" class="nonrequired-input pl-21"
                                       required autocomplete="off" />
                                <label for="mersis">{{ $t('Mersis Number') }}</label>
                              </div>
                              <small class="text-danger" v-if="errors.length>0">* {{ errors[0] }}.</small>
                              <small class="opacity-0" v-else>*</small>
                            </ValidationProvider>
                          </div>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                          <div class="form-white">
                            <ValidationProvider :name="$t('Fax')" rules="min:19|max:19" v-slot="{ errors }">
                              <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                                <i class="fa-solid fa-phone form-icon"></i>
                                <b-form-input v-model="fax" class="nonrequired-input pl-32"
                                              :class="errors.length>0 ? 'dangered-border' : ''"
                                              v-mask="'+90 (###) ### ## ##'"
                                              required autocomplete="off" id="input1"/>
                                <label for="fax" class="pl-21">{{ $t('Fax') }}</label>
                              </div>
                              <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                              <span class="error-text opacity-0" v-else>*</span>
                            </ValidationProvider>
                          </div>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                          <div class="form-white">
                            <div class="form-group input-material">
                              <b-form-input v-model="discount_rate" v-mask="'% ###'" class="nonrequired-input"
                                            required autocomplete="off"/>
                              <label for="tax_number" class="">{{ $t('Discount Rate') }}</label>
                            </div>
                            <span class="error-text opacity-0">*</span>
                          </div>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                          <div class="form-white">
                            <div class="form-group input-material">
                              <money v-model="debt" class="form-control has-append-icon nonrequired-input" required
                                     autocomplete="off" :disabled="debt_edit"/>
                              <i class="fa-solid form-icon-append cursor-pointer"
                                 :class="debt_edit ? 'fa-lock' : 'fa-lock-open'" @click="debt_edit = !debt_edit"></i>

                              <label for="tax_number" class="">{{ $t('Debt') }}</label>
                            </div>
                            <span class="error-text opacity-0">*</span>
                          </div>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                          <div class="form-white">
                            <div class="form-group input-material">
                              <money v-model="lend" class="form-control has-append-icon nonrequired-input"
                                     required autocomplete="off" :disabled="lend_edit"/>
                              <i class="fa-solid form-icon-append cursor-pointer"
                                 :class="lend_edit ? 'fa-lock' : 'fa-lock-open'" @click="lend_edit = !lend_edit"></i>

                              <label for="tax_number" class="">{{ $t('Lend') }}</label>
                            </div>
                            <span class="error-text opacity-0">*</span>
                          </div>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                          <div class="form-white">
                            <div class="form-group input-material">
                              <money v-model="balance" class="form-control nonrequired-input has-append-icon" required
                                     autocomplete="off" :disabled="balance_edit"/>

                              <i class="fa-solid form-icon-append cursor-pointer"
                                 :class="balance_edit ? 'fa-lock' : 'fa-lock-open'"
                                 @click="balance_edit = !balance_edit"></i>

                              <label for="tax_number" class="">{{ $t('Balance') }}</label>
                            </div>
                            <span class="error-text opacity-0">*</span>
                          </div>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                          <div class="form-white">
                            <div class="form-group input-material">
                              <money v-model="risk_limit" class="form-control has-append-icon nonrequired-input"
                                     required
                                     autocomplete="off" :disabled="risk_limit_edit"/>
                              <i class="fa-solid form-icon-append cursor-pointer"
                                 :class="risk_limit_edit ? 'fa-lock' : 'fa-lock-open'"
                                 @click="risk_limit_edit = !risk_limit_edit"></i>
                              <label for="tax_number" class="">{{ $t('Risk Limit') }}</label>
                            </div>
                            <span class="error-text opacity-0">*</span>
                          </div>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                          <div class="form-white">
                            <div class="form-group input-material">
                              <b-form-input type="number" v-model="payment_term" class="nonrequired-input pl-21"
                                     required autocomplete="off" />
                              <label for="name-field">{{ $t('Payment Term') }}</label>
                            </div>
                            <small class="text-danger opacity-0">*</small>
                          </div>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                          <div class="form-white">
                            <div class="form-group input-material">
                              <b-form-input v-model="installment_count" class="nonrequired-input" type="number"
                                            required
                                            autocomplete="off"/>
                              <i class="fa-solid fa-circle-info form-icon-append cursor-pointer" v-b-tooltip.hover
                                 :title="$t('Max. number of installments according to the number of installments available in the payment method offered')"></i>
                              <label for="payment_term" class="">{{ $t('Max. Installment Count') }}</label>
                            </div>
                            <span class="error-text opacity-0">*</span>
                          </div>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                          <div class="form-white">
                            <div class="form-group input-material">
                              <b-form-input v-model="term_installment_count" class="nonrequired-input" type="number"
                                            required autocomplete="off"/>
                              <i class="fa-solid fa-circle-info form-icon-append cursor-pointer" v-b-tooltip.hover
                                 :title="$t('How many installments should be offered to current customers with overdue orders?')"></i>

                              <label for="payment_term" class="">{{ $t('Installment Limitation') }}</label>
                            </div>
                            <span class="error-text opacity-0">*</span>
                          </div>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6 select-label">
                          <label for="country">{{ $t('Price Display Type') }}</label>
                          <price-type @setType="dealer.list_price_type = $event.value"
                                      :selected="dealer.list_price_type"/>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6 select-label">
                          <label for="country">{{ $t('Payment Methods') }} *</label>
                          <multiple-payment-type @setPaymentType="setPaymentType($event)" :error="payment_error" :selecteds="dealer.paymentTypes"/>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6 select-label">
                          <label for="placier">{{ $t('Placier') }}</label>
                          <placier-select @setPlacier="dealer.salesman_id = $event.id" :id="dealer.salesman ? dealer.salesman.id : 0"/>
                        </div>

<!--                        <div class="col-sm-12 col-md-6 col-lg-6"/>-->

                        <div class="col-sm-12 col-md-6 col-lg-6 mt-4">
                          <div class="d-flex justify-content-end">
                            <button class="modal-footer-button cancel-button" @click="edit = false">{{
                                $t('Cancel')
                              }}
                            </button>
                            <button class="modal-footer-button save-button dealer-bg dealer-border" @click="updateBasicInfo()">{{
                                $t('Save')
                              }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </ValidationObserver>
                  </div>
                </div>

                <div class="tab-content-card bg-card">
                  <div class="space-between">
                    <div class="title">
                      {{ $t('Address Informations') }}
                    </div>
                    <button class="edit-btn" v-b-modal.add-address-form>
                      <i class="fa-solid fa-plus"></i>
                      {{ $t('Add Address') }}
                    </button>
                  </div>

                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-6 mt-4" v-for="i in addresses" :key="i.id"
                         v-if="!$store.state.address.loader">
                      <div class="address-item" :class="i.is_default === true ? 'active' : ''">
                        <div class="space-between">
                          <div class="d-flex align-items-center" style="gap: .5rem;">
                            <i class="fa-solid fa-check-circle dealer-text" v-if="i.is_default"></i>
                            <i class="fa-regular fa-circle" v-else></i>
                            <AddressType :color="color"/>
                            <span class="address-name">{{ i.title }}</span>
                            <span class="default-badge" v-if="i === 1">{{ $t('Default') }}</span>
                          </div>
                          <span class="edit-text" @click="openAddressUpdateModal(i)">{{ $t('Edit') }}</span>
                        </div>

                        <div>
                          {{ i.neighborhood_name }} {{ i.address }} {{ i.district_name }} / {{ i.city_name }}
                        </div>
                        <div>
                          {{ i.phone }}
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-12 col-lg-6 mt-4" v-for="i in 4" :key="i" v-else>
                      <div class="address-item">
                        <b-skeleton v-for="i in 5" :key="i.$index" width="100%"></b-skeleton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab :title="$t('Users')" @click="getDealerUsers()" :active="tab === 2">
          <div class="order-tab-content" v-if="!dealer_user_loader">
            <div class="table-content product-listing-table neu col-sm-12 col-lg-12">
              <div class="table-title">{{ $t('Users') }}</div>
              <div class="table-desc">{{ $t('Panel kullanıcılarını ve hesap izinlerini burada yönetin.') }}</div>

              <div>
                <hr>
              </div>


              <div class="filters">
                <div></div>
<!--                <div class="filter-input">-->
<!--                  <b-form-input :placeholder="$t('Search by name or email')"/>-->
<!--                  <i class="fa-solid fa-filter"></i>-->
<!--                </div>-->

                <span class="add-current" v-b-modal.add-current-user-form>
                    <i class="fa-solid fa-plus"></i>
                    {{ $t('Add User') }}
                  </span>
              </div>
              <div class="table-responsive">
                <table id="productTable" class="w-100 no-last">
                  <thead>
                  <tr class="product-listing-table-head">
                    <!--                    <th>-->
                    <!--                      <label class="product-listing-checkbox-label">-->
                    <!--                        <input type="checkbox">-->
                    <!--                        <span class="checkmark"></span>-->
                    <!--                      </label>-->
                    <!--                    </th>-->
                    <th>
                      {{ $t('Name Surname') }}
                    </th>
                    <th>
                      {{ $t('E-Mail') }}
                    </th>
                    <th class="text-center">
                      {{ $t('Phone') }}
                    </th>
                    <th class="text-center">
                      {{ $t('Access') }}
                    </th>
                    <th class="text-center">
                      {{ $t('Date Added') }}
                    </th>
                    <th class="text-center">
                      {{ $t('Last Active') }}
                    </th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item,key) in dealer_users" :key="item.$index">
                    <!--                    <td>-->
                    <!--                      <b-form-checkbox name="check-button"/>-->
                    <!--                    </td>-->
                    <td>
                      {{ item.name }} {{ item.surname }}
                    </td>
                    <td>
                      {{ item.email ?? '-' }}
                    </td>
                    <td class="text-center">
                      {{ item.gsm ?? '-' }}
                    </td>
                    <td class="text-center">
                      <span v-if="item.is_active" class="category-badge admin-badge">{{ $t('Active') }}</span>
                      <span v-else class="category-badge user-badge">{{ $t('Passive') }}</span>
                    </td>
                    <td class="text-center">
                      {{ dateFormat(item.created_at) }}
                    </td>
                    <td class="text-center">
                      -
                    </td>
                    <td>
                      <div class="d-flex justify-content-end">
                        <b-dropdown id="actions" class="m-md-2" no-caret>
                          <template #button-content>
                            {{ $t('Actions') }} <i class="fa-solid fa-chevron-down"></i>
                          </template>
                          <b-dropdown-item @click="getUserDetail(item)">{{ $t('Edit') }}</b-dropdown-item>
<!--                          <b-dropdown-item @click="$router.push(`/currents`)">{{ $t('Authorizations') }}-->
<!--                          </b-dropdown-item>-->
<!--                          <b-dropdown-item @click="$router.push(`/currents`)">{{ $t('Archive') }}-->
<!--                          </b-dropdown-item>-->
                          <b-dropdown-item @click="deleteUser(item)">{{ $t('Delete') }}
                          </b-dropdown-item>
                          <!--                      <b-dropdown-item>{{$t('Invoice')}}</b-dropdown-item>-->
                          <!--                      <b-dropdown-item>{{$t('Waybill')}}</b-dropdown-item>-->
                        </b-dropdown>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <hr>

              <div class="d-flex justify-content-between pagination-count align-items-center">
                <span>
                  <span class="pagination-text">{{ $t('Show by per page:') }} &nbsp;</span>
                  <select name="pagination" id="pagination-select" @change="getDealerUsers()" v-model="size">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </span>
                <span class="d-flex align-items-center" style="gap: 1rem;">
                   <span>
             <span class="fw-bolder">{{  dealer_users_pagination.total }}</span> kişiden
                  <span
                      class="fw-bolder">{{
                      dealer_users_pagination.current_page === 1 ? 1 : ((dealer_users_pagination.current_page - 1) * dealer_users_pagination.per_page) + 1
                    }} - {{
                      dealer_users_pagination.total_page === dealer_users_pagination.current_page ? dealer_users_pagination.total : dealer_users_pagination.current_page * dealer_users_pagination.per_page
                    }}</span> gösteriliyor.</span>
                <b-pagination
                    :total-rows="dealer_users_pagination.total"
                    :per-page="size"
                    align="center"
                    size="sm"
                    class="my-1"
                    first-number
                    last-number
                    v-model="currentPage"
                    @input="getDealerUsers()"
                />
              </span>
              </div>
            </div>
          </div>

          <div class="order-tab-content" v-else>
            <loader/>
          </div>
        </b-tab>
        <b-tab disabled :title="$t('Working Conditions')">
          <div class="order-tab-content row px-3">
            <div class="col-sm-12 col-md-10 col-lg-10 neu history-card">
              <div class="history-content">
                <div class="d-flex justify-content-between bottom-border pb-2">
                  <div class="d-flex justify-content-between" style="gap: 50px; min-width: 170px;">
                    <div>{{ $t('Date') }}</div>
                    <div class="text-left">{{ $t('Hour') }}</div>
                  </div>
                  <div>{{ $t('Order Status') }}</div>
                </div>

                <div class="d-flex justify-content-between bottom-border py-3" v-for="i in 8" :key="i.$index">
                  <div class="d-flex justify-content-between" style="gap: 50px; min-width: 170px;">
                    <div class="fw-400">18/07/2022</div>
                    <div class="fw-400 text-left">14:03:12</div>
                  </div>
                  <div class="font-servislet-dark">Dağıtımda</div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-2 col-lg-2">
              <div class="right-button-content">
                <div style="line-height: 0">{{ $t('Order Status') }}</div>
                <div class="btn status-btn">{{ $t('Completed') }}</div>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab disabled :title="$t('Documents')">
          <div class="order-tab-content row px-3">
            <div class="col-sm-12 col-md-10 col-lg-10 neu history-card">
              <div class="history-content">
                <div class="d-flex justify-content-between bottom-border pb-2">
                  <div class="d-flex justify-content-between" style="gap: 50px; min-width: 170px;">
                    <div>{{ $t('Date') }}</div>
                    <div class="text-left">{{ $t('Hour') }}</div>
                  </div>
                  <div>{{ $t('Order Status') }}</div>
                </div>

                <div class="d-flex justify-content-between bottom-border py-3" v-for="i in 8" :key="i.$index">
                  <div class="d-flex justify-content-between" style="gap: 50px; min-width: 170px;">
                    <div class="fw-400">18/07/2022</div>
                    <div class="fw-400 text-left">14:03:12</div>
                  </div>
                  <div class="font-servislet-dark">Dağıtımda</div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-2 col-lg-2">
              <div class="right-button-content">
                <div style="line-height: 0">{{ $t('Order Status') }}</div>
                <div class="btn status-btn">{{ $t('Completed') }}</div>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>

      <div v-else>
        <loader/>
      </div>
    </div>

    <b-modal id="add-address-form" ref="add-address-form" size="lg" no-close-on-backdrop hide-header hide-footer>
      <ValidationObserver ref="addressAdd">
        <div class="forms-g-wrapper">
          <div class="space-between">
            <div class="modal-header-title">{{ $t('Add Address') }}</div>
            <img src="/images/icons/close.svg" alt="" class="cursor-pointer" @click="resetData()">
          </div>
          <div class="form-wrapper">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-6">
                <ValidationProvider :name="$t('Address Title')" #default="{errors}" rules="required|min:2">
                  <div class="input-wrapper">
                    <b-input-group>
                      <template #prepend>
                        <i class="fa-solid fa-envelope"></i>
                      </template>
                      <b-form-input :placeholder="$t('Address Title')" type="text" v-model="add.title"></b-form-input>
                    </b-input-group>
                  </div>
                  <label for="adress_title" class="text-center text-danger" v-if="errors.length>0"><small>*
                    {{ errors[0] }}.</small></label>
                  <label for="adress_title" class="opacity-0" v-else><small>*</small></label>
                </ValidationProvider>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6">
                <ValidationProvider :name="$t('Phone')" #default="{errors}" rules="required|min:2">
                  <div class="input-wrapper">
                    <b-input-group>
                      <template #prepend>
                        <i class="fa-solid fa-phone"></i>
                      </template>
                      <b-form-input type="text" :placeholder="$t('Phone')" v-model="address_phone"
                                    v-mask="'+90 (###) ### ## ##'"/>
                    </b-input-group>
                  </div>
                  <label for="email" class="text-center text-danger" v-if="errors.length>0"><small>*
                    {{ errors[0] }}.</small></label>
                </ValidationProvider>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4 mt-2">
                <label for="city">{{ $t('City') }} *</label>
                <city-select @setCity="setAddressCity($event)"/>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4 mt-2">
                <label for="district">{{ $t('District') }} *</label>
                <district-select @setDistrict="setAddressDistrict($event)"/>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4 mt-2">
                <label for="district">{{ $t('Neighborhood') }} / {{ $t('Street') }}</label>
                <street-select @setStreet="setAddressStreet($event)"/>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-12 mt-3">
                <b-form-textarea rows="5" :placeholder="$t('Address')" v-model="add.address"/>
              </div>

              <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                <div class="w-100 d-flex justify-content-end">
                  <button class="modal-footer-button cancel-button" @click="resetData()">{{ $t('Cancel') }}</button>
                  <button class="modal-footer-button save-button dealer-bg dealer-border" @click="addAdress()">{{ $t('Save') }}</button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </ValidationObserver>
    </b-modal>

    <b-modal id="add-current-user-form" ref="add-current-user-form" size="lg" no-close-on-backdrop hide-header
             hide-footer>
      <div>
        <div class="space-between">
          <div class="modal-header-title d-flex align-items-center gap05">{{ $t('Add User') }}
            <span class="warning-area" v-if="showerror">{{ $t('Please check fields.') }}</span>
          </div>
          <img src="/images/icons/close.svg" alt="" class="cursor-pointer"
               @click="$refs['add-current-user-form'].hide()">
        </div>

        <ValidationObserver ref="userAdd">
          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6 mt-4">
              <div class="form-white">
                <ValidationProvider :name="$t('Name')" rules="required|max:100" v-slot="{ errors }">
                  <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                    <b-form-input v-model="user.name" class="nonrequired-input"
                                  :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                    <label for="name" class="">{{ $t('Name') }} <span class="small"
                                                                      :class="errors.length>0 ? 'text-danger' : ''">*</span></label>
                  </div>
                  <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                  <span class="error-text opacity-0" v-else>*</span>
                </ValidationProvider>
              </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 mt-4">
              <div class="form-white">
                <ValidationProvider :name="$t('Surname')" rules="max:100" v-slot="{ errors }">
                  <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                    <b-form-input v-model="user.surname" class="nonrequired-input"
                                  :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                    <label for="title" class="">{{ $t('Surname') }}
                      <!--                      <span class="small" :class="errors.length>0 ? 'text-danger' : ''">*</span>-->
                    </label>
                  </div>
                  <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                  <span class="error-text opacity-0" v-else>*</span>
                </ValidationProvider>
              </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
              <div class="form-white">
                <!--                <ValidationProvider :name="$t('Position')" rules="required" v-slot="{ errors }">-->
                <!--                  <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">-->
                <div class="form-group input-material">
                  <!--                    <b-form-input v-model="user.position" class="nonrequired-input" :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>-->
                  <b-form-input v-model="user.position" class="nonrequired-input" required autocomplete="off"/>
                  <label for="title" class="">{{ $t('Position') }}
                    <!--                      <span class="small" :class="errors.length>0 ? 'text-danger' : ''">*</span>-->
                  </label>
                </div>
                <!--                  <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>-->
                <span class="error-text opacity-0">*</span>
                <!--                </ValidationProvider>-->
              </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
              <div class="form-white">
                <ValidationProvider :name="$t('E-Mail')" rules="required|email" v-slot="{ errors }">
                  <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                    <i class="fa-solid fa-envelope form-icon"></i>
                    <b-form-input v-model="user.email" class="nonrequired-input pl-32"
                                  :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                    <label for="email" class="pl-21">{{ $t('E-Mail') }} <span class="small"
                                                                              :class="errors.length>0 ? 'text-danger' : ''">*</span></label>
                  </div>
                  <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                  <span class="error-text opacity-0" v-else>*</span>
                </ValidationProvider>
              </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
              <div class="form-white">
                                <ValidationProvider :name="$t('Phone')" rules="min:19|max:19" v-slot="{ errors }">
                <div class="form-group input-material">
                  <i class="fa-solid fa-phone form-icon"></i>
                  <b-form-input v-model="user.phone" class="nonrequired-input pl-32" v-mask="'+90 (###) ### ## ##'"
                                required autocomplete="off"/>
                  <label for="phone" class="pl-21">{{ $t('Phone') }}</label>
                </div>
                                  <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                <span class="error-text opacity-0" v-else>*</span>
                                </ValidationProvider>
              </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6"/>
            <div class="col-sm-12 col-md-12 col-lg-12">
                <span class="font-small">
                  {{ $t('Set a password for the user you will create to log in.') }}
                </span>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 mt-4">
              <div class="form-white">
                <ValidationProvider :name="$t('Password')" vid="password" v-slot="{ errors }" rules="required">
                                    <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                  <div class="form-group input-material">
                    <img src="/images/icons/lock.svg" alt="" class="form-icon">
                    <!--                    <b-form-input type="password" v-model="user.password" class="nonrequired-input pl-32" required autocomplete="off" :class="errors.length>0 ? 'dangered-border' : ''" v-if="password_type"/>-->
                    <b-form-input type="password" v-model="user.password" class="nonrequired-input pl-32" required
                                  autocomplete="off" v-if="password_type" :class="errors.length>0 ? 'dangered-border' : ''"/>
                    <!--                    <b-form-input type="text" v-model="user.password" class="nonrequired-input pl-32" required autocomplete="off" :class="errors.length>0 ? 'dangered-border' : ''" v-if="!password_type"/>-->
                    <b-form-input type="text" v-model="user.password" class="nonrequired-input pl-32" required
                                  autocomplete="off" v-if="!password_type" :class="errors.length>0 ? 'dangered-border' : ''"/>
                    <!--                    <label for="email" class="pl-21">{{ $t('Password') }} <span class="small" :class="errors.length>0 ? 'text-danger' : ''">*</span></label>-->
                    <label for="email" class="pl-21">{{ $t('Password') }}</label>

                    <i class="fa-sharp fa-regular fa-eye-slash append-icon"
                       @click="password_type = !password_type"></i>
                  </div>
                  <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                  <span class="error-text opacity-0" v-else>*</span>
                                    </div>
                </ValidationProvider>
              </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 mt-4">
              <div class="form-white">
                <ValidationProvider :name="$t('Password Again')"
                                    v-slot="{ errors }" :rules="user.password ? 'required|confirmed:password' : ''">
                  <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                    <img src="/images/icons/lock.svg" alt="" class="form-icon">
                    <b-form-input type="password" v-model="user.password_confirmation"
                                  class="nonrequired-input pl-32" required autocomplete="off"
                                  :class="errors.length>0 ? 'dangered-border' : ''"
                                  v-if="password_confirmation_type"/>
                    <b-form-input type="text" v-model="user.password_confirmation"
                                  class="nonrequired-input pl-32" required autocomplete="off"
                                  :class="errors.length>0 ? 'dangered-border' : ''"
                                  v-if="!password_confirmation_type"/>
                    <label for="email" class="pl-21">{{ $t('Password Again') }}
                      <span class="small" :class="errors.length>0 ? 'text-danger' : ''"
                            v-if="user.password">*</span></label>

                    <i class="fa-sharp fa-regular fa-eye-slash append-icon"
                       @click="password_confirmation_type = !password_confirmation_type"></i>
                  </div>
                  <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6"/>

            <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
              <div class="d-flex justify-content-end">
                <button class="modal-footer-button cancel-button"
                        @click="$refs['add-current-user-form'].hide(); user = {}">
                  {{ $t('Cancel') }}
                </button>

                <button class="modal-footer-button save-button dealer-bg dealer-border" @click="addUser()">{{ $t('Save') }}</button>
              </div>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </b-modal>

    <b-modal id="update-address-form" ref="update-address-form" size="lg" no-close-on-backdrop hide-header hide-footer>
      <ValidationObserver ref="addressUpdate">
        <div class="forms-g-wrapper">
          <div class="space-between">
            <div class="modal-header-title">{{ $t('Add Address') }}</div>
            <img src="/images/icons/close.svg" alt="" class="cursor-pointer" @click="resetUpdateFormData()">
          </div>
          <div class="form-wrapper">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-6">
                <ValidationProvider :name="$t('Address Title')" #default="{errors}" rules="required|min:2">
                  <div class="input-wrapper">
                    <b-input-group>
                      <template #prepend>
                        <i class="fa-solid fa-envelope"></i>
                      </template>
                      <b-form-input :placeholder="$t('Address Title')" type="text"
                                    v-model="update_address.title"></b-form-input>
                    </b-input-group>
                  </div>
                  <label for="adress_title" class="text-center text-danger" v-if="errors.length>0"><small>*
                    {{ errors[0] }}.</small></label>
                  <label for="adress_title" class="opacity-0" v-else><small>*</small></label>
                </ValidationProvider>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6">
                <ValidationProvider :name="$t('Phone')" #default="{errors}" rules="required|min:2">
                  <div class="input-wrapper">
                    <b-input-group>
                      <template #prepend>
                        <i class="fa-solid fa-phone"></i>
                      </template>
                      <b-form-input type="text" :placeholder="$t('Phone')" v-model="update_address.phone"
                                    v-mask="'+90 (###) ### ## ##'"/>
                    </b-input-group>
                  </div>
                  <label for="email" class="text-center text-danger" v-if="errors.length>0"><small>*
                    {{ errors[0] }}.</small></label>
                </ValidationProvider>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4 mt-2">
                <label for="city">{{ $t('City') }} *</label>
                <city-select @setCity="setAddressCityUpdate($event)" @setCityId="update_address.city_id = $event.id"
                             :country="true" :city_id.sync="update_address.city_id"/>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4 mt-2">
                <label for="district">{{ $t('District') }} *</label>
                <district-select @setDistrict="setAddressDistrictUpdate($event)"
                                 @setDistrictId="update_address.district_id = $event.id" :country="true"
                                 :selected.sync="update_address.selected"
                                 :district_id.sync="update_address.district_id"/>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4 mt-2">
                <label for="district">{{ $t('Neighborhood') }} / {{ $t('Street') }}</label>
                <street-select @setStreet="setAddressStreetUpdate($event)" :country="true"
                               @setStreetId="update_address.neighborhood_id = $event.id"
                               :selected.sync="update_address.selected2"
                               :street_id.sync="update_address.neighborhood_id"/>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-12 mt-3">
                <b-form-textarea rows="5" :placeholder="$t('Address')" v-model="update_address.address"/>
              </div>

              <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                <div class="w-100 d-flex justify-content-end">
                  <button class="modal-footer-button cancel-button" @click="deleteAddress()">{{
                      $t('Delete Address')
                    }}
                  </button>
                  <button class="modal-footer-button save-button dealer-bg dealer-border" @click="updateAddress()">{{ $t('Update') }}</button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </ValidationObserver>
    </b-modal>

    <b-modal id="update-current-user-form" ref="update-current-user-form" size="lg" no-close-on-backdrop hide-header
             hide-footer>
      <div>
        <div class="space-between">
          <div class="modal-header-title d-flex align-items-center gap05">{{ $t('Update User') }}
            <span class="warning-area" v-if="showerror">{{ $t('Please check fields.') }}</span>
          </div>
          <img src="/images/icons/close.svg" alt="" class="cursor-pointer"
               @click="$refs['update-current-user-form'].hide()">
        </div>

        <ValidationObserver ref="userUpdate">
          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6 mt-4">
              <div class="form-white">
                <ValidationProvider :name="$t('Name')" rules="required|max:100" v-slot="{ errors }">
                  <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                    <b-form-input v-model="update.name" class="nonrequired-input"
                                  :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                    <label for="name" class="">{{ $t('Name') }} <span class="small"
                                                                      :class="errors.length>0 ? 'text-danger' : ''">*</span></label>
                  </div>
                  <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                  <span class="error-text opacity-0" v-else>*</span>
                </ValidationProvider>
              </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 mt-4">
              <div class="form-white">
                <ValidationProvider :name="$t('Surname')" rules="max:100" v-slot="{ errors }">
                  <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                    <b-form-input v-model="update.surname" class="nonrequired-input"
                                  :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                    <label for="title" class="">{{ $t('Surname') }}
                      <!--                      <span class="small" :class="errors.length>0 ? 'text-danger' : ''">*</span>-->
                    </label>
                  </div>
                  <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                  <span class="error-text opacity-0" v-else>*</span>
                </ValidationProvider>
              </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
              <div class="form-white">
                <!--                <ValidationProvider :name="$t('Position')" rules="required" v-slot="{ errors }">-->
                <!--                  <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">-->
                <div class="form-group input-material">
                  <!--                    <b-form-input v-model="user.position" class="nonrequired-input" :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>-->
                  <b-form-input v-model="update.position" class="nonrequired-input" required autocomplete="off"/>
                  <label for="title" class="">{{ $t('Position') }}
                    <!--                      <span class="small" :class="errors.length>0 ? 'text-danger' : ''">*</span>-->
                  </label>
                </div>
                <!--                  <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>-->
                <span class="error-text opacity-0">*</span>
                <!--                </ValidationProvider>-->
              </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
              <div class="form-white">
                <ValidationProvider :name="$t('E-Mail')" rules="required|email" v-slot="{ errors }">
                  <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                    <i class="fa-solid fa-envelope form-icon"></i>
                    <b-form-input v-model="update.email" class="nonrequired-input pl-32"
                                  :class="errors.length>0 ? 'dangered-border' : ''" required autocomplete="off"/>
                    <label for="email" class="pl-21">{{ $t('E-Mail') }} <span class="small"
                                                                              :class="errors.length>0 ? 'text-danger' : ''">*</span></label>
                  </div>
                  <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                  <span class="error-text opacity-0" v-else>*</span>
                </ValidationProvider>
              </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
              <div class="form-white">
                <!--                <ValidationProvider :name="$t('Phone')" rules="required|min:19|max:19" v-slot="{ errors }">-->
                <div class="form-group input-material">
                  <i class="fa-solid fa-phone form-icon"></i>
                  <b-form-input v-model="update.gsm" class="nonrequired-input pl-32" v-mask="'+9# (###) ### ## ##'"
                                required autocomplete="off"/>
                  <label for="phone" class="pl-21">{{ $t('Phone') }}
                    <!--                      <span class="small" :class="errors.length>0 ? 'text-danger' : ''">*</span>-->
                  </label>
                </div>
                <!--                  <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>-->
                <span class="error-text opacity-0">*</span>
                <!--                </ValidationProvider>-->
              </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6"/>
            <div class="col-sm-12 col-md-12 col-lg-12">
                <span class="font-small">
                  {{ $t('Set a password for the user you will create to log in.') }}
                </span>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 mt-4">
              <div class="form-white">
                <ValidationProvider :name="$t('Password')" vid="password2" v-slot="{ errors }">
                  <!--                  <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">-->
                  <div class="form-group input-material">
                    <img src="/images/icons/lock.svg" alt="" class="form-icon">
                    <!--                    <b-form-input type="password" v-model="user.password" class="nonrequired-input pl-32" required autocomplete="off" :class="errors.length>0 ? 'dangered-border' : ''" v-if="password_type"/>-->
                    <b-form-input type="password" v-model="update.password" class="nonrequired-input pl-32" required
                                  autocomplete="off" v-if="password_type"/>
                    <!--                    <b-form-input type="text" v-model="user.password" class="nonrequired-input pl-32" required autocomplete="off" :class="errors.length>0 ? 'dangered-border' : ''" v-if="!password_type"/>-->
                    <b-form-input type="text" v-model="update.password" class="nonrequired-input pl-32" required
                                  autocomplete="off" v-if="!password_type"/>
                    <!--                    <label for="email" class="pl-21">{{ $t('Password') }} <span class="small" :class="errors.length>0 ? 'text-danger' : ''">*</span></label>-->
                    <label for="email" class="pl-21">{{ $t('Password') }}</label>

                    <i class="fa-sharp fa-regular fa-eye-slash append-icon"
                       @click="password_type = !password_type"></i>
                  </div>
                  <!--                  <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>-->
                  <span class="error-text opacity-0">*</span>
                </ValidationProvider>
              </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 mt-4">
              <div class="form-white">
                <ValidationProvider :name="$t('Password Again')"
                                    v-slot="{ errors }" :rules="update.password ? 'required|confirmed:password2' : ''">
                  <div class="form-group input-material" :class="errors.length > 0 ? 'input-error' : ''">
                    <img src="/images/icons/lock.svg" alt="" class="form-icon">
                    <b-form-input type="password" v-model="update.password_confirmation"
                                  class="nonrequired-input pl-32" required autocomplete="off"
                                  :class="errors.length>0 ? 'dangered-border' : ''"
                                  v-if="password_confirmation_type"/>
                    <b-form-input type="text" v-model="update.password_confirmation"
                                  class="nonrequired-input pl-32" required autocomplete="off"
                                  :class="errors.length>0 ? 'dangered-border' : ''"
                                  v-if="!password_confirmation_type"/>
                    <label for="email" class="pl-21">{{ $t('Password Again') }}
                      <span class="small" :class="errors.length>0 ? 'text-danger' : ''"
                            v-if="update.password">*</span></label>

                    <i class="fa-sharp fa-regular fa-eye-slash append-icon"
                       @click="password_confirmation_type = !password_confirmation_type"></i>
                  </div>
                  <span class="error-text" v-if="errors.length>0">* {{ errors[0] }}.</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6"/>

            <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
              <div class="d-flex justify-content-end">
                <button class="modal-footer-button cancel-button"
                        @click="$refs['update-current-user-form'].hide();">
                  {{ $t('Cancel') }}
                </button>

                <button class="modal-footer-button save-button dealer-bg dealer-border" @click="updateUser()">{{ $t('Update') }}</button>
              </div>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </b-modal>
  </Default>
</template>

<script>
import Default from "@/layouts/default.vue";
import AddressType from "@/components/icons/AddressType.vue";
import DistrictSelect from "@/components/selects/current/DistrictSelect.vue";
import StreetSelect from "@/components/selects/current/StreetSelect.vue";
import CitySelect from "@/components/selects/current/CitySelect.vue";
import axios from "@/plugins/axios";
import PriceType from "@/components/selects/current/PriceType.vue";
import MultiplePaymentType from "@/components/selects/MultiplePaymentType.vue";
import PlacierSelect from "@/components/selects/current/PlacierSelect.vue";

export default {
  name: "CurrentDetail",
  components: {
    PlacierSelect,
    MultiplePaymentType, Default, AddressType, DistrictSelect, StreetSelect, CitySelect, PriceType},
  data() {
    return {
      tab: 1,
      currentPage: 1,
      size: 10,
      loader: true,
      dealer: {},
      update: {},
      address_phone: '',
      edit: false,
      password_type: true,
      password_confirmation_type: true,
      user: {},
      showerror: false,
      dealer_user_loader: true,
      dealer_users_pagination: {},
      dealer_users: [],
      title: null,
      phone: null,
      email: null,
      mersis: null,
      tax_office: null,
      tax_number: null,
      status: true,
      add: {},
      update_address: {},
      risk_limit: null,
      payment_term: null,
      name: null,
      fax: null,
      discount_rate: null,
      debt: null,
      lend: null,
      balance: null,
      risk_limit_edit: false,
      balance_edit: false,
      debt_edit: false,
      lend_edit: false,
      installment_count: null,
      term_installment_count: null,
      payment_error: null
    }
  },
  mounted() {
    this.getDetail()
  },
  computed: {
    addresses() {
      return this.$store.state.address.list
    },
    color(){
      return localStorage.getItem('color')
    },
  },
  methods: {
    setPaymentType(event){
      if(event.length>0){
        var ids = []
        event.forEach(item=>{
          ids.push(item.paymentType.id)
        })
        this.dealer.payment_types = ids
      }else{
        this.dealer.payment_types = null
      }
    },
    openAddressUpdateModal(i) {
      this.$store.dispatch('city/getCities', i.country_id);
      this.$store.dispatch('district/getDistricts', i.city_id);
      this.$store.dispatch('street/getStreets', i.district_id);
      this.update_address = i;
      this.$refs['update-address-form'].show()
    },
    getDetail() {
      this.loader = true
      this.$axios.get(`/v1/customers/dealers/${this.$route.params.id}`).then(response => {
        if (response.data.status) {
          this.dealer = response.data.responseData.dealer
          this.name = response.data.responseData.dealer.name
          this.title = response.data.responseData.dealer.title
          this.phone = response.data.responseData.dealer.phone
          this.email = response.data.responseData.dealer.email
          this.mersis = response.data.responseData.dealer.mersis
          this.tax_office = response.data.responseData.dealer.tax_office
          this.tax_number = response.data.responseData.dealer.tax_number
          this.risk_limit = response.data.responseData.dealer.risk_limit ?? 0
          this.payment_term = response.data.responseData.dealer.payment_term
          this.fax = response.data.responseData.dealer.fax
          this.debt = response.data.responseData.dealer.dept_amount ?? 0
          this.lend = response.data.responseData.dealer.credit_amount ?? 0
          this.balance = response.data.responseData.dealer.balance_amount ?? 0
          this.installment_count = response.data.responseData.dealer.installment_count
          this.term_installment_count = response.data.responseData.dealer.term_installment_count
          if(this.dealer.paymentTypes){
            this.payment_error = false
          }
          if (this.debt) {
            this.debt_edit = true
          }
          if (this.lend) {
            this.lend_edit = true
          }
          if (this.balance) {
            this.balance_edit = true
          }
          if (this.risk_limit) {
            this.risk_limit_edit = true
          }
          this.discount_rate = parseFloat(response.data.responseData.dealer.discount_rate)
          this.$store.dispatch('address/getDealerAddress', this.dealer.id)
          this.loader = false
        }
      }).catch(error => {
        this.loader = false
        this.$swal({
          iconHtml: '<img src="/images/toast/error.svg" width="32" height="32">',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          title: this.$i18n.t('Error') + ' !',
          text: error.response.data.statusMessage,
          customClass: {
            icon: 'swal2-no-border'
          }
        })
      })
    },
    updateBasicInfo() {
      if(!this.dealer.payment_types) {
        this.payment_error = true
      }
      this.$refs.basicRules.validate().then(success => {
        if (success) {
          this.$axios.put(`/v1/customers/dealers/${this.$route.params.id}`, {
            name: this.name,
            dept_amount: this.debt,
            credit_amount: this.lend,
            balance_amount: this.balance,
            discount_rate: this.discount_rate ? parseInt(this.discount_rate.substring(2, this.discount_rate.length)) : 0,
            phone: this.phone,
            fax: this.fax,
            tax_number: this.tax_number,
            tax_office: this.tax_office,
            title: this.title,
            email: this.email,
            mersis: this.mersis,
            payment_term: this.payment_term ? parseInt(this.payment_term) : 0,
            risk_limit: this.risk_limit,
            installment_count: this.installment_count,
            term_installment_count: this.term_installment_count,
            list_price_type: this.dealer.list_price_type,
            payment_types: this.dealer.payment_types,
            salesman_id: this.dealer.salesman_id
          }).then(response => {
            if (response.data.status) {
              this.edit = false
              this.$swal({
                iconHtml: '<img src="/images/toast/success.svg" width="32" height="32">',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                title: this.$i18n.t('Success') + ' !',
                text: this.$i18n.t('Dealer successfully update.'),
                customClass: {
                  icon: 'swal2-no-border'
                }
              })
              this.getDetail();
            }
          }).catch(error => {
            this.$swal({
              iconHtml: '<img src="/images/toast/error.svg" width="32" height="32">',
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              title: this.$i18n.t('Error') + ' !',
              text: error.response.data.statusMessage,
              customClass: {
                icon: 'swal2-no-border'
              }
            })
          })
        } else {
          this.$swal({
            iconHtml: '<img src="/images/toast/warning.png" width="32" height="32">',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            title: this.$i18n.t('Warning') + ' !',
            text: this.$i18n.t('Please check fields.'),
            customClass: {
              icon: 'swal2-no-border'
            }
          })
        }
      })
    },
    getDealerUsers() {
      this.dealer_user_loader = true
      this.$axios.get('/v1/customers/dealer-users', {
        params:{
          page: this.currentPage,
          size: this.size,
          filter: {
            dealer_id: this.$route.params.id
          }
        }
      }).then(response => {
        if (response.data.status) {
          this.dealer_users = response.data.responseData.dealerUser.items
          this.dealer_users_pagination = response.data.responseData.dealerUser.pagination
          this.dealer_user_loader = false
        }
      })
    },
    addUser() {
      this.$refs.userAdd.validate().then(success => {
        if (success) {
          this.showerror = false
          this.$axios.post('/v1/customers/dealer-users', {
            dealer_id: this.$route.params.id,
            name: this.user.name,
            surname: this.user.surname,
            email: this.user.email,
            password: this.user.password,
            gsm: this.user.phone
          }).then(response => {
            if (response.data.status) {
              this.$refs["add-current-user-form"].hide();
              this.$swal({
                iconHtml: '<img src="/images/toast/success.svg" width="32" height="32">',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                title: this.$i18n.t('Success') + ' !',
                text: this.$i18n.t('Dealer user successfully added.'),
                customClass: {
                  icon: 'swal2-no-border'
                }
              })
              this.currentPage = 1
              this.size = 10
              this.tab = 2
              this.user = {}
              this.getDealerUsers()
            }
          }).catch(error => {
            this.$swal({
              iconHtml: '<img src="/images/toast/error.svg" width="32" height="32">',
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              title: this.$i18n.t('Error') + ' !',
              text: error.response.data.statusMessage,
              customClass: {
                icon: 'swal2-no-border'
              }
            })
          })
        } else {
          this.showerror = true
        }
      })
    },
    setAddressCity(event) {
      this.add.city_id = event.id
      if (event.id) {
        this.$store.dispatch('district/getDistricts', event.id)
        this.$store.commit('street/setList', [])
        this.$store.commit('city/setSelected', event)
      } else {
        this.$store.commit('district/setList', [])
      }
    },
    setAddressDistrict(event) {
      this.add.district_id = event.id
      if (event.id) {
        this.$store.commit('district/setSelected', event)
        this.$store.dispatch('street/getStreets', event.id)
      } else {
        this.$store.commit('street/setList', [])
      }
    },
    setAddressStreet(event) {
      if (event.id) {
        this.add.neighborhood_id = event.id
        this.$store.commit('street/setSelected', event)
      }
    },
    setAddressCityUpdate(event) {
      this.update_address.city_id = event.id
      this.update_address.selected = true
      if (event.id) {
        this.$store.dispatch('district/getDistricts', event.id)
        this.$store.commit('street/setList', [])
        this.$store.commit('city/setSelected', event)
      } else {
        this.$store.commit('district/setList', [])
      }
    },
    setAddressDistrictUpdate(event) {
      this.update_address.district_id = event.id
      this.update_address.selected2 = true
      if (event.id) {
        this.$store.commit('district/setSelected', event)
        this.$store.dispatch('street/getStreets', event.id)
      } else {
        this.$store.commit('street/setList', [])
      }
    },
    setAddressStreetUpdate(event) {
      this.update_address.neighborhood_id = event.id
      if (event.id) {
        this.$store.commit('street/setSelected', event)
      }
    },
    resetData() {
      this.$refs['add-address-form'].hide()
      this.add = {}
      this.address_phone = ''
      this.$store.commit('city/setList', [])
      this.$store.commit('city/setSelected', {})
      this.$store.commit('district/setList', [])
      this.$store.commit('district/setSelected', {})
      this.$store.commit('street/setList', [])
      this.$store.commit('street/setSelected', {})
    },
    addAdress() {
      this.$refs.addressAdd.validate().then(success => {
        if (success) {
          if (this.add.city_id && this.add.district_id) {
            axios.post('/v1/customers/dealer-addresses', {
              dealer_id: this.$route.params.id,
              title: this.add.title,
              country_id: this.$store.state.country.selected.id,
              city_id: this.add.city_id,
              district_id: this.add.district_id,
              neighborhood_id: this.add.neighborhood_id,
              address: this.add.address,
              phone: this.address_phone,
              is_default: true
            }).then(response => {
              if (response.data.status) {
                this.$refs['add-address-form'].hide()
                this.$swal({
                  iconHtml: '<img src="/images/toast/success.svg" width="32" height="32">',
                  toast: true,
                  position: 'top-end',
                  showConfirmButton: false,
                  timer: 3000,
                  title: this.$i18n.t('Success') + ' !',
                  text: this.$i18n.t('Address successfully added.'),
                  customClass: {
                    icon: 'swal2-no-border'
                  }
                })
                this.$store.dispatch('address/getDealerAddress', this.dealer.id)
                this.add = {}
                this.address_phone = ''
                this.$store.commit('city/setList', [])
                this.$store.commit('city/setSelected', {})
                this.$store.commit('district/setList', [])
                this.$store.commit('district/setSelected', {})
                this.$store.commit('street/setList', [])
                this.$store.commit('street/setSelected', {})
              }
            }).catch(error => {
              this.$swal({
                iconHtml: '<img src="/images/toast/error.svg" width="32" height="32">',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                title: this.$i18n.t('Error') + ' !',
                text: error.response.data.statusMessage,
                customClass: {
                  icon: 'swal2-no-border'
                }
              })
            })
          } else {
            if (!this.add.city_id) {
              this.$store.commit('city/setSelected', null)
            }
            if (!this.add.district_id) {
              this.$store.commit('district/setSelected', null)
            }
            this.$swal({
              iconHtml: '<img src="/images/toast/warning.png" width="32" height="32">',
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              title: this.$i18n.t('Warning') + ' !',
              text: this.$i18n.t('Please check fields.'),
              customClass: {
                icon: 'swal2-no-border'
              }
            })
          }
        } else {
          if (this.add.city_id) {
          } else {
            this.$store.commit('city/setSelected', null)
          }
          if (this.add.district_id) {
          } else {
            this.$store.commit('district/setSelected', null)
          }
          this.$swal({
            iconHtml: '<img src="/images/toast/warning.png" width="32" height="32">',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            title: this.$i18n.t('Warning') + ' !',
            text: this.$i18n.t('Please check fields.'),
            customClass: {
              icon: 'swal2-no-border'
            }
          })
        }
      })
    },
    updateAddress() {
      this.$refs.addressUpdate.validate().then(success => {
        if (success) {
          if (this.update_address.city_id && this.update_address.district_id) {
            axios.put(`/v1/customers/dealer-addresses/${this.update_address.id}`, {
              dealer_id: parseInt(this.$route.params.id),
              title: this.update_address.title,
              country_id: this.update_address.country_id,
              city_id: this.update_address.city_id,
              district_id: this.update_address.district_id,
              neighborhood_id: this.update_address.neighborhood_id,
              address: this.update_address.address,
              phone: this.update_address.phone,
              is_default: true
            }).then(response => {
              if (response.data.status) {
                this.$refs["update-address-form"].hide()
                this.$swal({
                  iconHtml: '<img src="/images/toast/success.svg" width="32" height="32">',
                  toast: true,
                  position: 'top-end',
                  showConfirmButton: false,
                  timer: 3000,
                  title: this.$i18n.t('Success') + ' !',
                  text: this.$i18n.t('Address successfully updated.'),
                  customClass: {
                    icon: 'swal2-no-border'
                  }
                })
                this.$store.dispatch('address/getDealerAddress', this.dealer.id)
                this.update_address = {}
                this.$store.commit('city/setList', [])
                this.$store.commit('city/setSelected', {})
                this.$store.commit('district/setList', [])
                this.$store.commit('district/setSelected', {})
                this.$store.commit('street/setList', [])
                this.$store.commit('street/setSelected', {})
              }
            }).catch(error => {
              this.$swal({
                iconHtml: '<img src="/images/toast/error.svg" width="32" height="32">',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                title: this.$i18n.t('Error') + ' !',
                text: error.response.data.statusMessage,
                customClass: {
                  icon: 'swal2-no-border'
                }
              })
            })
          } else {
            if (!this.update_address.city_id) {
              this.$store.commit('city/setSelected', null)
            }
            if (!this.update_address.district_id) {
              this.$store.commit('district/setSelected', null)
            }
            this.$swal({
              iconHtml: '<img src="/images/toast/warning.png" width="32" height="32">',
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              title: this.$i18n.t('Warning') + ' !',
              text: this.$i18n.t('Please check fields.'),
              customClass: {
                icon: 'swal2-no-border'
              }
            })
          }
        } else {
          if (this.update_address.city_id) {
          } else {
            this.$store.commit('city/setSelected', null)
          }
          if (this.update_address.district_id) {
          } else {
            this.$store.commit('district/setSelected', null)
          }
          this.$swal({
            iconHtml: '<img src="/images/toast/warning.png" width="32" height="32">',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            title: this.$i18n.t('Warning') + ' !',
            text: this.$i18n.t('Please check fields.'),
            customClass: {
              icon: 'swal2-no-border'
            }
          })
        }
      })
    },
    resetUpdateFormData() {
      this.$refs["update-address-form"].hide()
      this.update_address = {}
      this.address_phone = ''
      this.$store.commit('city/setList', [])
      this.$store.commit('city/setSelected', {})
      this.$store.commit('district/setList', [])
      this.$store.commit('district/setSelected', {})
      this.$store.commit('street/setList', [])
      this.$store.commit('street/setSelected', {})
    },
    deleteAddress() {
      axios.delete(`/v1/customers/dealer-addresses/${this.update_address.id}`).then(response => {
        if (response.data.status) {
          this.resetUpdateFormData()
          this.$swal({
            iconHtml: '<img src="/images/toast/success.svg" width="32" height="32">',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            title: this.$i18n.t('Success') + ' !',
            text: this.$i18n.t('Address successfully deleted.'),
            customClass: {
              icon: 'swal2-no-border'
            }
          })
          this.$store.dispatch('address/getDealerAddress', this.dealer.id)
        }
      }).catch(error => {
        this.$swal({
          iconHtml: '<img src="/images/toast/error.svg" width="32" height="32">',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          title: this.$i18n.t('Error') + ' !',
          text: error.response.data.statusMessage,
          customClass: {
            icon: 'swal2-no-border'
          }
        })
      })
    },
    getUserDetail(item){
      axios.get(`/v1/customers/dealer-users/${item.id}`).then(response=>{
        this.update = response.data.responseData.dealerUser
        this.$refs["update-current-user-form"].show()
      })
    },
    updateUser(){
      this.$refs.userUpdate.validate().then(success => {
        if (success) {
          this.showerror = false
          this.$axios.put(`/v1/customers/dealer-users/${this.update.id}`, {
            dealer_id: this.$route.params.id,
            name: this.update.name,
            surname: this.update.surname,
            email: this.update.email,
            password: this.update.password,
            gsm: this.update.gsm
          }).then(response => {
            if (response.data.status) {
              this.$refs["update-current-user-form"].hide();
              this.$swal({
                iconHtml: '<img src="/images/toast/success.svg" width="32" height="32">',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                title: this.$i18n.t('Success') + ' !',
                text: this.$i18n.t('Dealer user successfully updated.'),
                customClass: {
                  icon: 'swal2-no-border'
                }
              })
              this.currentPage = 1
              this.size = 10
              this.tab = 2
              this.getDealerUsers()
            }
          }).catch(error => {
            this.$swal({
              iconHtml: '<img src="/images/toast/error.svg" width="32" height="32">',
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              title: this.$i18n.t('Error') + ' !',
              text: error.response.data.statusMessage,
              customClass: {
                icon: 'swal2-no-border'
              }
            })
          })
        } else {
          this.showerror = true
        }
      })
    },
    deleteUser(item){
      axios.delete(`/v1/customers/dealer-users/${item.id}`).then(response=>{
        if(response.data.status){
          this.$swal({
            iconHtml: '<img src="/images/toast/success.svg" width="32" height="32">',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            title: this.$i18n.t('Success') + ' !',
            text: this.$i18n.t('Dealer user successfully deleted.'),
            customClass: {
              icon: 'swal2-no-border'
            }
          })
          this.currentPage = 1
          this.size = 10
          this.tab = 2
          this.getDealerUsers()
        }
      }).catch(error => {
        this.$swal({
          iconHtml: '<img src="/images/toast/error.svg" width="32" height="32">',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          title: this.$i18n.t('Error') + ' !',
          text: error.response.data.statusMessage,
          customClass: {
            icon: 'swal2-no-border'
          }
        })
      })
    }
  }
}
</script>

<style scoped>
.warning-area {
  font-family: Inter;
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #FF4343;
  background-color: #FF43431F;
  padding: 2px 6px;
  border-radius: 4px;
}

.table-content {
  padding: 30px;
  margin-top: 30px;
}

.order-tab-content {
  margin-top: 32px;
}

.card-content {
  padding: 28px 30px;
}

.tab-content {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.tab-content-card {
  padding: 30px;
}

.first-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.first-card .left {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.bg-card {
  background-color: #fff;
  border-radius: 8px;
}

.left .img {
  border: 1px solid rgba(211, 211, 211, 1);
  border-radius: 100%;
  width: 125px;
  height: 125px;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left .img img {
  width: 100%;
}

.left .name {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #323338;
}

.left .email {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: rgba(119, 119, 119, 0.9);
  margin-top: 12px;
}

.edit-btn {
  display: flex;
  align-items: center;
  gap: .5rem;
  border: 1px solid #6D6E6F66;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  border-radius: 5px;
  background-color: transparent;
  padding: 7px 14px;
}

.tab-content-card .title {
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  color: #1C1C1C;
}

.space-between .key {
  color: #777777E5;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}

.space-between .value {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  text-align: right;
  color: #323338;
}

.bottom-border {
  padding: 10px 0;
  border-bottom: 1px solid #6D6E6F33;
}

.address-item {
  display: flex;
  flex-direction: column;
  gap: 12px;
  border: 1px solid #6D6E6F66;
  border-radius: 10px;
  padding: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #1c1c1c;
  cursor: pointer;
  transition: .3s all;
}

.address-item.active, .address-item:hover {
  border: 1px solid v-bind('color');
}

.address-item i {
  color: #CECECE;
  font-size: 18px;
}

.address-name {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 800;
  line-height: 20px;
  text-align: left;
  color: #323338;
}

.default-badge {
  color: v-bind('color');
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  background-color: #2A41951F;
  padding: 4px 8px;
  border-radius: 4px;
}

.edit-text {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #323338;
  cursor: pointer;
}

label {
  color: #777777E5;
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
}

.modal-footer-button {
  padding: 10px 30px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: center;
  border-radius: 5px;
  margin-left: .5rem;
  width: 108px;
  transition: .3s all;
  white-space: nowrap;
}

.modal-footer-button.cancel-button {
  background-color: transparent;
  border: 1px solid transparent;
  color: #777777E5;
}

.modal-footer-button.cancel-button:hover {
  background-color: #f4f4f4;
  border: 1px solid #f4f4f4;
  color: #777777E5;
}

.modal-footer-button.save-button {
  color: #fff;
}

td span {
  color: #777777E5;
  font-size: 13px;
  font-family: Inter;
}

.category-badge {
  padding: 5px 20px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  font-family: Montserrat;
}

.admin-badge {
  color: #28C76F;
  background-color: #28C76F1F;
}

.user-badge {
  color: #07C7E3;
  background-color: #07C7E31F;
}

.table-title {
  font-family: Inter;
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
  color: #1C1C1C;
}

.table-desc {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #323338;
  margin-top: 12px;
}

.filters {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.filter-input {
  display: flex;
  border: 1px solid v-bind('color');
  align-items: center;
  border-radius: 5px;
  padding: 4px 16px;
  color: v-bind('color');
  min-width: 300px;
}

.filter-input input, .filter-input input:focus {
  color: v-bind('color');
  padding-left: 0 !important;
  font-size: 14px;
  border: none !important;
}

.filter-input input::placeholder {
  color: v-bind('color');
}

.add-current {
  background-color: v-bind('color');
  color: #f4f4f4;
  display: flex;
  text-align: center;
  align-items: center;
  border-radius: 5px;
  font-size: 14px;
  padding: 10px;
  cursor: pointer;
  gap: .5rem;
}

.font-small {
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: Inter !important;
  color: #323338 !important;
}
</style>

<style>
#add-address-form input::placeholder, #add-address-form textarea::placeholder {
  font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 40px !important;
  text-align: left !important;
  color: #777777E5 !important;
}
</style>
