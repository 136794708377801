import Vue from "vue";
import axios from 'axios';

const TOKEN = localStorage.getItem('access_token') ? 'Bearer '+ localStorage.getItem('access_token') : ''

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_URL || 'https://staging-b2b-api.servislet.com/api',
    headers: {
        Authorization: TOKEN
    }
});
export default axiosInstance;

Vue.prototype.$axios = axiosInstance;

axiosInstance.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.data.statusCode === 401) {
        // localStorage.removeItem('userData')
        if(window.location.pathname !== "/login"){
            window.location.pathname = "/login"
        }
    }

    if (error.response.data.statusCode === "token_not_found") {
        // localStorage.removeItem('userData')
        if(window.location.pathname !== "/login"){
            window.location.pathname = "/login"
        }
    }

    return Promise.reject(error);
});
