import Vue from 'vue';
import Vuex from 'vuex';
import axios from "@/plugins/axios";
import i18n from "@/i18n";

Vue.use(Vuex);

const state = {
    products: [],
    pagination: {},
    list_loader: true
};

const mutations = {
    setProductList(state, data) {
        state.products = data.items;
        state.pagination = data.pagination;
    },
};

const actions = {
    getProducts({state,commit},data) {
        state.list_loader = true
        var params  = {
            page: data.page,
            size: data.size,
            filter:{},
            sort:{}
        }
        if(data.search){
            params.filter.searchText = data.search
        }
        if(data.sort_direction || data.sort_column){
            params.sort.column = data.sort_column
            params.sort.direction = data.sort_direction
        }
        axios.get('/v1/customers/products', {
            params
        }).then(response => {
            commit('setProductList', response.data.responseData.product);
            state.list_loader = false
        }).catch(error => {
            Vue.swal({
                iconHtml: '<img src="/images/toast/error.svg" width="32" height="32">',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                title: i18n.t('Error')+' !',
                text: error.response.data.statusMessage,
                customClass: {
                    icon: 'swal2-no-border'
                }
            })
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
